// ------------------------------------
// default browser styles reset mixins
// ------------------------------------
/// @access private
// ------------------------------------
// resets default browser styles for certain elements to achieve consistent look and feel on all platforms
// ------------------------------------

@import '../config/all';

@mixin btn-reset() {
  border: 0;
  background: transparent;
  font-size: $font-size-primary;
  cursor: pointer;
  appearance: none;
}

