@import '../config/all';
@import '../utils/all';

// having box-sizinng border-box is the only global styling requirement for the library to work nice
* {
  box-sizing: border-box;
}

// global styles for all the ".flix-" elements
[class^='#{$config-prefix}'] {
  margin: 0;
  padding: 0;

  &::before,
  &::after {
    box-sizing: border-box;
  }
}

// global body styles
body {
  color: $primary-content-color;
  font-family: $font-family-primary;
  font-size: $font-size-primary;
  font-weight: $font-weight-normal;
  line-height: $line-height-primary;
}
