// ------------------------------------
// NOTE: this is an autogenerated file
// Refer to fontello:build script in './scripts/fontello'
// ------------------------------------

@import '../config/all';

// ------------------------------------
// flixicons font-face
// this provides an extracted font-face declaration allowing us to include it alongside with
// components that require icon font, as well as adding it to "base" file in other modules so
// the icon font is present on the page and all the components that use icon mixins display properly 
// ------------------------------------

// sass-lint:disable no-vendor-prefixes no-duplicate-properties class-name-format

@font-face {
  font-family: 'flixicons';
  font-style: normal;
  font-weight: normal;
  src: url('#{$font-base-path}/font/flix.woff2?1569935770717') format('woff2'),
  url('#{$font-base-path}/font/flix.woff?1569935770717') format('woff'),
  url('#{$font-base-path}/font/flix.ttf?1569935770717') format('truetype');
  // hides the icons while font is loading
  font-display: block;
}
