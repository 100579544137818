// ------------------------------------
// NOTE: this is an autogenerated file
// Refer to fontello:build script in './scripts/fontello'
// ------------------------------------

@mixin flix-icon-account-attention {
  content: '\e801';
}

@mixin flix-icon-account-settings-solid {
  content: '\e802';
}

@mixin flix-icon-account-settings {
  content: '\e803';
}

@mixin flix-icon-account-solid {
  content: '\e804';
}

@mixin flix-icon-account {
  content: '\e805';
}

@mixin flix-icon-airport-solid {
  content: '\e806';
}

@mixin flix-icon-airport {
  content: '\e807';
}

@mixin flix-icon-apps-solid {
  content: '\e808';
}

@mixin flix-icon-apps {
  content: '\e809';
}

@mixin flix-icon-archive-solid {
  content: '\e80a';
}

@mixin flix-icon-archive {
  content: '\e80b';
}

@mixin flix-icon-arrow-big-down {
  content: '\e80c';
}

@mixin flix-icon-arrow-big-left {
  content: '\e80d';
}

@mixin flix-icon-arrow-big-right {
  content: '\e80e';
}

@mixin flix-icon-arrow-big-up {
  content: '\e80f';
}

@mixin flix-icon-arrow-down {
  content: '\e810';
}

@mixin flix-icon-arrow-left {
  content: '\e811';
}

@mixin flix-icon-arrow-right {
  content: '\e812';
}

@mixin flix-icon-arrow-up {
  content: '\e813';
}

@mixin flix-icon-attention-solid {
  content: '\e814';
}

@mixin flix-icon-attention {
  content: '\e815';
}

@mixin flix-icon-audio-solid {
  content: '\e816';
}

@mixin flix-icon-audio {
  content: '\e817';
}

@mixin flix-icon-beach-solid {
  content: '\e818';
}

@mixin flix-icon-beach {
  content: '\e819';
}

@mixin flix-icon-beer {
  content: '\e81a';
}

@mixin flix-icon-bike-solid {
  content: '\e81b';
}

@mixin flix-icon-bike {
  content: '\e81c';
}

@mixin flix-icon-birthday-solid {
  content: '\e81d';
}

@mixin flix-icon-birthday {
  content: '\e81e';
}

@mixin flix-icon-book-solid {
  content: '\e81f';
}

@mixin flix-icon-book {
  content: '\e820';
}

@mixin flix-icon-booster-solid {
  content: '\e821';
}

@mixin flix-icon-booster {
  content: '\e822';
}

@mixin flix-icon-burger {
  content: '\e823';
}

@mixin flix-icon-bus-night-solid {
  content: '\e824';
}

@mixin flix-icon-bus-night {
  content: '\e825';
}

@mixin flix-icon-bus-service-solid {
  content: '\e826';
}

@mixin flix-icon-bus-service {
  content: '\e827';
}

@mixin flix-icon-bus-solid {
  content: '\e828';
}

@mixin flix-icon-bus-stop-solid {
  content: '\e829';
}

@mixin flix-icon-bus-stop {
  content: '\e82a';
}

@mixin flix-icon-bus-time-solid {
  content: '\e82b';
}

@mixin flix-icon-bus {
  content: '\e82c';
}

@mixin flix-icon-bus-time {
  content: '\e82d';
}

@mixin flix-icon-buses {
  content: '\e82e';
}

@mixin flix-icon-calendar-solid {
  content: '\e82f';
}

@mixin flix-icon-calendar {
  content: '\e830';
}

@mixin flix-icon-call-solid {
  content: '\e831';
}

@mixin flix-icon-call {
  content: '\e832';
}

@mixin flix-icon-car-solid {
  content: '\e833';
}

@mixin flix-icon-car {
  content: '\e834';
}

@mixin flix-icon-cart-solid {
  content: '\e835';
}

@mixin flix-icon-cart {
  content: '\e836';
}

@mixin flix-icon-chat-solid {
  content: '\e837';
}

@mixin flix-icon-chat {
  content: '\e838';
}

@mixin flix-icon-check-solid {
  content: '\e839';
}

@mixin flix-icon-check {
  content: '\e83a';
}

@mixin flix-icon-checkmark-strong-solid {
  content: '\e83b';
}

@mixin flix-icon-checkmark-strong {
  content: '\e83c';
}

@mixin flix-icon-checkmark {
  content: '\e83d';
}

@mixin flix-icon-child-solid {
  content: '\e83e';
}

@mixin flix-icon-child {
  content: '\e83f';
}

@mixin flix-icon-city-solid {
  content: '\e840';
}

@mixin flix-icon-close {
  content: '\e841';
}

@mixin flix-icon-city {
  content: '\e842';
}

@mixin flix-icon-collaboration {
  content: '\e843';
}

@mixin flix-icon-collaboration-solid {
  content: '\e844';
}

@mixin flix-icon-collapse {
  content: '\e845';
}

@mixin flix-icon-connection {
  content: '\e846';
}

@mixin flix-icon-connection-transfer {
  content: '\e847';
}

@mixin flix-icon-credit-card-solid {
  content: '\e848';
}

@mixin flix-icon-credit-card {
  content: '\e849';
}

@mixin flix-icon-customer-solid {
  content: '\e84a';
}

@mixin flix-icon-customer {
  content: '\e84b';
}

@mixin flix-icon-delete-solid {
  content: '\e84c';
}

@mixin flix-icon-directions-solid {
  content: '\e84d';
}

@mixin flix-icon-delete {
  content: '\e84e';
}

@mixin flix-icon-directions {
  content: '\e84f';
}

@mixin flix-icon-discount-solid {
  content: '\e850';
}

@mixin flix-icon-discount {
  content: '\e851';
}

@mixin flix-icon-document-solid {
  content: '\e852';
}

@mixin flix-icon-document {
  content: '\e853';
}

@mixin flix-icon-drinks {
  content: '\e854';
}

@mixin flix-icon-drinks-solid {
  content: '\e855';
}

@mixin flix-icon-driver-solid {
  content: '\e856';
}

@mixin flix-icon-driver {
  content: '\e857';
}

@mixin flix-icon-ebus-solid {
  content: '\e858';
}

@mixin flix-icon-ebus {
  content: '\e859';
}

@mixin flix-icon-edit-solid {
  content: '\e85a';
}

@mixin flix-icon-edit {
  content: '\e85b';
}

@mixin flix-icon-entertainment-solid {
  content: '\e85c';
}

@mixin flix-icon-entertainment {
  content: '\e85d';
}

@mixin flix-icon-expand {
  content: '\e85e';
}

@mixin flix-icon-eye-no-solid {
  content: '\e85f';
}

@mixin flix-icon-eye-no {
  content: '\e860';
}

@mixin flix-icon-eye-solid {
  content: '\e861';
}

@mixin flix-icon-eye {
  content: '\e862';
}

@mixin flix-icon-ferry-solid {
  content: '\e863';
}

@mixin flix-icon-ferry {
  content: '\e864';
}

@mixin flix-icon-filter {
  content: '\e865';
}

@mixin flix-icon-flixtech-solid {
  content: '\e866';
}

@mixin flix-icon-flixtech {
  content: '\e867';
}

@mixin flix-icon-full-screen {
  content: '\e868';
}

@mixin flix-icon-games-solid {
  content: '\e869';
}

@mixin flix-icon-games {
  content: '\e86a';
}

@mixin flix-icon-group-solid {
  content: '\e86b';
}

@mixin flix-icon-group {
  content: '\e86c';
}

@mixin flix-icon-headphones-solid {
  content: '\e86d';
}

@mixin flix-icon-headphones {
  content: '\e86e';
}

@mixin flix-icon-home-solid {
  content: '\e86f';
}

@mixin flix-icon-home {
  content: '\e870';
}

@mixin flix-icon-info-solid {
  content: '\e871';
}

@mixin flix-icon-info {
  content: '\e872';
}

@mixin flix-icon-interconnection-solid {
  content: '\e873';
}

@mixin flix-icon-interconnection {
  content: '\e874';
}

@mixin flix-icon-invoice {
  content: '\e875';
}

@mixin flix-icon-label-attention-solid {
  content: '\e876';
}

@mixin flix-icon-label-attention {
  content: '\e877';
}

@mixin flix-icon-label-solid {
  content: '\e878';
}

@mixin flix-icon-label {
  content: '\e879';
}

@mixin flix-icon-leaf-solid {
  content: '\e87a';
}

@mixin flix-icon-leaf {
  content: '\e87b';
}

@mixin flix-icon-link-solid {
  content: '\e87c';
}

@mixin flix-icon-link {
  content: '\e87d';
}

@mixin flix-icon-location-solid {
  content: '\e87e';
}

@mixin flix-icon-location {
  content: '\e87f';
}

@mixin flix-icon-logout-solid {
  content: '\e880';
}

@mixin flix-icon-logout {
  content: '\e881';
}

@mixin flix-icon-lost-solid {
  content: '\e882';
}

@mixin flix-icon-lost {
  content: '\e883';
}

@mixin flix-icon-luggage-additional-solid {
  content: '\e884';
}

@mixin flix-icon-luggage-additional {
  content: '\e885';
}

@mixin flix-icon-luggage-bulky-solid {
  content: '\e886';
}

@mixin flix-icon-luggage-bulky {
  content: '\e887';
}

@mixin flix-icon-luggage-hand-solid {
  content: '\e888';
}

@mixin flix-icon-luggage-hand {
  content: '\e889';
}

@mixin flix-icon-luggage-solid {
  content: '\e88a';
}

@mixin flix-icon-luggage {
  content: '\e88b';
}

@mixin flix-icon-magnifier-solid {
  content: '\e88c';
}

@mixin flix-icon-magnifier {
  content: '\e88d';
}

@mixin flix-icon-map-solid {
  content: '\e88e';
}

@mixin flix-icon-map {
  content: '\e88f';
}

@mixin flix-icon-men-women-solid {
  content: '\e890';
}

@mixin flix-icon-men-women {
  content: '\e891';
}

@mixin flix-icon-minus {
  content: '\e892';
}

@mixin flix-icon-mountains-solid {
  content: '\e893';
}

@mixin flix-icon-mountains {
  content: '\e894';
}

@mixin flix-icon-new-tab-solid {
  content: '\e895';
}

@mixin flix-icon-new-tab {
  content: '\e896';
}

@mixin flix-icon-newsletter-solid {
  content: '\e897';
}

@mixin flix-icon-newsletter {
  content: '\e898';
}

@mixin flix-icon-night-solid {
  content: '\e899';
}

@mixin flix-icon-night {
  content: '\e89a';
}

@mixin flix-icon-partner-solid {
  content: '\e89b';
}

@mixin flix-icon-partner {
  content: '\e89c';
}

@mixin flix-icon-passport-solid {
  content: '\e89d';
}

@mixin flix-icon-passport {
  content: '\e89e';
}

@mixin flix-icon-person-solid {
  content: '\e89f';
}

@mixin flix-icon-person {
  content: '\e8a0';
}

@mixin flix-icon-phone-solid {
  content: '\e8a1';
}

@mixin flix-icon-phone-tablet-solid {
  content: '\e8a2';
}

@mixin flix-icon-phone-tablet {
  content: '\e8a3';
}

@mixin flix-icon-phone {
  content: '\e8a4';
}

@mixin flix-icon-pin-solid {
  content: '\e8a5';
}

@mixin flix-icon-pin {
  content: '\e8a6';
}

@mixin flix-icon-plane-solid {
  content: '\e8a7';
}

@mixin flix-icon-plane {
  content: '\e8a8';
}

@mixin flix-icon-plus {
  content: '\e8a9';
}

@mixin flix-icon-public-solid {
  content: '\e8aa';
}

@mixin flix-icon-public {
  content: '\e8ab';
}

@mixin flix-icon-q-and-a-solid {
  content: '\e8ac';
}

@mixin flix-icon-q-and-a {
  content: '\e8ad';
}

@mixin flix-icon-question-solid {
  content: '\e8ae';
}

@mixin flix-icon-question {
  content: '\e8af';
}

@mixin flix-icon-real-time-solid {
  content: '\e8b0';
}

@mixin flix-icon-real-time {
  content: '\e8b1';
}

@mixin flix-icon-rebook-solid {
  content: '\e8b2';
}

@mixin flix-icon-rebook {
  content: '\e8b3';
}

@mixin flix-icon-refresh {
  content: '\e8b4';
}

@mixin flix-icon-seat-belt-solid {
  content: '\e8b5';
}

@mixin flix-icon-seat-belt {
  content: '\e8b6';
}

@mixin flix-icon-seat-solid {
  content: '\e8b7';
}

@mixin flix-icon-seat {
  content: '\e8b8';
}

@mixin flix-icon-secure-payment-solid {
  content: '\e8b9';
}

@mixin flix-icon-secure-payment {
  content: '\e8ba';
}

@mixin flix-icon-security-solid {
  content: '\e8bb';
}

@mixin flix-icon-security {
  content: '\e8bc';
}

@mixin flix-icon-settings-solid {
  content: '\e8bd';
}

@mixin flix-icon-settings {
  content: '\e8be';
}

@mixin flix-icon-share-file-solid {
  content: '\e8bf';
}

@mixin flix-icon-share-file {
  content: '\e8c0';
}

@mixin flix-icon-sit-solid {
  content: '\e8c1';
}

@mixin flix-icon-sit {
  content: '\e8c2';
}

@mixin flix-icon-snacks-solid {
  content: '\e8c3';
}

@mixin flix-icon-snacks {
  content: '\e8c4';
}

@mixin flix-icon-socket-solid {
  content: '\e8c5';
}

@mixin flix-icon-socket {
  content: '\e8c6';
}

@mixin flix-icon-stopwatch-solid {
  content: '\e8c7';
}

@mixin flix-icon-stopwatch {
  content: '\e8c8';
}

@mixin flix-icon-stroller-solid {
  content: '\e8c9';
}

@mixin flix-icon-stroller {
  content: '\e8ca';
}

@mixin flix-icon-suitcase-solid {
  content: '\e8cb';
}

@mixin flix-icon-suitcase {
  content: '\e8cc';
}

@mixin flix-icon-switch-vertical {
  content: '\e8cd';
}

@mixin flix-icon-switch {
  content: '\e8ce';
}

@mixin flix-icon-ticket-solid {
  content: '\e8cf';
}

@mixin flix-icon-ticket-special-solid {
  content: '\e8d0';
}

@mixin flix-icon-ticket-special {
  content: '\e8d1';
}

@mixin flix-icon-ticket {
  content: '\e8d2';
}

@mixin flix-icon-time-solid {
  content: '\e8d3';
}

@mixin flix-icon-time {
  content: '\e8d4';
}

@mixin flix-icon-toilet-solid {
  content: '\e8d5';
}

@mixin flix-icon-toilet {
  content: '\e8d6';
}

@mixin flix-icon-train-night-solid {
  content: '\e8d7';
}

@mixin flix-icon-train-night {
  content: '\e8d8';
}

@mixin flix-icon-train-service-solid {
  content: '\e8d9';
}

@mixin flix-icon-train-service {
  content: '\e8da';
}

@mixin flix-icon-train-solid {
  content: '\e8db';
}

@mixin flix-icon-train-stop-solid {
  content: '\e8dc';
}

@mixin flix-icon-train-stop {
  content: '\e8dd';
}

@mixin flix-icon-train-time-solid {
  content: '\e8de';
}

@mixin flix-icon-train-time {
  content: '\e8df';
}

@mixin flix-icon-train-wagon-solid {
  content: '\e8e0';
}

@mixin flix-icon-train {
  content: '\e8e1';
}

@mixin flix-icon-transfer-solid {
  content: '\e8e2';
}

@mixin flix-icon-transfer {
  content: '\e8e3';
}

@mixin flix-icon-trip-solid {
  content: '\e8e4';
}

@mixin flix-icon-trip {
  content: '\e8e5';
}

@mixin flix-icon-tunnel {
  content: '\e8e6';
}

@mixin flix-icon-user-add-group-solid {
  content: '\e8e7';
}

@mixin flix-icon-user-add-group {
  content: '\e8e8';
}

@mixin flix-icon-user-add-solid {
  content: '\e8e9';
}

@mixin flix-icon-user-add {
  content: '\e8ea';
}

@mixin flix-icon-user-minus-solid {
  content: '\e8eb';
}

@mixin flix-icon-user-minus {
  content: '\e8ec';
}

@mixin flix-icon-user-private-solid {
  content: '\e8ed';
}

@mixin flix-icon-user-private {
  content: '\e8ee';
}

@mixin flix-icon-voucher-solid {
  content: '\e8ef';
}

@mixin flix-icon-voucher {
  content: '\e8f0';
}

@mixin flix-icon-warning {
  content: '\e8f1';
}

@mixin flix-icon-wheelchair-solid {
  content: '\e8f2';
}

@mixin flix-icon-wheelchair {
  content: '\e8f3';
}

@mixin flix-icon-wifi-solid {
  content: '\e8f4';
}

@mixin flix-icon-wifi {
  content: '\e8f5';
}

@mixin flix-icon-account-attention-solid {
  content: '\e800';
}

@mixin flix-icon-download {
  content: '\e8f6';
}

@mixin flix-icon-station-solid {
  content: '\e8f7';
}

@mixin flix-icon-station {
  content: '\e8f8';
}

@mixin flix-icon-upload {
  content: '\e8f9';
}

@mixin flix-icon-watch-solid {
  content: '\e8fa';
}

@mixin flix-icon-watch {
  content: '\e8fb';
}

@mixin flix-icon-bus-double-solid {
  content: '\e8fc';
}

@mixin flix-icon-bus-double {
  content: '\e8fd';
}

@mixin flix-icon-desktop {
  content: '\e8fe';
}

@mixin flix-icon-ride-share-solid {
  content: '\e8ff';
}

@mixin flix-icon-ride-share {
  content: '\e900';
}

@mixin flix-icon-smoking-no {
  content: '\e901';
}

@mixin flix-icon-smoking {
  content: '\e902';
}

@mixin flix-icon-sort {
  content: '\e903';
}

@mixin flix-icon-tablet {
  content: '\e904';
}

@mixin flix-icon-zoom-in {
  content: '\e905';
}

@mixin flix-icon-zoom-out {
  content: '\e906';
}

@mixin flix-icon-cash {
  content: '\e907';
}

@mixin flix-icon-forbidden {
  content: '\e908';
}

@mixin flix-icon-offer-solid {
  content: '\e909';
}

@mixin flix-icon-offer {
  content: '\e90a';
}

@mixin flix-icon-star-solid {
  content: '\e90b';
}

@mixin flix-icon-star {
  content: '\e90c';
}

@mixin flix-icon-bulb-solid {
  content: '\e90d';
}

@mixin flix-icon-bulb {
  content: '\e90e';
}

@mixin flix-icon-car-seat {
  content: '\e910';
}

@mixin flix-icon-lock-solid {
  content: '\e911';
}

@mixin flix-icon-lock {
  content: '\e912';
}

@mixin flix-icon-notification-solid {
  content: '\e913';
}

@mixin flix-icon-notification {
  content: '\e914';
}

@mixin flix-icon-pause-solid {
  content: '\e915';
}

@mixin flix-icon-pause {
  content: '\e916';
}

@mixin flix-icon-play-solid {
  content: '\e917';
}

@mixin flix-icon-play {
  content: '\e918';
}

@mixin flix-icon-wheelchair-info-solid {
  content: '\e919';
}

@mixin flix-icon-wheelchair-info {
  content: '\e91a';
}

@mixin flix-icon-car-seat-solid {
  content: '\e91b';
}
