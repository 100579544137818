// ----------------------------------------------
// Progress (.[prefix-]progress-tracker)
// ----------------------------------------------

@import '../themes/theme-default';
@import '../config/all';
@import '../utils/all';

// ------------------------------------
// style variables
// ------------------------------------
$progress-tracker-item-min-width: 60px;
$progress-tracker-item-min-width-desktop: 145px;
$progress-tracker-item-height: 36px;
$progress-tracker-item-side-padding: 45px;
$progress-tracker-color: $primary-ui-color;
$progress-tracker-active-color: $plain-white-color;
$progress-tracker-bg-color: $plain-white-color;
$progress-tracker-active-bg-color: $primary-ui-color;
$progress-tracker-font-weight: $font-weight-semibold;


#{$config-class-prefix} {
  &progress-tracker {
    display: flex;
    // align steps horizontally within the container
    justify-content: center;
    height: $progress-tracker-item-height;
    padding-right: $progress-tracker-item-height / 2; // allows us compensating last item's arrow width
    overflow: hidden;
    list-style-type: none;

    &__item {
      display: flex;
      position: relative;
      flex-grow: 1;
      justify-content: center;
      min-width: $progress-tracker-item-min-width;
      height: $progress-tracker-item-height;
      padding-right: get-spacing(xs)/2;
      padding-left: $progress-tracker-item-height/2 + get-spacing(xs)/2;
      border: 1px solid $progress-tracker-active-bg-color;
      border-right: 0;
      background: $progress-tracker-bg-color;
      color: $progress-tracker-color;
      font-weight: $progress-tracker-font-weight;
      line-height: $progress-tracker-item-height - 2; // compensating top/bottom borders
      text-decoration: none;

      @include on-bp(sm) {
        flex-grow: 0; // items occupy the whole space on mobile only
        min-width: $progress-tracker-item-min-width-desktop;
      }

      // first progress item needs to have border radiuses on the left that are seen
      &:first-child {
        border-radius: $primary-border-radius 0 0 $primary-border-radius;
      }

      // adds right arrow made of pseudo elements
      &::before,
      &::after {
        position: absolute;
        top: 50%;
        left: 100%;
        width: 0;
        height: 0;
        border: solid transparent;
        content: '';
        z-index: 1;
      }

      &::after {
        margin-top: -($progress-tracker-item-height/2 - 1);
        border-width: $progress-tracker-item-height/2 - 1;
        border-left-color: $progress-tracker-bg-color;
      }

      &::before {
        margin-top: -$progress-tracker-item-height/2;
        border-width: $progress-tracker-item-height/2;
        border-left-color: $progress-tracker-active-bg-color;
      }

      &--active {
        background: $progress-tracker-active-bg-color;
        color: $progress-tracker-active-color;

        &::after {
          border-left-color: $progress-tracker-active-bg-color;
        }

        &::before {
          border-left-color: $progress-tracker-bg-color;
        }
      }
    }

    &__number {
      margin-right: .25em;

      // we need to hide the dot on mobile
      // @todo consider using CSS counters instead of dedicated number field
      @include on-bp(sm) {
        &::after {
          content: '.';
        }
      }
    }

    &__text {
      display: none;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;

      @include on-bp(sm) {
        display: block;
      }
    }
  }
}
