// ------------------------------------
// input utilities
// ------------------------------------
/// @access private
// ------------------------------------

@import '../config/all';
@import '../themes/theme-default';
@import 'positioning'; // pulling in get-spacing() & z()
@import 'assets-urls'; // pulling in svg-url-encode function
// base icon mixins to get icons
@import '../icons/base-mixins';
@import '../icons/icon-svg-mixins';

// ------------------------------------
// input-basic
// ------------------------------------
// defines basic input styles
// ------------------------------------

@mixin input-basic {
  @include font-primary-normal;
  position: relative;
  width: 100%;
  height: $input-height-mobile;
  padding: 0 get-spacing(xs);
  border: 1px solid $input-border-color;
  border-radius: $input-border-radius;
  background-color: $input-bg-color;
  color: $primary-content-color;
  line-height: $line-height-primary;
  text-overflow: ellipsis;
  // getting rid of default iOS webkit styles
  appearance: none;

  @include on-bp(xl) {
    height: $input-height-desktop;
  }

  &::placeholder {
    color: $input-placeholder-color;
    font-style: italic;
  }
}

// ------------------------------------
// input-states
// ------------------------------------
// defines common appearance params for input error/valid states
// currently requires icon mixins (imported here above)
@mixin input-states ($top: 10px, $right: get-spacing(xs), $bottom: auto, $left: auto) {
  // valid and error states have icons
  &--error::before,
  &--valid::before {
    @include flix-svg-icon-small;
    position: absolute;
    // skips "auto" properties to save few CSS lines
    @if ($top != auto ) {
      top: $top;
    }
    @if ($right != auto ) {
      right: $right;
    }
    @if ($bottom != auto ) {
      bottom: $bottom;
    }
    @if ($left != auto ) {
      left: $left;
    }
    z-index: z(form-label);

    @if ($top != auto ) {
      @include on-bp(xl) {
        top: $top + 20px; // compensating label height
      }
    }
  }

  &--error::before {
    @include flix-svg-icon-attention-solid($fail-color);
  }

  &--valid::before {
    @include flix-svg-icon-checkmark-strong-solid($success-color);
  }
}

// ------------------------------------
// input-outline
// ------------------------------------
// outline styles for for inputs
@mixin input-outline () {
  outline: 1px solid transparent;
  box-shadow: 0 0 3px $neutral-color; //@todo come up with general outline styles and strategy
}
