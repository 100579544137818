// ----------------------------------------------
// Table
// ----------------------------------------------
// .[prefix-]table
// .[prefix-]table__wrap

@import '../themes/theme-default';
@import '../config/all';
@import '../utils/all';

#{$config-class-prefix} {

  &table__wrap {
    display: inline-block;
    margin-bottom: get-spacing(xs);
    padding: get-spacing(xs) 0;
    border: 1px solid $primary-line-color;
    border-radius: $primary-border-radius;
  }

  &table {
    border-collapse: separate;
    border-spacing: get-spacing(sm) 0;

    &__header th,
    &__header td {
      @extend %table-cell;
      @include show-as-h4();
    }

    td {
      @extend %table-cell;
    }

    tr:last-child td {
      border-bottom: 0;
    }
  }
}

%table-cell {
  padding: get-spacing(xs) 0;
  border-bottom: 1px solid $primary-line-color;
  text-align: left;
}
