// ----------------------------------------------
// Box (.prefix-box)
// ----------------------------------------------

@import '../themes/theme-default';
@import '../config/all';
@import '../utils/all';

// ------------------------------------
// style variables
// ------------------------------------
$box-bg-color: $box-background-color;
$box-border-radius: $primary-border-radius;
$box-border-color: $primary-line-color;
$box-box-shadow: $primary-box-shadow;

#{$config-class-prefix} {
  &box {
    margin-bottom: get-spacing(xs);
    padding: get-spacing(xs);
    border: 1px solid $box-border-color;
    border-radius: $box-border-radius;
    background: $box-bg-color;

    @include on-bp(sm) {
      padding: get-spacing(sm);
    }

    &--highlighted {
      border: 0;
      box-shadow: $box-box-shadow;
    }
  }
}
