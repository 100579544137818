// ----------------------------------------------
// Link (.[prefix-]link)
// ----------------------------------------------

@import '../themes/theme-default';
@import '../config/all';
@import '../utils/all';

@include exports('link') {
  #{$config-class-prefix} {
    &link {
      // Remove the gray background on active links in IE 10.
      background-color: transparent;
      color: $link-color;
      font-weight: $font-weight-semibold;
      text-decoration: none;

      &:hover,
      &:active,
      &:focus {
        color: $link-color;
        text-decoration: underline;
      }
    }
  }
}
