// ----------------------------------------------
// Linked list element
// ----------------------------------------------
// .[prefix-]list-linked
// ------------------------------------

@import '../themes/theme-default';
@import '../config/all';
@import '../utils/all';
// base icon mixins to get arrow icons
@import '../icons/base-mixins';
@import '../icons/icon-svg-mixins';


#{$config-class-prefix} {

  // List of linked elements
  // ----------------------------------------------

  &list-linked {
    margin: 0;
    padding-left: 0;
    list-style-type: none;

    &__item {
      position: relative;
      border-bottom: 1px solid $primary-line-color;

      &::after {
        @include flix-svg-icon-small;
        @include flix-svg-icon-arrow-right;
        position: absolute;
        top: 50%;
        right: get-spacing(xs);
        margin-top: -11px;
      }

      &:hover,
      &:active,
      &:focus {
        border-radius: $primary-border-radius;
        box-shadow: $primary-box-shadow;
      }

      &:last-child {
        border-bottom: 0;
      }
    }

    a, // class agnostic styling for react children
    &__link {
      display: flex;
      align-items: center;
      width: 100%;
      height: 60px;
      padding: 5px get-spacing(sm) 5px get-spacing(xs);
      color: $primary-content-color;
      font-size: $font-size-primary;
      font-weight: $font-weight-semibold;
      text-decoration: none;
      cursor: pointer;

      &:hover,
      &:active,
      &:focus {
        text-decoration: none;
      }
    }
  }
}
