// ----------------------------------------------
// Pager element
// ----------------------------------------------
// .[prefix-]pager
// ------------------------------------

@import '../themes/theme-default';
@import '../config/all';
@import '../utils/all';
// base icon mixins to get arrow icons
@import '../icons/base-mixins';
@import '../icons/icon-svg-mixins';

// ------------------------------------
// style variables
// ------------------------------------
$pager-font-weight: $font-weight-semibold;
$pager-font-size: $font-size-primary;
$pager-text-color: $primary-content-color;
$pager-text-active-color: $plain-white-color;
$pager-text-active-bg-color: $primary-ui-color;
$pager-item-border-radius: $primary-border-radius;
$pager-item-border-color: $input-border-color;
$pager-item-size: 44px; // using size in here since items are squares
$pager-item-size-xl: 36px;

#{$config-class-prefix} {
  &pager {
    display: inline-flex;
    flex-wrap: wrap;
    max-width: 100%;
    padding-top: 1px; // compensating negative top margin for items

    &__item {
      display: block;
      min-width: $pager-item-size; // mix-width allows us some stretching for long numbers
      height: $pager-item-size;
      margin-top: -1px; // compensating the double borders
      margin-left: -1px; // compensating the double borders
      border: 1px solid $pager-item-border-color;
      // border-right: 0; // ensuring single borders on the sides
      color: $pager-text-color;
      font-size: $pager-font-size;
      font-weight: $pager-font-weight;
      line-height: $pager-item-size - 2; // compensating borders
      text-align: center;
      text-decoration: none;

      &:focus,
      &:active {
        text-decoration: none;
      }

      @include on-bp(xl) {
        min-width: $pager-item-size-xl;
        height: $pager-item-size-xl;
        line-height: $pager-item-size-xl;
      }

      &:first-child {
        border-radius: $pager-item-border-radius 0 0 $pager-item-border-radius;
      }

      &:last-child {
        border-right: 1px solid $pager-item-border-color;
        border-radius: 0 $pager-item-border-radius $pager-item-border-radius 0;
      }

      &--active {
        background-color: $pager-text-active-bg-color;
        color: $pager-text-active-color;
      }

      &--disabled {
        cursor: not-allowed;
        opacity: .5;
      }

      &--prev {
        &::after {
          @include flix-svg-icon-small;
          @include flix-svg-icon-arrow-left($pager-text-color);
        }
      }

      &--next {
        &::after {
          @include flix-svg-icon-small;
          @include flix-svg-icon-arrow-right($pager-text-color);
        }
      }
    }
  }
}
