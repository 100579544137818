// ----------------------------------------------
// Checkbox (.[prefix-]checkbox)
// ----------------------------------------------

@import '../themes/theme-default';
@import '../config/all';
@import '../utils/all';

// ------------------------------------
// style variables
// ------------------------------------

$checkbox-size: 30px;
$checkbox-checkmark-size: 24px;
$checkbox-focus-color: $curacao-blue-color;

#{$config-class-prefix} {

  &checkbox {
    @include basic-choice($checkbox-size);

    // check mark gets displayed for checked state only
    &__input:checked + &__label::after {
      display: block;
      position: absolute;
      top: ($checkbox-size - $checkbox-checkmark-size)/2; // calculating position depending on the size to center properly
      left: ($checkbox-size - $checkbox-checkmark-size)/2;
      width: $checkbox-checkmark-size;
      height: $checkbox-checkmark-size;
      background: svg-url-encode($image-svg-valid) no-repeat center; // mimics checkmark of valid inputs
      content: '';
      animation: checkmark-grow 250ms;
    }

    @include anim-checkmark-grow($anim-name: 'checkmark-grow');
  }
}
