// ----------------------------------------------
// Button (.prefix-btn)
// ----------------------------------------------

@import '../themes/theme-default';
@import '../config/all';
@import '../utils/all';

// ------------------------------------
// style variables
// ------------------------------------
$button-size: 36px; // defines heights and width for square buttons, used for height only in others
$button-size-mobile: 44px;
$button-font-size: 18px;
$button-font-weight: $font-weight-semibold;
$button-line-height: 24px;
$button-text-color: $plain-white-color;
$button-primary-bg-color: $secondary-ui-color ;
$button-primary-hover-bg-color: $secondary-ui-dark-color;
$button-secondary-bg-color: $primary-ui-color;
$button-secondary-hover-bg-color: $primary-ui-dark-color;
$button-tertiary-bg-color: transparent;
$button-tertiary-hover-bg-color: $primary-ui-dark-color;
$button-tertiary-text-color: $primary-ui-color;
$button-danger-bg-color: $fail-color;
$button-danger-hover-bg-color: $fail-dark-color;
$button-disabled-bg-color: $disabled-color;
$button-radius: $primary-border-radius;
$button-loading-spinner-size: 18px;
$button-spinner-color: rgba(255, 255, 255, 0.6);
$button-spinner-slice-color: rgba(255, 255, 255, 1);

#{$config-class-prefix} {
  &btn {
    display: inline-block;
    position: relative;
    width: 100%;
    min-width: 142px;
    max-width: 100%;
    height: $button-size-mobile;
    padding: 0 get-spacing(xs);
    transition: background 250ms linear;
    border: 0;
    border-radius: $button-radius;
    font-family: $font-family-primary;
    font-size: $button-font-size;
    font-weight: $button-font-weight;
    line-height: $button-size-mobile;
    text-align: center;
    text-decoration: none;
    text-overflow: ellipsis;
    white-space: nowrap; // no multiline content in buttons allowed
    cursor: pointer;
    overflow: hidden;
    vertical-align: middle;
    // getting rid of default iOS webkit styles
    appearance: none;

    @include on-bp(sm) {
      width: auto;
    }

    @include on-bp(xl) {
      height: $button-size;
      line-height: $button-size;
    }

    // buttons side-by-side have default margin
    // @todo reconsider supporting this in favour of grid usage or button group elements
    & + & {
      margin-top: get-spacing(xs);

      @include on-bp(sm) {
        margin-top: 0;
        margin-left: get-spacing(xs);
      }
    }

    &--block {
      display: block;
      width: 100%;
    }

    &--square {
      width: $button-size-mobile;
      min-width: auto;
      padding: 0;
      // no multiline text allowed, cut everything that exceeds button's width
      overflow: hidden;

      @include on-bp(xl) {
        width: $button-size;
      }

      // square buttons don't have top margins since they don't fill the container
      & + & {
        margin-top: 0;
        margin-left: get-spacing(xs);
      }
    }

    &--primary {
      border: 0;
      background: $button-primary-bg-color;
      color: $button-text-color;

      &:hover,
      &:active,
      &#{$config-class-prefix}btn--loading {
        background: $button-primary-hover-bg-color;
        text-decoration: none;
      }
    }

    &--secondary {
      border: 0;
      background: $button-secondary-bg-color;
      color: $button-text-color;

      &:hover,
      &:active,
      &#{$config-class-prefix}btn--loading {
        background: $button-secondary-hover-bg-color;
        text-decoration: none;
      }
    }

    &--danger {
      border: 0;
      background: $button-danger-bg-color;
      color: $button-text-color;

      &:hover,
      &:active,
      &#{$config-class-prefix}btn--loading {
        background: $button-danger-hover-bg-color;
        text-decoration: none;
      }
    }

    &--tertiary {
      // compensating the border width to preserve the same button height
      border: 1px solid $button-tertiary-text-color;
      background: $button-tertiary-bg-color;
      color: $button-tertiary-text-color;
      line-height: $button-size-mobile - 2; // compensating the borders

      @include on-bp(xl) {
        line-height: $button-size - 2; // compensating the borders
      }

      &:hover,
      &:active,
      &#{$config-class-prefix}btn--loading {
        background-color: $button-tertiary-hover-bg-color;
        color: $button-text-color;
        text-decoration: none;
      }
    }

    &:disabled,
    &--disabled {
      border: 0;
      background: $button-disabled-bg-color;
      color: $button-text-color;
      cursor: not-allowed;

      &:hover {
        background: $button-disabled-bg-color;
        text-decoration: none;
      }
    }

    &--loading {
      // animated rounded spinner
      &::before {
        display: inline-block;
        // min-width is enough for flexbox, it doesn't allow element to shrink in size
        width: $button-loading-spinner-size;
        height: $button-loading-spinner-size;
        margin-top: -2px; // helps center loader properly, @todo investigate for a better way
        margin-right: .5em;
        border: 1px solid $button-spinner-color;
        border-radius: 50%;
        border-left-color: $button-spinner-slice-color;
        content: '';
        vertical-align: middle;
        animation: btn-spinner-rotate 1s infinite linear, btn-spinner-grow 250ms;
      }
      @include anim-grow-fade(
        $anim-name: 'btn-spinner-grow',
        $to-width: $button-loading-spinner-size,
        $to-height: $button-loading-spinner-size
      );
      @include anim-rotate(
        $anim-name: 'btn-spinner-rotate'
      )
    }

    &--has-icon {
      &#{$config-class-prefix}btn--loading {
        &:before {
          margin-left: .5em; // helps center loader properly
        }

        #{$config-class-prefix}btn__icon {
          display: none; // icons get replaced with the loader in square buttons
        }
      }
    }

    &__icon {
      margin-top: -2px; // helps center loader properly, @todo investigate for a better way
      vertical-align: middle;
    }

    &__text {
      // compensates icon font margin, centers content properly
      margin-right: .2em;
    }
  }
}
