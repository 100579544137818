// ----------------------------------------------
// Radio input (.[prefix-]radio)
// ----------------------------------------------

@import '../themes/theme-default';
@import '../config/all';
@import '../utils/all';

// ------------------------------------
// style variables
// ------------------------------------

$radio-size: 30px;
$radio-checkmark-size: 16px;
$radio-focus-color: $curacao-blue-color;

#{$config-class-prefix} {
  &radio {

    @include basic-choice($radio-size, $border-radius: 100%);

    // check mark gets displayed for checked state only
    &__input:checked + &__label::after {
      display: block;
      position: absolute;
      top: ($radio-size - $radio-checkmark-size)/2; // calculating position depending on the size to center properly
      left: ($radio-size - $radio-checkmark-size)/2;
      width: $radio-checkmark-size;
      height: $radio-checkmark-size;
      border-radius: 100%;
      background: $success-color;
      content: '';
      animation: radio-grow 250ms;
    }
    @include anim-checkmark-grow($anim-name: 'radio-grow');
  }
}
