// ------------------------------------
// choice field utilities
// ------------------------------------
/// @access private
// ------------------------------------

@import '../config/all';
@import '../themes/theme-default';

// ------------------------------------
// basic-choice
// ------------------------------------
// defines basic choice form elements styles
// ------------------------------------
@mixin basic-choice ($size, $border-radius: $primary-border-radius) {
  position: relative;

  &__label {
    display: inline-flex;
    align-items: center; // keeps text oneliners centered with the checkbox
    min-height: $size; // matches checkbox height
    padding-left: $size + get-spacing(xs);
    cursor: pointer;

    &::before {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: $size;
      height: $size;
      border: 2px solid $input-border-color;
      border-radius: $border-radius;
      content: '';
    }
  }

  &__input {
    position: absolute;
    opacity: 0;
  }

  &__input:focus + &__label::before {
    @include input-outline;
  }

  // coloring for checked state
  &__input:checked + &__label::before {
    border-color: $success-color;
  }

  &__info {
    @include show-as-fineprint();
    display: block;
    padding-top: 3px;
  }

  // disabled state, same logic as for other inputs in here
  &--disabled &__label {
    cursor: not-allowed;
    opacity: .5;
  }

  &--error &__label:before {
    border-color: $fail-color;
  }

  &--error &__info {
    color: $input-error-info-color;
  }
}
