// ------------------------------------
// Form label
//
// Important note!!!
// Labels rely on having a position:relative container
// so DON"T meant to be used on their own outside of form inputs
// ------------------------------------
// .[prefix-]label
// ------------------------------------

@import '../themes/theme-default';
@import '../config/all';
@import '../utils/all';

// ------------------------------------
// style variables
// ------------------------------------
$label-bg-color: transparent;
$label-bg-color-mobile: $primary-bg-color;
$label-font-size-mobile: $font-size-small;
$label-font-size: $font-size-primary;

#{$config-class-prefix} {
  &label {
    display: block;
    position: absolute;
    top: -8px; // 50% of the label height
    // left + paddiing === get-spacing(xs)
    left: 7px;
    padding: 1px 5px;
    border-radius: $primary-border-radius;
    background: $label-bg-color-mobile;
    color: $primary-content-color;
    font-family: $font-family-primary;
    font-size: $label-font-size-mobile;
    font-weight: $font-weight-normal;
    line-height: 1em;
    z-index: z('form-label');

    @include on-bp(xl) {
      position: static;
      top: auto;
      left: auto;
      padding: 0 0 2px;
      background: $label-bg-color;
      color: $secondary-content-color;
      font-size: $label-font-size;
      line-height: $line-height-primary;
    }
  }
}
