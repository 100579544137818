
// Skeleton loader placeholders styles
// ----------------------------------------------

@import '../themes/theme-default';
@import '../config/all';
@import '../utils/all';

// ------------------------------------
// style variables
// ------------------------------------
$skeleton-color: $grayscale-30-color;
$skeleton-border-radius: $primary-border-radius;
$skeleton-height: 15px;
$skeleton-xs-height: 1px;
$skeleton-md-height: 26px;
$skeleton-lg-height: 44px;
$skeleton-lg-height-desktop: 36px;
$skeleton-xs-width: 25%;
$skeleton-sm-width: 33%;
$skeleton-md-width: 50%;
$skeleton-lg-width: 75%;


#{$config-class-prefix} {
  &skeleton {
    display: block;
    width: 100%;
    height: $skeleton-height;
    margin-bottom: 7px;
    border-radius: $skeleton-border-radius;
    background: $skeleton-color;
    content: '';


    // skeleton width options
    &--w-xs {
      width: $skeleton-xs-width;
    }

    &--w-sm {
      width: $skeleton-sm-width;
    }

    &--w-md {
      width: $skeleton-md-width;
    }

    &--w-lg {
      width: $skeleton-lg-width;
    }


    // skeleton height options
    &--h-xs {
      height: $skeleton-xs-height;
      margin-bottom: get-spacing(xs);
    }

    &--h-md {
      height: $skeleton-md-height;
      margin-bottom: get-spacing(xs);
    }

    // mimics height change of form controls depending on the screen size
    &--h-lg {
      height: $skeleton-lg-height;
      margin-bottom: get-spacing(xs);

      @include on-bp(xl) {
        height: $skeleton-lg-height-desktop;
      }
    }

    // skipping the bottom margin if needed

    &--flush-bottom {
      margin-bottom: 0;
    }
  }
}
