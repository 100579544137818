// ----------------------------------------------
// Number (quantity) input (.[prefix-]quantity)
// ----------------------------------------------

@import '../themes/theme-default';
@import '../config/all';
@import '../utils/all';
@import '../icons/base-mixins';
@import '../icons/icon-svg-mixins';

#{$config-class-prefix} {
  &quantity {

    &__label {
      display: block;
      margin-bottom: get-spacing(xs);
      color: $primary-content-color;
      @include on-bp(xl) {
        margin-bottom: 2px;
      }
    }

    &--inline {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &--inline &__label {
      align-self: center;
      margin-bottom: 0;
      font-size: $font-size-primary;
      font-weight: $font-weight-bold;
      line-height: 22px;

      small {
        display: block;
        font-size: $font-size-small;
        font-weight: $font-weight-normal;
        line-height: 17px;
      }
    }

    &__picker {
      display: flex;
      align-items: stretch;

      // it's easier and more transparent to control the appearance of the element by applying the modifiers to the core wrapper
      &--active {

        #{$config-class-prefix}quantity__input {
          border: 1px solid $input-active-border-color;
        }
      }

      // apply disabled style for all the elements inside when the root element has --disabled modifier
      &--disabled {

        #{$config-class-prefix}quantity__input,
        #{$config-class-prefix}quantity__button {
          opacity: .5;
        }
      }

      // apply error style for input element inside when the root element has --error modifier
      &--error {

        #{$config-class-prefix}quantity__input {
          border: 1px solid $input-error-border-color;
        }
      }
    }

    // number input shares common input styles mostly
    &__input {
      // sass-lint:disable no-vendor-prefixes
      align-self: center;
      @include input-basic;
      width: 60px;
      text-align: center;

      // A bit of normalization for input[type=number] to sort out browser rendering differences on a Shadow DOM layer
      // @todo find a way to include to a general normalize kit maybe
      -moz-appearance: textfield; // Removes controls from Firefox

      &::-webkit-inner-spin-button,
      &::-webkit-outer-spin-button {
        margin: 0; // Removes leftover margin
        -webkit-appearance: none; // Removes controls from Safari and Chrome
      }

      &:focus {
        border: 1px solid $input-active-border-color;
      }
    }

    &__button {
      width: 35px; // 20px icon size + 15px padding
      padding: 0;
      border: 0;
      background: transparent;
      color: $primary-icon-color;
      font-size: $font-size-primary;
      cursor: pointer;

      &:disabled,
      &--disabled {
        cursor: not-allowed;
        opacity: .5;
      }

      // use icon SVGs for controls
      &:after {
        @include flix-svg-icon-small;
      }

      &--minus {
        padding-right: get-spacing(xs);

        &:after {
          @include flix-svg-icon-minus($primary-icon-color);
          // making this same sieze as font icons
          // @todo verify proper icon size and remove this in 4.0
          background-size: 20px;
        }
      }

      &--plus {
        padding-left: get-spacing(xs);

        // plus icon code in flixfont as defined in .flix-icon-plus:before
        &:after {
          @include flix-svg-icon-plus($primary-icon-color);
          // making this same sieze as font icons
          // @todo verify proper icon size and remove this in 4.0
          background-size: 20px;
        }
      }
    }
  }
}
