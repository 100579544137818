// ------------------------------------
// Input select (.[prefix-]select)
// ------------------------------------

@import '../themes/theme-default';
@import '../config/all';
@import '../utils/all';
@import '../icons/base-mixins';
@import '../icons/icon-svg-mixins';

#{$config-class-prefix} {
  &select {
    position: relative;

    // set states right positioning based on select icon size  plus double spacing
    @include input-states($right: #{25px + get-spacing(xs) * 2});

    // adding arrow, ::before element is used for validation icons, so using ::after here
    &::after {
      @include flix-svg-icon-small;
      @include flix-svg-icon-arrow-down($primary-icon-color);

      position: absolute;
      top: 10px;
      right: get-spacing(xs);
      pointer-events: none; // let arrow clicks go to select itself

      @include on-bp(xl) {
        top: 10px + 20px; // compensating label height
      }
    }

    &__field {
      // sass-lint:disable no-vendor-prefixes
      @include input-basic;
      padding-right: get-spacing(lg); // we don't want text to overlap arrow icon
      background: transparent;

      &:hover,
      &:active,
      &:focus {
        outline: 0;
      }

      #{$config-class-prefix}select--valid & {
        padding-right: 80px;
      }

      #{$config-class-prefix}select--error & {
        padding-right: 80px;
        border: 1px solid $input-error-border-color;
      }

      &:focus,
      #{$config-class-prefix}select--active & {
        border: 1px solid $input-active-border-color;
        outline: none;
      }

      &[disabled],
      #{$config-class-prefix}select--disabled & {
        cursor: not-allowed;
        opacity: .5;
      }

      &::-ms-expand {
        display: none;
      }
    }


    &__info {
      @include show-as-fineprint();
      display: block; // element needs to be a block one to have the proper line height applied
      padding-top: 3px; // we agreed on using a custom value in here because it looks better

      #{$config-class-prefix}select--error & {
        color: $input-error-info-color;
      }
    }

  }
}
