// ------------------------------------
// Spacing helpers
// ------------------------------------

@import '../themes/theme-default';
@import '../config/all';
@import '../utils/all';

#{$config-class-prefix} {

  @each $size, $value in $s-map {

    // adds margins of predefined values to specified directions

    &space-#{$size}-top {
      margin-top: get-spacing($size);
    }

    &space-#{$size}-bottom {
      margin-bottom: get-spacing($size);
    }

    &space-#{$size}-left {
      margin-left: get-spacing($size);
    }

    &space-#{$size}-right {
      margin-right: get-spacing($size);
    }
  }

  &space-flush-top {
    margin-top: 0;
  }

  &space-flush-bottom {
    margin-bottom: 0;
  }

  &space-flush-left {
    margin-left: 0;
  }

  &space-flush-right {
    margin-right: 0;
  }

}
