// ----------------------------------------------
// Select group (.[prefix-]select-group)
// ----------------------------------------------

@import '../themes/theme-default';
@import '../config/all';
@import '../utils/all';

// ------------------------------------
// style variables
// ------------------------------------

$select-group-item-size: $input-height-mobile;
$select-group-item-size-xl: $input-height-desktop;
$select-group-item-active-bg-color: $primary-ui-color;
$select-group-item-bg-color: $primary-bg-color;
$select-group-item-color: $plain-white-color;

#{$config-class-prefix} {
  &select-group {
    position: relative;

    &__items {
      display: inline-flex;
      position: relative;
      flex-wrap: wrap;
      padding-top: 1px; // compensating 1px margin for an item
      border-left: 1px solid $input-border-color; // this mimics the side item border, makes it nicer for multiline
      border-radius: $primary-border-radius;
      overflow: hidden;
      list-style-type: none;
    }

    // clickable select indicator
    &__item {
      min-width: $select-group-item-size;
      height: $select-group-item-size;
      margin-top: -1px; // hiding duplicating borders for multiline layout
      border: 1px solid $input-border-color;
      border-left: 0; // this is replaced by the container's side border
      line-height: $select-group-item-size;
      text-align: center;
      overflow: hidden;

      @include on-bp(xl) {
        min-width: $select-group-item-size-xl;
        height: $select-group-item-size-xl;
        line-height: $select-group-item-size-xl;
      }

      // ensures top and bottom radius
      &:last-child {
        border-radius: 0 $primary-border-radius $primary-border-radius 0;
      }

      // hiding the default inputs
      input {
        position: absolute;
        cursor: pointer;
        opacity: 0;
      }

      // label tag is required to have checkboxes work properly
      // so we can more or less safely use the tag selector
      label {
        display: block;
        padding: 0 get-spacing(xs);
        background: $select-group-item-bg-color;
        cursor: pointer;
      }

      // active state styles
      input:checked + label {
        background: $select-group-item-active-bg-color;
        color: $select-group-item-color;
      }
    }
  }
}
