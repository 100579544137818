// ------------------------------------
// Grid utilities
// ------------------------------------
/// @access public
// ------------------------------------

@import '../config/all';

// ------------------------------------
// Grid mixins
// ------------------------------------

// ------------------------------------
// grid-container mixin
// ------------------------------------
// Creates grid container compensating the negative margins in rows,
// useful for root elements where this might produce horizontal scrolling
// ------------------------------------
@mixin grid-container() {
  $grid-gutter: grid-gutter();
  padding-right: #{grid-gutter() / 2};
  padding-left: #{grid-gutter() / 2};
}


// ------------------------------------
// grid-row mixin
// ------------------------------------
// Creates grid row container (based on flexbox)
// ------------------------------------
@mixin grid-row($inline: false) {
  @if ($inline) {
    display: inline-flex;
  } @else {
    display: flex;
  }
  flex-wrap: wrap;
  $grid-gutter: grid-gutter();
  // adds negative margin to align content
  width: calc(100% + #{$grid-gutter});
  margin-right: #{- $grid-gutter / 2};
  margin-left: #{- $grid-gutter / 2};
}


// ------------------------------------
// grid-col mixin
// ------------------------------------
// Creates grid column
//
/// @param {number} $size - column size in columns from 1 to 12
// ------------------------------------

@mixin grid-col($size) {
  width: grid-span($size);
  $grid-gutter: grid-gutter();
  padding-right: #{grid-gutter() / 2};
  padding-left: #{grid-gutter() / 2};
}

@mixin grid-push($size) {
  margin-left: grid-push($size);
}

// ------------------------------------
// Grid helpers
// ------------------------------------

// ------------------------------------
// grid-gutter function
// ------------------------------------
// Grid gutter getter
// ------------------------------------

@function grid-gutter() {
  @return map-get($grid, 'gutter');
}


// ------------------------------------
// grid-columns function
// ------------------------------------
// Grid columns getter
// ------------------------------------

@function grid-columns() {
  @return map-get($grid, 'columns');
}

// ------------------------------------
// grid-span function
// ------------------------------------
// Grid calc helper, calculates column span width
//
/// @param $size {Integer} - number of columns (from 1 to 12)
/// @return {String} - calc() providing the width of the column
// ------------------------------------

@function grid-span($size) {
  $grid-gutter: grid-gutter();
  $grid-columns: grid-columns();
  // exact percentage of width - 1 griid gutter (note: negative margin on wrapper is requried)
  @return #{100% / ( $grid-columns / $size)};
}


// ------------------------------------
// grid-push function
// ------------------------------------
// Grid calc helper, calculates column push width
//
/// @return {String} - calc() providing the width of the push gap
// ------------------------------------

@function grid-push($size) {
  $grid-gutter: grid-gutter();
  $grid-columns: grid-columns();
  @return #{100% / (  $grid-columns / $size) };
}
