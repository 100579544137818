// ------------------------------------
// PAGE LAYOUTS
// ------------------------------------

@import '../themes/theme-default';
@import '../config/all';
@import '../utils/all';

#{$config-class-prefix} {
  &main-wrapper {
    position: relative;
    min-height: 100%;
    margin-top: 0;
    padding-top: get-spacing(md);
    padding-bottom: 0;
    background: $primary-bg-color;

    @include on-bp(lg) {
      padding-top: get-spacing(md);
    }
  }

  // Page grid
  // ----------------------------------------------

  &site-body {
    min-width: 320px;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 get-spacing(xs); // as of the styleguide recommendation we need to have a 12px gutters on the sides
  }

  &page {
    @include grid-row();

    &__content {
      @include grid-col(12);

      @include on-bp(md) {
        @include grid-col(10);
      }
    }

    &__sidebar {
      @include grid-col(12);

      @include on-bp(md) {
        @include grid-col(2);
      }
    }
  }
}
