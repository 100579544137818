// ------------------------------------
// Form elements
// ------------------------------------
// .[prefix-]control
// ------------------------------------

@import '../themes/theme-default';
@import '../config/all';
@import '../utils/all';

#{$config-class-prefix} {

  // Form control row
  // ----------------------------------------------

  &control {
    display: block;
    position: relative;
    width: 100%;
    margin-bottom: get-spacing(sm);

    &--is-last {
      margin-bottom: 0;
    }

    &--small {
      width: 50%;
    }
  }
}
