
// Sliding sidebar styles (.[prefix-]panel)
// ----------------------------------------------

@import '../themes/theme-default';
@import '../config/all';
@import '../utils/all';

// ------------------------------------
// style variables
// ------------------------------------
$panel-width: 100%;
$panel-width-desktop: 380px;
$panel-bg-color: $box-background-color;
$panel-box-shadow: $primary-box-shadow;
$panel-header-bg-color: $primary-ui-color;
$panel-header-height: 60px;
$panel-header-title-color: $plain-white-color;
$panel-header-icon-size: 15px;
$panel-footer-height: $panel-header-height;
$panel-animation-duration: 250ms;
$panel-animation-function: ease;


#{$config-class-prefix} {
  &panel {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateY(100%);// hiding element from view, on mobile
    // total transition equals $panel-animation-duration
    transition: transform $panel-animation-duration / 4 $panel-animation-duration / 4, opacity $panel-animation-duration / 2;
    opacity: 0;
    z-index: z('side-menu');

    &--active {
      transform: translateY(0);
      // total transition equals $panel-animation-duration, swapping properties to hide sliding overlay animation
      transition: transform $panel-animation-duration / 2, opacity $panel-animation-duration / 4 $panel-animation-duration / 4;
      opacity: 1;
    }

    &__body {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      transform: translate(0, 100%); // element slides from the bottom on mobile
      transition: transform $panel-animation-duration $panel-animation-function;
      background: $panel-bg-color;
      box-shadow: $panel-box-shadow;
      overflow: hidden;
      z-index: z('side-menu');

      @include on-bp(sm) {
        width: $panel-width-desktop;
        transform: translate(100%, 0); // element slides from the side on tablet
      }

      #{$config-class-prefix}panel--active & {
        transform: translate(0, 0); // show the panel
        transition: transform $panel-animation-duration $panel-animation-duration  $panel-animation-function; // wait for wrapper animation to end
      }
    }

    &__header {
      display: flex;
      position: absolute;
      top: 0;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      height: $panel-header-height;
      // there can be icons from both sides
      padding-right: get-spacing(sm);
      padding-left: get-spacing(sm);
      background: $panel-header-bg-color;
    }

    &__title {
      padding: 0 get-spacing(xs); // ensures having some space to the icons
      color: $panel-header-title-color;
      font-size: 18px;
      font-weight: $font-weight-semibold;
      line-height: 1.1; // allows to display more then one line of text
      text-align: center;
    }

    &__back,
    &__close {
      margin: 0 -2px; // optically align icons
      padding: 0;
      border: 0;
      background: none;
      color: $plain-white-color;
      font-size: $font-size-primary;
      line-height: $panel-header-height;
      cursor: pointer;
      overflow: visible; // IE has hidden by default which cuts icons, @todo align icon/button sizes properly
    }

    &__close {
      @include flix-svg-icon-small;
      @include flix-svg-icon-close($plain-white-color);
      // making this same sieze as font icons
      // @todo verify proper icon size and remove this in 4.0
      background-size: 20px;

      // @todo deprecating flix icon usage, icon needs to be removed from HTML in 4.0
      #{$config-class-prefix}icon {
        display: none;
      }
    }

    &__content {
      max-height: 100%;
      padding: $panel-header-height + get-spacing(xs) get-spacing(xs);
      background: $panel-bg-color;
      overflow-y: auto;

      @include on-bp(sm) {
        padding: $panel-header-height + get-spacing(sm) get-spacing(sm);
      }
    }

    &__footer {
      display: flex;
      position: absolute;
      bottom: 0;
      align-items: center;
      justify-content: center;
      // footer is mostly there for buttons and they should be centered
      width: 100%;
      height: $panel-footer-height;
      padding: 0 get-spacing(xs);
      background: $panel-bg-color;
      text-align: center;
      box-shadow: $panel-box-shadow;

      @include on-bp(sm) {
        padding: 0 get-spacing(sm);
      }
    }

    &__footer-section {
      margin: 0 get-spacing(xs) / 2;
      @include on-bp(xs) {
        margin: 0 get-spacing(xs) / 2;
      }
    }


    &__overlay {
      // fixing IE 11 issues with not supporting new stacking context for fixed elements via transforms
      position: absolute;
    }
  }
}
