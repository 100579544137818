// ----------------------------------------------
// Connection (.[prefix-]connection)
// ----------------------------------------------

@import '../themes/theme-default';
@import '../config/all';
@import '../utils/all';

// ------------------------------------
// style variables
// ------------------------------------
$connection-time-column-width: 38px;
$connection-start-end-station-marker-width: 9px;
$connection-station-marker-width: 5px;
$connection-station-marker-outer-width: 7px;
$connection-pseudo-element-border-width: 1px;
$connection-line-padding: get-spacing(sm);

#{$config-class-prefix} {

  &connection {
    width: 100%;
    margin-bottom: get-spacing(sm);
    border-collapse: collapse;

    // Styles

    &__station,
    &__station-info,
    &__station-toggler {
      position: relative;
      margin-left: $connection-line-padding + ceil($connection-station-marker-outer-width / 2);

      // continuous line (a.k.a. route) of thiis connection
      // Adds vertical line before each station
      &::after {
        display: block;
        position: absolute;
        top: - $line-height-primary / 2;
        bottom:  - $line-height-primary / 2 ;
        left: - $connection-line-padding;
        width: 1px;
        background: $primary-icon-color;
        content: '';
        z-index: 1;
      }

    }

    &__station-info::after {
      top: 0%;
    }

    &__station-info {
      padding: 2px 4px;
      background: $grayscale-10-color;
    }

    &__station-toggler a {
      font-size: $font-size-primary;
    }

    // hides line in connection element when last child
    tr:last-child &__station-info::after  {
      display: none;
    }

    // decoration for station (not first-last)
    // small lines just before the station class
    &__station::before {
      position: absolute;
      top: $line-height-primary / 2;
      left: - $connection-line-padding;
      width: $connection-station-marker-outer-width;
      height: 1px;
      background: $primary-icon-color;
      content: '';
      vertical-align: middle;
      z-index: 2;
    }

    &__title {
      margin-bottom: get-spacing(xs) / 2;
      font-weight: $font-weight-bold;
      text-align: left;

      &-arrival {
        font-weight: $font-weight-normal;
      }
    }

    &__day {
      font-size: 10px;
      font-weight: $font-weight-normal;
      line-height: 1;
      vertical-align: text-top;
    }

    &__time &__day {
      position: absolute;
    }

    &__time  {
      padding-right: $connection-line-padding - $connection-station-marker-outer-width;

      &--warning {
        color: $warning-color;
      }

      &--old {
        color: $grayscale-70-color;
        text-decoration: line-through;
      }

      &--danger {
        color: $fail-color;
      }
    }

    &__ellipsis {
      width: 0;
      min-width: 100%;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    // decorators for first and last station
    // small circles just before the station class
    & tr:first-child &__station::before,
    & tr:last-child &__station::before {
      display: inline-block;
      top: 12px;
      left: - $connection-line-padding - ceil($connection-station-marker-outer-width / 2);
      width: $connection-start-end-station-marker-width;
      height: $connection-start-end-station-marker-width;
      margin-top: - ceil($connection-start-end-station-marker-width / 2);
      border: 1px solid $primary-icon-color;
      border-radius: 50%;
      background: $primary-icon-color;
      content: '';
    }

    & tr:first-child &__station::before {
      background: $plain-white-color;
    }

    & tr:first-child &__station::after {
      top: 12px;
    }


    & tr:last-child &__station::after {
      bottom: calc(100% - 12px);
    }

    // Layout
    & tr td:last-child {
      width: 100%;
    }

    td {
      min-width: 1px;
      vertical-align: top;
    }


    // live

    &--live {

      #{$config-class-prefix}connection__time {
        padding: 0 0 0 get-spacing(xs);
        text-align: right;

        #{$config-class-prefix}connection__day {
          position: relative;
        }
      }

      #{$config-class-prefix}connection__station {
        &::after {
          top: - $line-height-primary * 0.8;
          bottom: - $line-height-primary * 0.8;
        }
      }

      tr:last-child,
      tr:first-child {
        #{$config-class-prefix}connection__station {
          font-weight: $font-weight-bold;
        }

        td:last-child {
          font-weight: $font-weight-bold;
        }
      }

      tr td:first-child {
        padding: 0;
      }

      tr td:first-child,
      tr td:only-child {
        width: 100%;
      }

      td {
        padding: get-spacing(xs) / 2 0;
        vertical-align: middle;
      }

      #{$config-class-prefix}connection__station::before {
        top: 50%;
      }

    }

    &--hide-stops {

      tr {
        display: none;
      }

      tr:first-child,
      tr:last-child {
        display: table-row;
      }
    }

  }
}
