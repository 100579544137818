// ------------------------------------
// animations utilities
// ------------------------------------
/// @access private
// ------------------------------------

// ------------------------------------
// anim-rotate
// ------------------------------------
// mixin to generate a rotating animation
//
/// @param {Degree} $from - valid css degree value
/// @param {Degree} $to - valid css degree value
/// @param {String} $anim-name - the name of the resulting animation
// ------------------------------------

@mixin anim-rotate(
  $from: 0deg,
  $to: 360deg,
  $anim-name: 'rotate'
) {
  @keyframes #{$anim-name} {

    0% {
      transform: rotate($from);
    }

    100% {
      transform: rotate($to);
    }

  }
}

// ------------------------------------
// anim-grow-fade
// ------------------------------------
// mixin to generate a grow and fade animation
//
/// @param {Size} $from-width - starting width
/// @param {Size} $from-height - starting height
/// @param {Float|Integer} $from-opacity - starting opacity
/// @param {Size} $to-width - ending width
/// @param {Size} $to-height - ending height
/// @param {Float|Integer} $to-opacity - ending opacity
/// @param {String} $anim-name - the name of the resulting animation
// ------------------------------------

@mixin anim-grow-fade(
  $from-width: 0,
  $from-height: 0,
  $from-opacity: 0,
  $to-width: 10px,
  $to-height: 10px,
  $to-opacity: 1,
  $anim-name: 'grow-fade'
) {
  @keyframes #{$anim-name} {
    0% {
      width: $from-width;
      height: $from-height;
      opacity: $from-opacity;
    }

    100% {
      width: $to-width;
      height: $to-height;
      opacity: $to-opacity;
    }
  }
}

// ------------------------------------
// anim-checkmark-grow
// ------------------------------------
// mixin to generate a grow animation for checkboxes and radio elements
//
/// @param {Float|Integer} $initial-scale - initial scale from where element start growing
/// @param {String} $anim-name - the name of the resulting animation
// ------------------------------------

@mixin anim-checkmark-grow (
  $initial-scale: .25,
  $anim-name: 'checkmark-grow'
) {
  @keyframes #{$anim-name} {
    0% {
      transform: scale($initial-scale);
    }

    50% {
      transform: scale(1);
    }
  }
}
