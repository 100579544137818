// ------------------------------------
// Fieldset
// ------------------------------------
// .[prefix-]fieldset
// ------------------------------------

@import '../themes/theme-default';
@import '../config/all';
@import '../utils/all';

#{$config-class-prefix} {

  &fieldset {
    margin: 0 0 get-spacing(sm);
    padding: 0;
    border: 0;

    @include on-bp(xl) {
      margin: 0 0 get-spacing(sm);
    }

    #{$config-class-prefix}fieldset__item {
      margin-top: get-spacing(xs);
      margin-bottom: 0;
    }

    &__info {
      @include show-as-fineprint();
      display: block;
      padding-top: 3px;
    }

    &--error &__info {
      color: $input-error-info-color;
    }
  }
}
