// ----------------------------------------------
// Image link (.[prefix-]img-link)
// ----------------------------------------------

@import '../themes/theme-default';
@import '../config/all';
@import '../utils/all';
@import '../icons/base-mixins';
@import '../icons/icon-svg-mixins';
// ------------------------------------
// style variables
// ------------------------------------
$img-link-bg-color: $primary-bg-color;
$img-link-border-radius: $primary-border-radius;
$img-link-border-color: $primary-line-color;
$img-link-icon-color: $primary-icon-color;
$img-link-box-shadow: $primary-box-shadow;
$img-link-image-height: 200px;
$img-link-image-height-bp-xl: 160px;
$img-link-sm-image-height: 90px;

#{$config-class-prefix} {
  &img-link {
    display: block;
    margin-bottom: get-spacing(xs);
    border: 1px solid $img-link-border-color;
    border-radius: $img-link-border-radius;
    text-decoration: none;
    overflow: hidden;

    &:hover {
      box-shadow: $img-link-box-shadow;
    }

    &__img-wrapper {
      position: relative;
      min-height: $img-link-image-height;

      @include on-bp(xl) {
        min-height: $img-link-image-height-bp-xl;
      }
    }

    &__img {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      transform: translate(-50%, -50%);
    }

    // adds proper image resizing for those browsers that support object-fit
    @supports (object-fit: cover) {
      &__img {
        height: 100%;
        object-fit: cover;
      }
    }

    &__content {
      position: relative;
      padding: get-spacing(xs) get-spacing(md) get-spacing(xs) get-spacing(sm);
      background: $plain-white-color;

      // arrow in content area
      &::after {
        @include flix-svg-icon-small;
        @include flix-svg-icon-arrow-right($img-link-icon-color);
        position: absolute;
        top: 50%;
        right: get-spacing(xs);
        margin-top: -11px;
      }
    }

    // smaller version with a square image on the side
    &--sm {
      position: relative;
      min-height: $img-link-sm-image-height + 2; // compensating borders

      #{$config-class-prefix}img-link__img-wrapper {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: $img-link-sm-image-height;
        min-height: $img-link-sm-image-height;
      }

      #{$config-class-prefix}img-link__content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: calc(100% - #{$img-link-sm-image-height});
        min-height:  $img-link-sm-image-height;
        margin-left: $img-link-sm-image-height;
      }
    }
  }
}
