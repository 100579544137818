// ----------------------------------------------
// General list element
// ----------------------------------------------
// .[prefix-]list-general
// ------------------------------------

@import '../themes/theme-default';
@import '../config/all';
@import '../utils/all';


#{$config-class-prefix} {

  // Outlined list with borders
  // ----------------------------------------------

  &list-simple {
    margin: 0;
    padding-left: 0;
    list-style-type: none;

    &__item {
      min-height: 40px;
      padding: 8px 0;
      border-bottom: 1px solid $primary-line-color;
      color: $primary-content-color;
      font-size: $font-size-primary;

      &:last-child {
        border-bottom: 0;
      }
    }
  }
}
