
// ----------------------------------------------
// accordion (.[prefix-]accordion)
// ----------------------------------------------

@import '../themes/theme-default';
@import '../config/all';
@import '../utils/all';
// base icon mixins to get arrow icons
@import '../icons/base-mixins';
@import '../icons/icon-svg-mixins';


#{$config-class-prefix} {
  &accordion {
    display: block;
    border-top: 1px solid $primary-line-color;
    border-bottom: 1px solid $primary-line-color;

    &__title {
      // sass-lint:disable no-vendor-prefixes
      @include show-as-h4();
      display: block;
      position: relative;
      padding: get-spacing(xs);
      outline: 0;
      cursor: pointer;

      // removing webkit's default arrow marker
      &::-webkit-details-marker {
        display: none;
      }

      &::after {
        @include flix-svg-icon-small;
        @include flix-svg-icon-arrow-down;
        position: absolute;
        top: 50%;
        right: get-spacing(xs);
        margin-top: -11px;
      }

      // adds focus indication for toggle icon for keyboard navigation
      &:focus {
        @include input-outline();
      }
    }

    // "open" attribute is used in native <details>/<summary> HTML implementation
    // adding an "--open" modifier class as well for customizations and 3rd party scripts
    &[open],
    &--open {
      > #{$config-class-prefix}accordion__title::after {
        transform: rotate(180deg); // optimizing the file size achieving the same icon with transform
      }

      > #{$config-class-prefix}accordion__content {
        display: block;
      }
    }

    &__content {
      display: none;
      padding: 0 get-spacing(xs) get-spacing(xs);
    }

    & + & {
      border-top: 0;
    }
  }
}
