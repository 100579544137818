// ------------------------------------
// Flixfont icons
// ------------------------------------

@import '../themes/theme-default';
@import '../config/all';

@import 'base-mixins';
@import 'icon-mixins';
@import 'icon-font-face';
@import 'icon-font-classes';

// sass-lint:disable no-vendor-prefixes

[class^='flix-icon-']:before,
[class*=' flix-icon-']:before {
  display: inline-block;
  width: 1em;
  margin-right: .2em;
  margin-left: .2em;
  font-family: 'flixicons';
  font-style: normal;
  font-variant: normal;
  font-weight: normal;

  line-height: 1em;
  text-align: center;
  text-decoration: inherit;

  text-transform: none;

  speak: none;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

#{$config-class-prefix} {

  &icon {
    @include flix-icon;

    &--sm {
      @include flix-icon-small;
    }

    &--lg {
      @include flix-icon-large;
    }
  }
}
