// ----------------------------------------------
// Side navigation (.[prefix-]nav-side)
// ----------------------------------------------

@import '../themes/theme-default';
@import '../config/all';
@import '../utils/all';
@import '../icons/base-mixins';
@import '../icons/icon-svg-mixins';

#{$config-class-prefix} {
  &nav-side {
    position: relative;
    margin: 0;
    padding: 0;

    &--centered {
      text-align: center;
    }


    &__item {
      display: block;
      position: relative;
    }

    &__link {
      display: inline-block;
      position: relative;
      width: 100%;
      padding: 5px 0;
      color: $primary-content-color;
      font-size: $font-size-primary;
      font-weight: $font-weight-semibold;
      line-height: $line-height-primary;
      text-decoration: none;
      cursor: pointer;

      &:hover,
      &:active,
      &:focus {
        color: $strong-highlight-color;
        text-decoration: none;
      }
    }

    &__link--active {
      color: $strong-highlight-color;
    }

    &__link--has-subnav {
      &:after {
        @include flix-svg-icon-small;
        @include flix-svg-icon-arrow-down($primary-content-color);
      }

      &:hover,
      &:active,
      &:focus,
      &#{$config-class-prefix}nav-side__link--active {
        &::after {
          @include flix-svg-icon-arrow-down($strong-highlight-color);
        }
      }
    }

    &__link--has-subnav-open {
      &:after {
        transform: rotate(180deg);
      }

      &:hover,
      &:active,
      &:focus {
        &::after {
          transform: rotate(180deg);
        }
      }
    }



    // Subnavigation
    // ----------------------------------------------

    &-subnav {
      @extend #{$config-class-prefix}nav-side;
      padding-left: get-spacing(xs);

      &__item {
        @extend #{$config-class-prefix}nav-side__item;
      }

      &__link {
        @extend #{$config-class-prefix}nav-side__link;
      }

      &__link--active {
        color: $strong-highlight-color;
      }
    }
  }
}
