// ----------------------------------------------
// Range input (.[prefix-]range & [type='range'])
// ----------------------------------------------

@import '../themes/theme-default';
@import '../config/all';
@import '../utils/all';

// ------------------------------------
// style variables
// ------------------------------------
$input-range-track-color: $strong-highlight-color !default;
$input-range-thumb-color: $primary-bg-color !default;

$input-range-thumb-radius: 100% !default;
$input-range-thumb-height: 30px !default;
$input-range-thumb-width: 30px !default;
$input-range-thumb-border-width: 2px !default;
$input-range-thumb-border-color: $primary-line-color !default;
$input-range-thumb-bg-color: $plain-white-color !default;

$input-range-track-width: 100% !default;
$input-range-track-height: 2px !default;
$input-range-track-border-width: 0px !default;
$input-range-track-border-color: $strong-highlight-color !default;

$input-range-track-radius: 5px !default;
$input-range-contrast: 5% !default;

// we do not use trakcs
@mixin track {
  border: 0;
  background: transparent;
}

@mixin thumb {
  width: $input-range-thumb-width;
  height: $input-range-thumb-height;
  border: $input-range-thumb-border-width solid $input-range-thumb-border-color;
  border-radius: $input-range-thumb-radius;
  background: $input-range-thumb-bg-color;
  cursor: pointer;
}

#{$config-class-prefix} {

  &range {
    // sass-lint:disable no-vendor-prefixes
    position: relative;
    font-size: 0;

    &__field {
      position: relative;
      height: $input-range-thumb-height;
      overflow: visible;

      &::before {
        display: block;
        position: absolute;
        top: 50%;
        width: $input-range-track-width;
        height: $input-range-track-height;
        margin-top: - $input-range-track-height / 2;
        border-radius: $input-range-track-radius;
        background: $input-range-track-color;
        content: '';
      }
    }

    [type='range'] {
      position: absolute;
      bottom: 0;
      left: 0;
      width: $input-range-track-width;
      height: $input-range-thumb-height;
      margin: 0;
      padding: 0;
      outline: none;
      background: transparent;
      overflow: visible;
      z-index: 100;
      appearance: none;

      &::-webkit-slider-runnable-track {
        @include track;
        border: $input-range-track-border-width solid $input-range-track-border-color;
      }

      &::-webkit-slider-thumb {
        @include thumb;
        position: relative;
        outline: 0;
        z-index: 1;
        -webkit-appearance: none;
        pointer-events: all;
      }

      &::-moz-range-track {
        @include track;
      }

      &::-moz-range-thumb {
        @include thumb;
        pointer-events: all;
      }

      &::-moz-focus-outer {
        border: 0;
      }

      &::-ms-track {
        @include track;
        color: transparent;
      }

      &::-ms-fill-lower {
        border: 0;
        background: transparent;
      }

      &::-ms-fill-upper {
        border: 0;
        background: transparent;
      }

      &::-ms-thumb {
        @include thumb;
        margin-top: 0;
      }
    }

    &__value {
      display: inline-block;
      width: 50%;
      margin-bottom: 4px;
      font-size: $font-size-small;
      word-break: break-all;

      &--max {
        text-align: right;
      }
    }

    &__track-range {
      position: absolute;
      bottom: $input-range-thumb-height / 2 - 1;
      height: $input-range-track-height;
      margin-top: - $input-range-track-height / 2;
      background: $input-range-track-color;
      z-index: 0;
    }


    &__label {
      display: block;
      margin-bottom: get-spacing(xs) / 2;
      font-size: $font-size-primary;
      word-break: break-all;
    }

    &__label--hidden {
      display: none;
    }

    [type='range']:focus {
      outline: 0;
    }

    &--disabled &__field::before {
      background: $primary-line-color;
      opacity: .5;
    }

    &--disabled &__track-range {
      background: $primary-line-color;
      opacity: 0;
    }

    &--disabled input,
    input:disabled {

      &::-moz-range-thumb {
        cursor: not-allowed;
      }

      &::-ms-thumb {
        cursor: not-allowed;
      }

      &::-webkit-slider-thumb {
        cursor: not-allowed;
      }
    }

    &--double {
      pointer-events: none;
    }

    &--double &__field::before {
      background: $primary-line-color;
    }

  }
}
