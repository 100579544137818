// ----------------------------------------------
// Bullet list element
// ----------------------------------------------
// .[prefix-]list
// ------------------------------------

@import '../themes/theme-default';
@import '../config/all';
@import '../utils/all';

@include exports('list') {
  #{$config-class-prefix} {
    // Bullet list
    // ----------------------------------------------

    // using placeholder to make flix-text includes pretty
    @at-root %list {
      margin: 0 0 get-spacing(xs);
      padding-left: get-spacing(xs);
      list-style-type: none;
    }

    // nested lists
    // using placeholder to make flix-text includes pretty
    @at-root %list-nested {
      margin: 0; // removes bottom margins for nested lists
      padding-left: 0;
    }

    &list {
      @extend %list;
    }

    &list &list {
      @extend %list-nested;
    }

    &list__item {
      position: relative;
      padding-left: get-spacing(xs) + 4;
      line-height: $line-height-primary;

      &::before {
        display: block;
        position: absolute;
        top: $line-height-primary / 2; // ensure it's positioned in in the middle of the text vertically
        left: 0;
        width: 4px;
        height: 4px;
        margin-top: -2px; // half of the size
        margin-right: get-spacing(xs);
        border-radius: 50%;
        background: $primary-content-color;
        content: '';
      }
    }
  }
}
