// ----------------------------------------------
// Info box (.[prefix-]infobox)
// ----------------------------------------------

@import '../themes/theme-default';
@import '../config/all';
@import '../utils/all';
@import '../icons/base-mixins';
@import '../icons/icon-svg-mixins';

// ------------------------------------
// style variables
// ------------------------------------
$infobox-background-color: $box-background-color;
$infobox-icon-size: 50px;
$infobox-icon-default-color: $link-color;

#{$config-class-prefix} {
  &infobox {
    display: flex;
    align-items: center;
    margin-bottom: get-spacing(xs);
    padding: get-spacing(xs);
    border-radius: $primary-border-radius;
    background-color: $infobox-background-color;
    font-family: $font-family-primary;
    font-size: $font-size-primary;
    font-weight: $font-weight-normal;
    box-shadow: $primary-box-shadow;

    @include on-bp(sm) {
      padding: get-spacing(sm);
    }

    &::before {
      @include flix-svg-icon-large;
      @include flix-svg-icon-info($infobox-icon-default-color);
      align-self: flex-start;
      min-width: get-spacing(lg);
      margin-right: 15px;
      color: $infobox-icon-default-color;
    }

    &--success {
      &::before {
        @include flix-svg-icon-large;
        @include flix-svg-icon-checkmark-strong($success-color);
      }
    }

    &--warning {
      &::before {
        @include flix-svg-icon-large;
        @include flix-svg-icon-attention($warning-color);
      }
    }

    &--danger {
      &::before {
        @include flix-svg-icon-large;
        @include flix-svg-icon-attention($fail-color);
      }
    }
  }
}
