// ----------------------------------------------
// Numbered list element
// ----------------------------------------------
// .[prefix-]list-numbered
// ------------------------------------

@import '../themes/theme-default';
@import '../config/all';
@import '../utils/all';

@include exports('list-numbered') {
  #{$config-class-prefix} {

    // Numbered list
    // ----------------------------------------------

    // placeholders
    // ----------------------------------------------
    // using placeholder to make flix-text includes pretty
    @at-root %numbered-list {
      margin: 0 0 get-spacing(xs);
      padding-left: get-spacing(xs);
      list-style-type: none;
      counter-reset: numbered-list-counter;
    }

    @at-root %numbered-list-item {
      display: table; // achieving a correct behaviour for multi-line list items, they need to be aligned in one line
      position: relative;

      &::before {
        display: table-cell;
        padding-right: 6px; // to achieve the same horizontal spacing as with bullet lists, so they look consistent
        font-weight: $font-weight-bold;
        // number should be followed with a dot "."
        content: counters(numbered-list-counter, '.') '.';
        counter-increment: numbered-list-counter;
      }
    }

    // nested lists
    // using placeholder to make flix-text includes pretty
    @at-root %numbered-list-nested {
      width: 100%; // make sure nested list gets a new line
      margin: 0; // removes bottom margins for nested lists
      padding-left: 0;
    }

    // nested list items counter
    // using placeholder to make flix-text includes pretty
    @at-root %numbered-list-nested-item {
      &::before {
        font-weight: $font-weight-normal;
        content: counters(numbered-list-counter, '.'); // no need for an ending dot for sub items
      }
    }

    // Classes
    // ----------------------------------------------

    &list-numbered {
      @extend %numbered-list;
    }

    &list-numbered__item {
      @extend %numbered-list-item;
    }

    // nested lists
    &list-numbered &list-numbered {
      @extend %numbered-list-nested;
    }

    // nested list items
    &list-numbered &list-numbered &list-numbered__item {
      @extend %numbered-list-nested-item;
    }
  }
}
