// ------------------------------------
// Footer Navigation
// ------------------------------------
// .[prefix-]footer-nav
// ------------------------------------

@import '../themes/theme-default';
@import '../config/all';
@import '../utils/all';

#{$config-class-prefix} {
  &footer-nav {
    position: relative;
    border-bottom: 1px solid $primary-line-color;

    @include on-bp(sm) {
      border-bottom: 0;
    }

    &--first {
      border-top: 1px solid $primary-line-color;

      @include on-bp(sm) {
        border-top: 0;
      }
    }

    &__items {
      display: none;
      padding-right: $spacing-xs;
      padding-left: $spacing-xs;
      list-style: none;

      @include on-bp(sm) {
        display: block;
        padding-right: 0;
        padding-left: 0;
      }

      &--open {
        display: block;
      }
    }

    &__item {
      padding-bottom: $spacing-xs;
    }

    &__link {
      @include show-as-text();
      font-weight: $font-weight-semibold;
      text-decoration: none;

      &:hover {
        color: $secondary-brand-color;
      }
    }

    &__title {
      @include show-as-h4();
      position: relative;
      padding: $spacing-xs;

      &::after {
        @include flix-svg-icon-small;
        @include flix-svg-icon-arrow-down;
        position: absolute;
        top: 50%;
        right: get-spacing(xs);
        margin-top: -11px;
      }

      @include on-bp(sm) {
        padding-right: 0;
        padding-left: 0;

        &::after {
          display: none;
        }
      }

      &--open {
        &::after {
          @include flix-svg-icon-arrow-up;
        }
      }
    }
  }
}
