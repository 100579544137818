// ------------------------------------
// Headers (.[prefix-]h#)
// ------------------------------------

@import '../themes/theme-default';
@import '../config/all';
@import '../utils/all';

@include exports('heading') {

  #{$config-class-prefix} {
    &h1 {
      @include show-as-h1();
      margin: 0;
      padding: 0 0 get-spacing(sm);
      // h1 should always be a section header, it has no top padding by default
      // so doesn't require "--section-header" modifier
    }

    &h2 {
      @include show-as-h2();
      margin: 0;
      padding: get-spacing(xs) 0;

      &--section-header {
        @extend %section-headline;
      }
    }

    &h3 {
      @include show-as-h3();
      margin: 0;
      padding: get-spacing(xs) 0;

      &--section-header {
        @extend %section-headline;
      }
    }

    &h4 {
      @include show-as-h4();
      margin: 0;
      padding: get-spacing(xs) 0;

      &--section-header {
        @extend %section-headline;
      }
    }
  }

  // headline placeholder needs to be here to overwrite the normal headline styles
  // related to the way SASS compiles placeholder styles when @extend is used
  %section-headline {
    padding-top: 0;
  }
}
