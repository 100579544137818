// ----------------------------------------------
// Button arrow (.prefix-btn-arrow)
// ----------------------------------------------

@import '../themes/theme-default';
@import '../config/all';
@import '../utils/all';
@import '../icons/base-mixins';
@import '../icons/icon-svg-mixins';

// ------------------------------------
// style variables
// ------------------------------------
$button-arrow-text-color: $primary-content-color;
$button-arrow-strong-text-color: $strong-highlight-color;
$button-arrow-font-size: 18px;
$button-arrow-font-weight: $font-weight-semibold;
$button-arrow-line-height: 24px;

#{$config-class-prefix} {

  &btn-arrow {
    display: inline-block;
    position: relative;
    min-height: 0;
    border: 0;
    background: transparent;
    color: $button-arrow-text-color;
    font-family: $font-family-primary;
    font-size: $button-arrow-font-size;
    font-weight: $button-arrow-font-weight;
    line-height: $button-arrow-line-height;
    text-decoration: none;
    cursor: pointer;

    &:hover,
    &:focus,
    &:active {
      text-decoration: none;
    }

    &::after {
      @include flix-svg-icon-small;
      @include flix-svg-icon-arrow-right;
    }

    &--back {
      &::after {
        display: none;
      }

      &::before {
        @include flix-svg-icon-small;
        @include flix-svg-icon-arrow-left;
      }
    }

    &--top {
      &::after {
        transform: rotate(-90deg); // optimizing the file size achieving the same icon with transform
      }
    }

    &--bottom {
      &::after {
        transform: rotate(90deg); // optimizing the file size achieving the same icon with transform
      }
    }

    &--strong {
      color: $button-arrow-strong-text-color;

      &::after {
        @include flix-svg-icon-arrow-right($button-arrow-strong-text-color);
      }

      &#{$config-class-prefix}btn-arrow--back {
        &::before {
          @include flix-svg-icon-arrow-left($button-arrow-strong-text-color);
        }
      }
    }
  }
}
