// ------------------------------------
// Typography mixins
//
// can be used to inherit certain typography styles in non-typogrphy elements
// e.g. style legend as h4 etc.
// ------------------------------------
/// @access private
// ------------------------------------

@import '../themes/theme-default';
@import '../config/all';

// ------------------------------------
// font-primary-normal
// ------------------------------------
// Sets font family, size, style, weight
// to be the one defined in the styleguide
// ------------------------------------

@mixin font-primary-normal {
  font-family: $font-family-primary;
  font-size: $font-size-primary;
  font-weight: $font-weight-normal;
}


// ------------------------------------
// show-as-text
// ------------------------------------
// defines basic text typography styles
// ------------------------------------

@mixin show-as-text () {
  color: $primary-content-color;
  font-size: $font-size-primary;
  font-weight: $font-weight-normal;
  line-height: $line-height-primary;
}

// ------------------------------------
// show-as-fineprint
// ------------------------------------
// defines basic fineprint text typography styles
// ------------------------------------

@mixin show-as-fineprint () {
  color: $primary-content-color;
  font-size: $font-size-small;
  font-weight: $font-weight-normal;
  line-height: 17px;
}

// ------------------------------------
// show-as-h1
// ------------------------------------
// defines basic H1 headline typography styles
// ------------------------------------

@mixin show-as-h1 () {
  color: $heading-font-color;
  font-size: 28px;
  font-weight: $font-weight-bold;
  line-height: 34px;
}

// ------------------------------------
// show-as-h2
// ------------------------------------
// defines basic H2 headline typography styles
// ------------------------------------

@mixin show-as-h2 () {
  color: $heading-font-color;
  font-size: 22px;
  font-weight: $font-weight-bold;
  line-height: 28px;
}

// ------------------------------------
// show-as-h3
// ------------------------------------
// defines basic H3 headline typography styles
// ------------------------------------

@mixin show-as-h3 () {
  color: $heading-font-color;
  font-size: 18px;
  font-weight: $font-weight-bold;
  line-height: 24px;
}

// ------------------------------------
// show-as-h4
// ------------------------------------
// defines basic H4 headline typography styles
// ------------------------------------

@mixin show-as-h4 () {
  color: $heading-font-color;
  font-size: 16px;
  font-weight: $font-weight-bold;
  line-height: 22px;
}
