// ------------------------------------
// Spinner (.prefix-spinner)
// ------------------------------------

@import '../themes/theme-default';
@import '../config/all';
@import '../utils/all';

$spinner-size: 90px;
$spinner-border-width: 4px;

#{$config-class-prefix} {
  &spinner {
    display: inline-block;
    width: $spinner-size;
    height: $spinner-size;
    border: $spinner-border-width solid $primary-ui-light-color;
    border-radius: 50%;
    border-left-color: $primary-ui-color;
    animation: spinner-rotate 1s infinite linear;

    &--sm,
    &--md,
    &--lg,
    &--xl,
    &--xxl {
      border-left-color: $primary-ui-dark-color;
    }

    &--sm {
      width: $spacing-sm;
      height: $spacing-sm;
    }

    &--md {
      width: $spacing-md;
      height: $spacing-md;
    }

    &--lg {
      width: $spacing-lg;
      height: $spacing-lg;
    }

    &--xl {
      width: $spacing-lg + $spacing-sm;
      height: $spacing-lg + $spacing-sm;
    }

    &--xxl {
      width: $spacing-lg + $spacing-sm * 2;
      height: $spacing-lg + $spacing-sm * 2;
    }

  }

  
  @include anim-rotate($anim-name: 'spinner-rotate')
}
