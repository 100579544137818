// ----------------------------------------------
// Horizontal navigation (.[prefix-]nav-horizontal)
// ----------------------------------------------

@import '../themes/theme-default';
@import '../config/all';
@import '../utils/all';

#{$config-class-prefix} {
  &nav-horizontal {
    margin: 0;
    margin-bottom: get-spacing(sm);
    padding: 0;

    &--centered {
      text-align: center;
    }

    &--right {
      text-align: right;
    }

    &--left {
      text-align: left;
    }

    &__items {
      display: flex;
      align-items: flex-end;
      margin: 0;
      padding: 0;
    }

    &__item {
      position: relative;
      max-width: 50%;
      text-align: center;
      list-style-type: none;
    }

    &__item + &__item {
      &::before {
        display: inline-block;
        position: absolute;
        bottom: 13px;
        left: 0;
        height: 16px;
        border-left: 1px solid $primary-line-color;
        content: '';
      }
    }

    &__link {
      display: inline-block;
      width: 100%;
      padding: 9px get-spacing(xs);
      color: $primary-content-color;
      font-size: $font-size-primary;
      font-weight: $font-weight-semibold;
      line-height: $line-height-primary;
      text-decoration: none;
      cursor: pointer;

      &:hover,
      &:active,
      &:focus,
      &--active {
        color: $strong-highlight-color;
        text-decoration: none;

        &::before {
          display: block;
          position: absolute;
          right: 0;
          bottom: 0;
          left: 0;
          height: 0;
          border-bottom: 4px solid $strong-highlight-color;
          content: '';
        }
      }
    }
  }
}
