// ----------------------------------------------
// Notice box (.[prefix-]notification)
// ----------------------------------------------

@import '../themes/theme-default';
@import '../config/all';
@import '../utils/all';
@import '../icons/base-mixins';
@import '../icons/icon-svg-mixins';

// ------------------------------------
// style variables
// ------------------------------------
$notification-bg-color: $box-background-color;

#{$config-class-prefix} {
  &notification {
    display: flex;
    position: relative;
    align-items: center;
    width: 100%;
    margin: get-spacing(xs) 0;
    padding: get-spacing(xs);
    border-radius: $primary-border-radius;
    background: $notification-bg-color;
    font-family: $font-family-primary;
    font-size: $font-size-primary;
    font-weight: $font-weight-bold;
    box-shadow: $primary-box-shadow;

    &::before {
      @include flix-svg-icon-small;
      @include flix-svg-icon-info-solid($neutral-color);
      align-self: flex-start;
      min-width: get-spacing(sm);
      margin-right: get-spacing(xs);
    }

    &--error {
      &::before {
        @include flix-svg-icon-attention-solid($fail-color);
      }
    }

    &--warning {
      &::before {
        @include flix-svg-icon-attention-solid($warning-color);
      }
    }

    &--success {
      &::before {
        @include flix-svg-icon-checkmark-strong-solid($success-color);
      }
    }

    &__content {
      flex-grow: 1;
    }

    &__close {
      @include btn-reset();
      align-self: flex-start;
      margin-left: get-spacing(xs);
      @include flix-svg-icon-small;
      @include flix-svg-icon-close;
      // making this same sieze as font icons
      // @todo verify proper icon size and remove this in 4.0
      background-size: 20px;

      // @todo deprecating flix icon usage, icon needs to be removed from HTML in 4.0
      #{$config-class-prefix}icon {
        display: none;
      }
    }
  }
}
