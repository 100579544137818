// ----------------------------------------------
// Input (text) (.[prefix-]input)
// ----------------------------------------------

@import '../themes/theme-default';
@import '../config/all';
@import '../utils/all';

#{$config-class-prefix} {
  &input {
    position: relative;

    @include input-states();

    &__field {
      @include input-basic;

      #{$config-class-prefix}input--valid & {
        padding-right: 40px;
      }

      #{$config-class-prefix}input--error & {
        padding-right: 40px;
        border: 1px solid $input-error-border-color;
      }

      #{$config-class-prefix}input--active &,
      &:focus {
        border: 1px solid $input-active-border-color;
        outline: none;
      }

      &[disabled],
      #{$config-class-prefix}input--disabled & {
        cursor: not-allowed;
        opacity: .5;
      }

      #{$config-class-prefix}input--has-icon & {
        padding-left: 40px;
      }

      #{$config-class-prefix}input--loading & {
        padding-right: get-spacing(lg);
      }
    }

    // we need to increase the specificity in here to make sure order of CSS partials loaded doesn't influence the layout,
    // this is needed for Honeycomb-react where partials order is unpredictable
    // @todo figure out the better way of ensuring the order doesn't influence the result
    & &__icon {
      display: block;
      position: absolute;
      left: get-spacing(xs);
      margin-top: - $input-height-mobile / 2;
      transform: translateY(-50%);
      z-index: z(form-label) + 1;

      @include on-bp(xl) {
        margin-top: - $input-height-desktop / 2;
      }

      &::before {
        margin-left: 0;
      }
    }

    &__spinner {
      display: block;
      position: absolute;
      right: get-spacing(xs);
      margin-top: - $input-height-mobile/2 - $spacing-sm / 2;
      z-index: z(form-label) + 1;

      @include on-bp(xl) {
        margin-top: - $input-height-desktop/2 - $spacing-sm / 2;
      }

    }

    &__info {
      @include show-as-fineprint();
      display: block; // element needs to be a block one to have the proper line height applied
      padding-top: 3px; // we agreed on using a custom value in here because it looks better

      #{$config-class-prefix}input--error & {
        color: $input-error-info-color;
      }
    }

  }

  // overwrite display of spinner component
  &input &input__spinner {
    display: block;
  }
}
