// ----------------------------------------------
// Typography elements
// ----------------------------------------------
// .[prefix-]text
// .[prefix-]text a
// .[prefix-]text blockquote
// .[prefix-]text h1
// .[prefix-]text h2
// .[prefix-]text h3
// .[prefix-]text ul
// .[prefix-]list-general
// ------------------------------------
// @TODO: this stylesheet is more a set of scope styles under the .[prefix-]text class
// we need to find a way to split this into proper independent components

@import '../themes/theme-default';
@import '../config/all';
@import '../utils/all';
// importing typography related components
@import 'blockquote';
@import 'link';
@import 'heading';
@import 'list';
@import 'list-numbered';

#{$config-class-prefix} {

  // Text
  // ----------------------------------------------

  &text,
  &text p {
    @include show-as-text();
    margin: 0 0 get-spacing(xs);
  }

  &fineprint {
    @include show-as-fineprint();
    margin: 0 0 get-spacing(xs);
  }

  // Links
  // ----------------------------------------------

  &text a {
    @extend #{$config-class-prefix}link;
  }

  // Headers
  // ----------------------------------------------

  &text h1 {
    @extend #{$config-class-prefix}h1;
  }

  &text h2 {
    @extend #{$config-class-prefix}h2;
  }

  &text h3 {
    @extend #{$config-class-prefix}h3;
  }

  &text h4 {
    @extend #{$config-class-prefix}h4;
  }

  // Blockquote
  // ----------------------------------------------

  &text blockquote {
    @extend #{$config-class-prefix}blockquote;
  }

  // List
  // ----------------------------------------------

  &text ul {
    @extend %list;
  }

  &text ul li {
    @extend #{$config-class-prefix}list__item;
  }

  &text ul ul {
    @extend %list-nested;
  }

  // List numbered
  // ----------------------------------------------

  &text ol {
    @extend %numbered-list;
  }

  &text ol > li {
    @extend %numbered-list-item;
  }

  &text ol ol {
    @extend %numbered-list-nested;
  }

  &text ol ol > li {
    @extend %numbered-list-nested-item;
  }
}
