// ----------------------------------------------
// Textarea (.[prefix-]textarea)
// ----------------------------------------------

@import '../themes/theme-default';
@import '../config/all';
@import '../utils/all';

// ------------------------------------
// style variables
// ------------------------------------
// roughly mades the textarea height the size of 2 text input elements
// 30px (sm spacing) is the control bottom margin we need to include as well
$textarea-height-mobile: $input-height-mobile * 2 + get-spacing(sm);
// 24px is the label height, spacing(xs) is the control bottom margin we need to include as well
$textarea-height-desktop: $input-height-desktop * 2 + get-spacing(sm) + 24px;
$textarea-vertical-padding-mobile: 10px;
$textarea-vertical-padding: 5px;
$textarea-horizontal-padding: get-spacing(xs);


#{$config-class-prefix} {
  &textarea {
    position: relative;

    @include input-states();

    &__field {
      @include font-primary-normal;
      position: relative;
      width: 100%;
      min-height: $textarea-height-mobile;
      padding: $textarea-vertical-padding-mobile $textarea-horizontal-padding;
      border: 1px solid $input-border-color;
      border-radius: $input-border-radius;
      background-color: $input-bg-color;
      color: $primary-content-color;
      line-height: $line-height-primary;
      // Remove the default vertical scrollbar in IE 10+
      overflow: auto;
      //removes spacing underneath the textarea, the inline-block issue
      vertical-align: top;
      appearance: none;

      @include on-bp(xl) {
        min-height: $textarea-height-desktop;
        padding: $textarea-vertical-padding $textarea-horizontal-padding;
      }

      &::placeholder {
        color: $secondary-content-color;
        font-style: italic;
      }

      #{$config-class-prefix}textarea--valid & {
        padding-right: 40px;
      }

      #{$config-class-prefix}textarea--error & {
        padding-right: 40px;
        border: 1px solid $input-error-border-color;
      }

      &:focus,
      #{$config-class-prefix}textarea--active & {
        border: 1px solid $input-active-border-color;
        outline: none;
        color: $primary-content-color;
      }

      &[disabled],
      #{$config-class-prefix}textarea--disabled & {
        cursor: not-allowed;
        opacity: .5;
      }
    }
  }
}
