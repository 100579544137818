// ----------------------------------------------
// Switch (checkbox) (.[prefix-]switch)
// ----------------------------------------------

@import '../themes/theme-default';
@import '../config/all';
@import '../utils/all';

// ------------------------------------
// style variables
// ------------------------------------
$input-switch-thumb-radius: 15px !default;
$input-switch-thumb-switch-speed: .4s !default;
$input-switch-thumb-border: 2px solid $primary-line-color;
$input-switch-thumb-border-disabled-color: rgba($primary-line-color , .5);
$input-switch-track-width: 45px !default;
$input-switch-track-height: 5px !default;
$input-switch-bg-color: $disabled-color !default;
$input-switch-control-bg-color: $primary-bg-color !default;
$input-switch-color: $strong-highlight-color !default;
$input-range-track-radius: $input-switch-track-height/2 !default;


#{$config-class-prefix} {

  &switch {
    display: block;
    position: relative;
    line-height: $input-switch-thumb-radius * 2;

    &__input {
      position: absolute;
      // hides original checkbox input, while still keeping it accessible to screen readers
      opacity: 0;
    }

    &__label {
      display: block;
      position: relative;
      // ensure correct spacing between switch controls and label
      padding-right: $input-switch-track-width + get-spacing(xs);
      line-height: $line-height-primary;
      cursor: pointer;

      // slider
      &::before {
        position: absolute;
        top: 50%;
        right: 0;
        width: $input-switch-track-width;
        height: $input-switch-track-height;
        transform: translateY(-50%);
        transition: transform $input-switch-thumb-switch-speed;
        border-radius: $input-range-track-radius;
        background-color: $input-switch-bg-color;
        content: '';
      }

      // thumb
      &::after {
        position: absolute;
        top: 50%;
        left: calc(100% - #{$input-switch-track-width});
        width: $input-switch-thumb-radius * 2;
        height: $input-switch-thumb-radius * 2;
        transform: translateY(-50%);
        transition: transform $input-switch-thumb-switch-speed;
        border: $input-switch-thumb-border;
        border-radius: 100%;
        background-color: $input-switch-control-bg-color;
        content: '';
      }

      // focus state
      #{$config-class-prefix}switch__input:focus + &::after {
        @include input-outline;
      }

      //active state
      #{$config-class-prefix}switch__input:checked + &::after {
        //moves thumb right to the other end of the track
        transform: translateY(-50%) translateX($input-switch-track-width - $input-switch-thumb-radius * 2);
        border-color: $secondary-ui-color;
      }

      #{$config-class-prefix}switch__input:checked + &::before {
        background-color: $input-switch-color;
      }
    }

    &--stacked &__label {
      padding-bottom: $input-switch-thumb-radius * 2 + get-spacing(xs)/2; // idea is to approx align with form input height
      padding-left: 0;

      &::before,
      &::after {
        top: calc(50% + #{get-spacing(xs)});
        bottom: 0;
        left: 0;
      }

      @include on-bp(xl) {
        color: $secondary-content-color; // we want switch label to be same color with inputs in this case
      }
    }

    &__input:disabled + &__label::before {
      background: $primary-line-color;
      opacity: 0.5;
    }

    &__input:disabled + &__label::after {
      border-color: $input-switch-thumb-border-disabled-color;
    }

    &__input:disabled + &__label::before,
    &__input:disabled + &__label::after {
      cursor: not-allowed;
    }

    // on mobile only take full width and align right
    @include on-bp(sm) {
      display: inline-block;

      &__label {
        padding-right: 0;
        padding-left: $input-switch-track-width + get-spacing(xs);

        &::before {
          right: auto;
          left: 0;
        }

        &::after {
          left: 0;
        }

      }
    }
  }

}
