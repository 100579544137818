// ------------------------------------
// Legend
// ------------------------------------
// .[prefix-]legend
// ------------------------------------

@import '../themes/theme-default';
@import '../config/all';
@import '../utils/all';

#{$config-class-prefix} {
  &legend {
    display: block;
    padding-bottom: 2px;
    color: $secondary-content-color;
    @include font-primary-normal;
  }
}
