* {
  -webkit-box-sizing: border-box;
          box-sizing: border-box; }

[class^='flix-'] {
  margin: 0;
  padding: 0; }
  [class^='flix-']::before, [class^='flix-']::after {
    -webkit-box-sizing: border-box;
            box-sizing: border-box; }

body {
  color: #444;
  font-family: "Roboto Condensed", "Arial", sans-serif;
  font-size: 16px;
  font-weight: 300;
  line-height: 1.375em; }

@font-face {
  font-family: 'flixicons';
  font-style: normal;
  font-weight: normal;
  src: url("../font/flix.woff2?1569935770717") format("woff2"), url("../font/flix.woff?1569935770717") format("woff"), url("../font/flix.ttf?1569935770717") format("truetype");
  font-display: block; }

.flix-icon-account-attention:before {
  content: '\e801'; }

.flix-icon-account-settings-solid:before {
  content: '\e802'; }

.flix-icon-account-settings:before {
  content: '\e803'; }

.flix-icon-account-solid:before {
  content: '\e804'; }

.flix-icon-account:before {
  content: '\e805'; }

.flix-icon-airport-solid:before {
  content: '\e806'; }

.flix-icon-airport:before {
  content: '\e807'; }

.flix-icon-apps-solid:before {
  content: '\e808'; }

.flix-icon-apps:before {
  content: '\e809'; }

.flix-icon-archive-solid:before {
  content: '\e80a'; }

.flix-icon-archive:before {
  content: '\e80b'; }

.flix-icon-arrow-big-down:before {
  content: '\e80c'; }

.flix-icon-arrow-big-left:before {
  content: '\e80d'; }

.flix-icon-arrow-big-right:before {
  content: '\e80e'; }

.flix-icon-arrow-big-up:before {
  content: '\e80f'; }

.flix-icon-arrow-down:before {
  content: '\e810'; }

.flix-icon-arrow-left:before {
  content: '\e811'; }

.flix-icon-arrow-right:before {
  content: '\e812'; }

.flix-icon-arrow-up:before {
  content: '\e813'; }

.flix-icon-attention-solid:before {
  content: '\e814'; }

.flix-icon-attention:before {
  content: '\e815'; }

.flix-icon-audio-solid:before {
  content: '\e816'; }

.flix-icon-audio:before {
  content: '\e817'; }

.flix-icon-beach-solid:before {
  content: '\e818'; }

.flix-icon-beach:before {
  content: '\e819'; }

.flix-icon-beer:before {
  content: '\e81a'; }

.flix-icon-bike-solid:before {
  content: '\e81b'; }

.flix-icon-bike:before {
  content: '\e81c'; }

.flix-icon-birthday-solid:before {
  content: '\e81d'; }

.flix-icon-birthday:before {
  content: '\e81e'; }

.flix-icon-book-solid:before {
  content: '\e81f'; }

.flix-icon-book:before {
  content: '\e820'; }

.flix-icon-booster-solid:before {
  content: '\e821'; }

.flix-icon-booster:before {
  content: '\e822'; }

.flix-icon-burger:before {
  content: '\e823'; }

.flix-icon-bus-night-solid:before {
  content: '\e824'; }

.flix-icon-bus-night:before {
  content: '\e825'; }

.flix-icon-bus-service-solid:before {
  content: '\e826'; }

.flix-icon-bus-service:before {
  content: '\e827'; }

.flix-icon-bus-solid:before {
  content: '\e828'; }

.flix-icon-bus-stop-solid:before {
  content: '\e829'; }

.flix-icon-bus-stop:before {
  content: '\e82a'; }

.flix-icon-bus-time-solid:before {
  content: '\e82b'; }

.flix-icon-bus:before {
  content: '\e82c'; }

.flix-icon-bus-time:before {
  content: '\e82d'; }

.flix-icon-buses:before {
  content: '\e82e'; }

.flix-icon-calendar-solid:before {
  content: '\e82f'; }

.flix-icon-calendar:before {
  content: '\e830'; }

.flix-icon-call-solid:before {
  content: '\e831'; }

.flix-icon-call:before {
  content: '\e832'; }

.flix-icon-car-solid:before {
  content: '\e833'; }

.flix-icon-car:before {
  content: '\e834'; }

.flix-icon-cart-solid:before {
  content: '\e835'; }

.flix-icon-cart:before {
  content: '\e836'; }

.flix-icon-chat-solid:before {
  content: '\e837'; }

.flix-icon-chat:before {
  content: '\e838'; }

.flix-icon-check-solid:before {
  content: '\e839'; }

.flix-icon-check:before {
  content: '\e83a'; }

.flix-icon-checkmark-strong-solid:before {
  content: '\e83b'; }

.flix-icon-checkmark-strong:before {
  content: '\e83c'; }

.flix-icon-checkmark:before {
  content: '\e83d'; }

.flix-icon-child-solid:before {
  content: '\e83e'; }

.flix-icon-child:before {
  content: '\e83f'; }

.flix-icon-city-solid:before {
  content: '\e840'; }

.flix-icon-close:before {
  content: '\e841'; }

.flix-icon-city:before {
  content: '\e842'; }

.flix-icon-collaboration:before {
  content: '\e843'; }

.flix-icon-collaboration-solid:before {
  content: '\e844'; }

.flix-icon-collapse:before {
  content: '\e845'; }

.flix-icon-connection:before {
  content: '\e846'; }

.flix-icon-connection-transfer:before {
  content: '\e847'; }

.flix-icon-credit-card-solid:before {
  content: '\e848'; }

.flix-icon-credit-card:before {
  content: '\e849'; }

.flix-icon-customer-solid:before {
  content: '\e84a'; }

.flix-icon-customer:before {
  content: '\e84b'; }

.flix-icon-delete-solid:before {
  content: '\e84c'; }

.flix-icon-directions-solid:before {
  content: '\e84d'; }

.flix-icon-delete:before {
  content: '\e84e'; }

.flix-icon-directions:before {
  content: '\e84f'; }

.flix-icon-discount-solid:before {
  content: '\e850'; }

.flix-icon-discount:before {
  content: '\e851'; }

.flix-icon-document-solid:before {
  content: '\e852'; }

.flix-icon-document:before {
  content: '\e853'; }

.flix-icon-drinks:before {
  content: '\e854'; }

.flix-icon-drinks-solid:before {
  content: '\e855'; }

.flix-icon-driver-solid:before {
  content: '\e856'; }

.flix-icon-driver:before {
  content: '\e857'; }

.flix-icon-ebus-solid:before {
  content: '\e858'; }

.flix-icon-ebus:before {
  content: '\e859'; }

.flix-icon-edit-solid:before {
  content: '\e85a'; }

.flix-icon-edit:before {
  content: '\e85b'; }

.flix-icon-entertainment-solid:before {
  content: '\e85c'; }

.flix-icon-entertainment:before {
  content: '\e85d'; }

.flix-icon-expand:before {
  content: '\e85e'; }

.flix-icon-eye-no-solid:before {
  content: '\e85f'; }

.flix-icon-eye-no:before {
  content: '\e860'; }

.flix-icon-eye-solid:before {
  content: '\e861'; }

.flix-icon-eye:before {
  content: '\e862'; }

.flix-icon-ferry-solid:before {
  content: '\e863'; }

.flix-icon-ferry:before {
  content: '\e864'; }

.flix-icon-filter:before {
  content: '\e865'; }

.flix-icon-flixtech-solid:before {
  content: '\e866'; }

.flix-icon-flixtech:before {
  content: '\e867'; }

.flix-icon-full-screen:before {
  content: '\e868'; }

.flix-icon-games-solid:before {
  content: '\e869'; }

.flix-icon-games:before {
  content: '\e86a'; }

.flix-icon-group-solid:before {
  content: '\e86b'; }

.flix-icon-group:before {
  content: '\e86c'; }

.flix-icon-headphones-solid:before {
  content: '\e86d'; }

.flix-icon-headphones:before {
  content: '\e86e'; }

.flix-icon-home-solid:before {
  content: '\e86f'; }

.flix-icon-home:before {
  content: '\e870'; }

.flix-icon-info-solid:before {
  content: '\e871'; }

.flix-icon-info:before {
  content: '\e872'; }

.flix-icon-interconnection-solid:before {
  content: '\e873'; }

.flix-icon-interconnection:before {
  content: '\e874'; }

.flix-icon-invoice:before {
  content: '\e875'; }

.flix-icon-label-attention-solid:before {
  content: '\e876'; }

.flix-icon-label-attention:before {
  content: '\e877'; }

.flix-icon-label-solid:before {
  content: '\e878'; }

.flix-icon-label:before {
  content: '\e879'; }

.flix-icon-leaf-solid:before {
  content: '\e87a'; }

.flix-icon-leaf:before {
  content: '\e87b'; }

.flix-icon-link-solid:before {
  content: '\e87c'; }

.flix-icon-link:before {
  content: '\e87d'; }

.flix-icon-location-solid:before {
  content: '\e87e'; }

.flix-icon-location:before {
  content: '\e87f'; }

.flix-icon-logout-solid:before {
  content: '\e880'; }

.flix-icon-logout:before {
  content: '\e881'; }

.flix-icon-lost-solid:before {
  content: '\e882'; }

.flix-icon-lost:before {
  content: '\e883'; }

.flix-icon-luggage-additional-solid:before {
  content: '\e884'; }

.flix-icon-luggage-additional:before {
  content: '\e885'; }

.flix-icon-luggage-bulky-solid:before {
  content: '\e886'; }

.flix-icon-luggage-bulky:before {
  content: '\e887'; }

.flix-icon-luggage-hand-solid:before {
  content: '\e888'; }

.flix-icon-luggage-hand:before {
  content: '\e889'; }

.flix-icon-luggage-solid:before {
  content: '\e88a'; }

.flix-icon-luggage:before {
  content: '\e88b'; }

.flix-icon-magnifier-solid:before {
  content: '\e88c'; }

.flix-icon-magnifier:before {
  content: '\e88d'; }

.flix-icon-map-solid:before {
  content: '\e88e'; }

.flix-icon-map:before {
  content: '\e88f'; }

.flix-icon-men-women-solid:before {
  content: '\e890'; }

.flix-icon-men-women:before {
  content: '\e891'; }

.flix-icon-minus:before {
  content: '\e892'; }

.flix-icon-mountains-solid:before {
  content: '\e893'; }

.flix-icon-mountains:before {
  content: '\e894'; }

.flix-icon-new-tab-solid:before {
  content: '\e895'; }

.flix-icon-new-tab:before {
  content: '\e896'; }

.flix-icon-newsletter-solid:before {
  content: '\e897'; }

.flix-icon-newsletter:before {
  content: '\e898'; }

.flix-icon-night-solid:before {
  content: '\e899'; }

.flix-icon-night:before {
  content: '\e89a'; }

.flix-icon-partner-solid:before {
  content: '\e89b'; }

.flix-icon-partner:before {
  content: '\e89c'; }

.flix-icon-passport-solid:before {
  content: '\e89d'; }

.flix-icon-passport:before {
  content: '\e89e'; }

.flix-icon-person-solid:before {
  content: '\e89f'; }

.flix-icon-person:before {
  content: '\e8a0'; }

.flix-icon-phone-solid:before {
  content: '\e8a1'; }

.flix-icon-phone-tablet-solid:before {
  content: '\e8a2'; }

.flix-icon-phone-tablet:before {
  content: '\e8a3'; }

.flix-icon-phone:before {
  content: '\e8a4'; }

.flix-icon-pin-solid:before {
  content: '\e8a5'; }

.flix-icon-pin:before {
  content: '\e8a6'; }

.flix-icon-plane-solid:before {
  content: '\e8a7'; }

.flix-icon-plane:before {
  content: '\e8a8'; }

.flix-icon-plus:before {
  content: '\e8a9'; }

.flix-icon-public-solid:before {
  content: '\e8aa'; }

.flix-icon-public:before {
  content: '\e8ab'; }

.flix-icon-q-and-a-solid:before {
  content: '\e8ac'; }

.flix-icon-q-and-a:before {
  content: '\e8ad'; }

.flix-icon-question-solid:before {
  content: '\e8ae'; }

.flix-icon-question:before {
  content: '\e8af'; }

.flix-icon-real-time-solid:before {
  content: '\e8b0'; }

.flix-icon-real-time:before {
  content: '\e8b1'; }

.flix-icon-rebook-solid:before {
  content: '\e8b2'; }

.flix-icon-rebook:before {
  content: '\e8b3'; }

.flix-icon-refresh:before {
  content: '\e8b4'; }

.flix-icon-seat-belt-solid:before {
  content: '\e8b5'; }

.flix-icon-seat-belt:before {
  content: '\e8b6'; }

.flix-icon-seat-solid:before {
  content: '\e8b7'; }

.flix-icon-seat:before {
  content: '\e8b8'; }

.flix-icon-secure-payment-solid:before {
  content: '\e8b9'; }

.flix-icon-secure-payment:before {
  content: '\e8ba'; }

.flix-icon-security-solid:before {
  content: '\e8bb'; }

.flix-icon-security:before {
  content: '\e8bc'; }

.flix-icon-settings-solid:before {
  content: '\e8bd'; }

.flix-icon-settings:before {
  content: '\e8be'; }

.flix-icon-share-file-solid:before {
  content: '\e8bf'; }

.flix-icon-share-file:before {
  content: '\e8c0'; }

.flix-icon-sit-solid:before {
  content: '\e8c1'; }

.flix-icon-sit:before {
  content: '\e8c2'; }

.flix-icon-snacks-solid:before {
  content: '\e8c3'; }

.flix-icon-snacks:before {
  content: '\e8c4'; }

.flix-icon-socket-solid:before {
  content: '\e8c5'; }

.flix-icon-socket:before {
  content: '\e8c6'; }

.flix-icon-stopwatch-solid:before {
  content: '\e8c7'; }

.flix-icon-stopwatch:before {
  content: '\e8c8'; }

.flix-icon-stroller-solid:before {
  content: '\e8c9'; }

.flix-icon-stroller:before {
  content: '\e8ca'; }

.flix-icon-suitcase-solid:before {
  content: '\e8cb'; }

.flix-icon-suitcase:before {
  content: '\e8cc'; }

.flix-icon-switch-vertical:before {
  content: '\e8cd'; }

.flix-icon-switch:before {
  content: '\e8ce'; }

.flix-icon-ticket-solid:before {
  content: '\e8cf'; }

.flix-icon-ticket-special-solid:before {
  content: '\e8d0'; }

.flix-icon-ticket-special:before {
  content: '\e8d1'; }

.flix-icon-ticket:before {
  content: '\e8d2'; }

.flix-icon-time-solid:before {
  content: '\e8d3'; }

.flix-icon-time:before {
  content: '\e8d4'; }

.flix-icon-toilet-solid:before {
  content: '\e8d5'; }

.flix-icon-toilet:before {
  content: '\e8d6'; }

.flix-icon-train-night-solid:before {
  content: '\e8d7'; }

.flix-icon-train-night:before {
  content: '\e8d8'; }

.flix-icon-train-service-solid:before {
  content: '\e8d9'; }

.flix-icon-train-service:before {
  content: '\e8da'; }

.flix-icon-train-solid:before {
  content: '\e8db'; }

.flix-icon-train-stop-solid:before {
  content: '\e8dc'; }

.flix-icon-train-stop:before {
  content: '\e8dd'; }

.flix-icon-train-time-solid:before {
  content: '\e8de'; }

.flix-icon-train-time:before {
  content: '\e8df'; }

.flix-icon-train-wagon-solid:before {
  content: '\e8e0'; }

.flix-icon-train:before {
  content: '\e8e1'; }

.flix-icon-transfer-solid:before {
  content: '\e8e2'; }

.flix-icon-transfer:before {
  content: '\e8e3'; }

.flix-icon-trip-solid:before {
  content: '\e8e4'; }

.flix-icon-trip:before {
  content: '\e8e5'; }

.flix-icon-tunnel:before {
  content: '\e8e6'; }

.flix-icon-user-add-group-solid:before {
  content: '\e8e7'; }

.flix-icon-user-add-group:before {
  content: '\e8e8'; }

.flix-icon-user-add-solid:before {
  content: '\e8e9'; }

.flix-icon-user-add:before {
  content: '\e8ea'; }

.flix-icon-user-minus-solid:before {
  content: '\e8eb'; }

.flix-icon-user-minus:before {
  content: '\e8ec'; }

.flix-icon-user-private-solid:before {
  content: '\e8ed'; }

.flix-icon-user-private:before {
  content: '\e8ee'; }

.flix-icon-voucher-solid:before {
  content: '\e8ef'; }

.flix-icon-voucher:before {
  content: '\e8f0'; }

.flix-icon-warning:before {
  content: '\e8f1'; }

.flix-icon-wheelchair-solid:before {
  content: '\e8f2'; }

.flix-icon-wheelchair:before {
  content: '\e8f3'; }

.flix-icon-wifi-solid:before {
  content: '\e8f4'; }

.flix-icon-wifi:before {
  content: '\e8f5'; }

.flix-icon-account-attention-solid:before {
  content: '\e800'; }

.flix-icon-download:before {
  content: '\e8f6'; }

.flix-icon-station-solid:before {
  content: '\e8f7'; }

.flix-icon-station:before {
  content: '\e8f8'; }

.flix-icon-upload:before {
  content: '\e8f9'; }

.flix-icon-watch-solid:before {
  content: '\e8fa'; }

.flix-icon-watch:before {
  content: '\e8fb'; }

.flix-icon-bus-double-solid:before {
  content: '\e8fc'; }

.flix-icon-bus-double:before {
  content: '\e8fd'; }

.flix-icon-desktop:before {
  content: '\e8fe'; }

.flix-icon-ride-share-solid:before {
  content: '\e8ff'; }

.flix-icon-ride-share:before {
  content: '\e900'; }

.flix-icon-smoking-no:before {
  content: '\e901'; }

.flix-icon-smoking:before {
  content: '\e902'; }

.flix-icon-sort:before {
  content: '\e903'; }

.flix-icon-tablet:before {
  content: '\e904'; }

.flix-icon-zoom-in:before {
  content: '\e905'; }

.flix-icon-zoom-out:before {
  content: '\e906'; }

.flix-icon-cash:before {
  content: '\e907'; }

.flix-icon-forbidden:before {
  content: '\e908'; }

.flix-icon-offer-solid:before {
  content: '\e909'; }

.flix-icon-offer:before {
  content: '\e90a'; }

.flix-icon-star-solid:before {
  content: '\e90b'; }

.flix-icon-star:before {
  content: '\e90c'; }

.flix-icon-bulb-solid:before {
  content: '\e90d'; }

.flix-icon-bulb:before {
  content: '\e90e'; }

.flix-icon-car-seat:before {
  content: '\e910'; }

.flix-icon-lock-solid:before {
  content: '\e911'; }

.flix-icon-lock:before {
  content: '\e912'; }

.flix-icon-notification-solid:before {
  content: '\e913'; }

.flix-icon-notification:before {
  content: '\e914'; }

.flix-icon-pause-solid:before {
  content: '\e915'; }

.flix-icon-pause:before {
  content: '\e916'; }

.flix-icon-play-solid:before {
  content: '\e917'; }

.flix-icon-play:before {
  content: '\e918'; }

.flix-icon-wheelchair-info-solid:before {
  content: '\e919'; }

.flix-icon-wheelchair-info:before {
  content: '\e91a'; }

.flix-icon-car-seat-solid:before {
  content: '\e91b'; }

[class^='flix-icon-']:before,
[class*=' flix-icon-']:before {
  display: inline-block;
  width: 1em;
  margin-right: .2em;
  margin-left: .2em;
  font-family: 'flixicons';
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  line-height: 1em;
  text-align: center;
  text-decoration: inherit;
  text-transform: none;
  speak: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.flix-icon {
  display: inline-block;
  font-size: 1.2em; }
  .flix-icon--sm {
    font-size: 25px;
    line-height: 25px; }
  .flix-icon--lg {
    font-size: 50px;
    line-height: 50px; }

.flix-accordion {
  display: block;
  border-top: 1px solid #e1e1e1;
  border-bottom: 1px solid #e1e1e1; }
  .flix-accordion__title {
    color: #444;
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
    display: block;
    position: relative;
    padding: 12px;
    outline: 0;
    cursor: pointer; }
    .flix-accordion__title::-webkit-details-marker {
      display: none; }
    .flix-accordion__title::after {
      display: inline-block;
      width: 24px;
      height: 24px;
      content: '';
      vertical-align: middle;
      background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1000 1000'%3E%3Cpath d='M236.4 365.9A28.2 28.2 0 0 1 276.8 365.9L493.2 582.3A8.2 8.2 0 0 0 506.8 582.3L723.2 365.9A28.6 28.6 0 0 1 763.6 406.4L545.5 622.7A66.4 66.4 0 0 1 500 642.3H500A66.4 66.4 0 0 1 454.5 622.7L236.4 406.4A28.2 28.2 0 0 1 236.4 365.9Z' fill='rgba(51, 51, 51, 0.99)'/%3E%3C/svg%3E") no-repeat center;
      position: absolute;
      top: 50%;
      right: 12px;
      margin-top: -11px; }
    .flix-accordion__title:focus {
      outline: 1px solid transparent;
      -webkit-box-shadow: 0 0 3px #00b5e2;
              box-shadow: 0 0 3px #00b5e2; }
  .flix-accordion[open] > .flix-accordion__title::after, .flix-accordion--open > .flix-accordion__title::after {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg); }
  .flix-accordion[open] > .flix-accordion__content, .flix-accordion--open > .flix-accordion__content {
    display: block; }
  .flix-accordion__content {
    display: none;
    padding: 0 12px 12px; }
  .flix-accordion + .flix-accordion {
    border-top: 0; }

.flix-blockquote, .flix-text blockquote {
  position: relative;
  margin: 0 0 12px;
  padding: 12px 0 0 34px; }
  @media (min-width: 600px) {
    .flix-blockquote, .flix-text blockquote {
      padding: 12px 0 0 56px; } }
  .flix-blockquote::before, .flix-text blockquote::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 22px;
    height: 14px;
    background: url("data:image/svg+xml,%3Csvg id='Ebene_1' data-name='Ebene 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 159.8 100'%3E%3Cdefs%3E%3Cstyle%3E.cls-1%7Bfill:%238c8c8c;%7D%3C/style%3E%3C/defs%3E%3Ctitle%3Equote1%3C/title%3E%3Cpath class='cls-1' d='M159.4,62.9A32.2,32.2,0,0,0,129,35.7c3.7-13.8,12.8-19.4,23.8-22.5a1.3,1.3,0,0,0,.9-1.4l-1.8-11s-0.2-.9-1.6-0.7c-38,4.3-63.7,33.2-58.9,68.3,4.4,24.3,23.4,33.7,41,31.2a32.4,32.4,0,0,0,27-36.7h0ZM38.1,35.7c3.6-13.8,13-19.4,23.8-22.5a1.3,1.3,0,0,0,.9-1.4l-1.6-11S61-.2,59.6,0C21.6,4.1-3.9,33,.5,68.2c4.6,24.5,23.8,33.9,41.2,31.4A32.1,32.1,0,0,0,68.5,62.9,31.7,31.7,0,0,0,38.1,35.7Z'/%3E%3C/svg%3E") no-repeat;
    content: ''; }
    @media (min-width: 600px) {
      .flix-blockquote::before, .flix-text blockquote::before {
        width: 44px;
        height: 28px; } }

.flix-box {
  margin-bottom: 12px;
  padding: 12px;
  border: 1px solid #e1e1e1;
  border-radius: 5px;
  background: #fff; }
  @media (min-width: 600px) {
    .flix-box {
      padding: 24px; } }
  .flix-box--highlighted {
    border: 0;
    -webkit-box-shadow: 0 0 12px rgba(0, 0, 0, 0.12);
            box-shadow: 0 0 12px rgba(0, 0, 0, 0.12); }

.flix-btn {
  display: inline-block;
  position: relative;
  width: 100%;
  min-width: 142px;
  max-width: 100%;
  height: 44px;
  padding: 0 12px;
  -webkit-transition: background 250ms linear;
  transition: background 250ms linear;
  border: 0;
  border-radius: 5px;
  font-family: "Roboto Condensed", "Arial", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 44px;
  text-align: center;
  text-decoration: none;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  overflow: hidden;
  vertical-align: middle;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none; }
  @media (min-width: 600px) {
    .flix-btn {
      width: auto; } }
  @media (min-width: 1200px) {
    .flix-btn {
      height: 36px;
      line-height: 36px; } }
  .flix-btn + .flix-btn {
    margin-top: 12px; }
    @media (min-width: 600px) {
      .flix-btn + .flix-btn {
        margin-top: 0;
        margin-left: 12px; } }
  .flix-btn--block {
    display: block;
    width: 100%; }
  .flix-btn--square {
    width: 44px;
    min-width: auto;
    padding: 0;
    overflow: hidden; }
    @media (min-width: 1200px) {
      .flix-btn--square {
        width: 36px; } }
    .flix-btn--square + .flix-btn--square {
      margin-top: 0;
      margin-left: 12px; }
  .flix-btn--primary {
    border: 0;
    background: #ffad00;
    color: #fff; }
    .flix-btn--primary:hover, .flix-btn--primary:active, .flix-btn--primary.flix-btn--loading {
      background: #c68600;
      text-decoration: none; }
  .flix-btn--secondary {
    border: 0;
    background: #73d700;
    color: #fff; }
    .flix-btn--secondary:hover, .flix-btn--secondary:active, .flix-btn--secondary.flix-btn--loading {
      background: #5cac00;
      text-decoration: none; }
  .flix-btn--danger {
    border: 0;
    background: #ff5700;
    color: #fff; }
    .flix-btn--danger:hover, .flix-btn--danger:active, .flix-btn--danger.flix-btn--loading {
      background: #c64300;
      text-decoration: none; }
  .flix-btn--tertiary {
    border: 1px solid #73d700;
    background: transparent;
    color: #73d700;
    line-height: 42px; }
    @media (min-width: 1200px) {
      .flix-btn--tertiary {
        line-height: 34px; } }
    .flix-btn--tertiary:hover, .flix-btn--tertiary:active, .flix-btn--tertiary.flix-btn--loading {
      background-color: #5cac00;
      color: #fff;
      text-decoration: none; }
  .flix-btn:disabled, .flix-btn--disabled {
    border: 0;
    background: #c8c8c8;
    color: #fff;
    cursor: not-allowed; }
    .flix-btn:disabled:hover, .flix-btn--disabled:hover {
      background: #c8c8c8;
      text-decoration: none; }
  .flix-btn--loading::before {
    display: inline-block;
    width: 18px;
    height: 18px;
    margin-top: -2px;
    margin-right: .5em;
    border: 1px solid rgba(255, 255, 255, 0.6);
    border-radius: 50%;
    border-left-color: white;
    content: '';
    vertical-align: middle;
    -webkit-animation: btn-spinner-rotate 1s infinite linear, btn-spinner-grow 250ms;
            animation: btn-spinner-rotate 1s infinite linear, btn-spinner-grow 250ms; }

@-webkit-keyframes btn-spinner-grow {
  0% {
    width: 0;
    height: 0;
    opacity: 0; }
  100% {
    width: 18px;
    height: 18px;
    opacity: 1; } }

@keyframes btn-spinner-grow {
  0% {
    width: 0;
    height: 0;
    opacity: 0; }
  100% {
    width: 18px;
    height: 18px;
    opacity: 1; } }

@-webkit-keyframes btn-spinner-rotate {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes btn-spinner-rotate {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }
  .flix-btn--has-icon.flix-btn--loading:before {
    margin-left: .5em; }
  .flix-btn--has-icon.flix-btn--loading .flix-btn__icon {
    display: none; }
  .flix-btn__icon {
    margin-top: -2px;
    vertical-align: middle; }
  .flix-btn__text {
    margin-right: .2em; }

.flix-btn-arrow {
  display: inline-block;
  position: relative;
  min-height: 0;
  border: 0;
  background: transparent;
  color: #444;
  font-family: "Roboto Condensed", "Arial", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  text-decoration: none;
  cursor: pointer; }
  .flix-btn-arrow:hover, .flix-btn-arrow:focus, .flix-btn-arrow:active {
    text-decoration: none; }
  .flix-btn-arrow::after {
    display: inline-block;
    width: 24px;
    height: 24px;
    content: '';
    vertical-align: middle;
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1000 1000'%3E%3Cpath d='M365.9 763.6A28.2 28.2 0 0 1 365.9 723.2L582.3 506.8A8.2 8.2 0 0 0 582.3 493.2L365.9 276.8A28.6 28.6 0 0 1 406.4 236.4L622.7 454.5A66.4 66.4 0 0 1 642.3 500H642.3A66.4 66.4 0 0 1 622.7 545.5L406.4 763.6A28.2 28.2 0 0 1 365.9 763.6Z' fill='rgba(51, 51, 51, 0.99)'/%3E%3C/svg%3E") no-repeat center; }
  .flix-btn-arrow--back::after {
    display: none; }
  .flix-btn-arrow--back::before {
    display: inline-block;
    width: 24px;
    height: 24px;
    content: '';
    vertical-align: middle;
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1000 1000'%3E%3Cpath d='M636.4 236.4A28.2 28.2 0 0 1 636.4 276.8L417.7 493.2A8.2 8.2 0 0 0 417.7 506.8L636.4 723.2A28.6 28.6 0 0 1 595.9 763.6L377.3 545.5A66.4 66.4 0 0 1 357.7 500H357.7A66.4 66.4 0 0 1 377.3 454.5L593.6 236.4A28.2 28.2 0 0 1 636.4 236.4Z' fill='rgba(51, 51, 51, 0.99)'/%3E%3C/svg%3E") no-repeat center; }
  .flix-btn-arrow--top::after {
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg); }
  .flix-btn-arrow--bottom::after {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg); }
  .flix-btn-arrow--strong {
    color: #ffad00; }
    .flix-btn-arrow--strong::after {
      background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1000 1000'%3E%3Cpath d='M365.9 763.6A28.2 28.2 0 0 1 365.9 723.2L582.3 506.8A8.2 8.2 0 0 0 582.3 493.2L365.9 276.8A28.6 28.6 0 0 1 406.4 236.4L622.7 454.5A66.4 66.4 0 0 1 642.3 500H642.3A66.4 66.4 0 0 1 622.7 545.5L406.4 763.6A28.2 28.2 0 0 1 365.9 763.6Z' fill='rgba(255, 173, 0, 0.99)'/%3E%3C/svg%3E") no-repeat center; }
    .flix-btn-arrow--strong.flix-btn-arrow--back::before {
      background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1000 1000'%3E%3Cpath d='M636.4 236.4A28.2 28.2 0 0 1 636.4 276.8L417.7 493.2A8.2 8.2 0 0 0 417.7 506.8L636.4 723.2A28.6 28.6 0 0 1 595.9 763.6L377.3 545.5A66.4 66.4 0 0 1 357.7 500H357.7A66.4 66.4 0 0 1 377.3 454.5L593.6 236.4A28.2 28.2 0 0 1 636.4 236.4Z' fill='rgba(255, 173, 0, 0.99)'/%3E%3C/svg%3E") no-repeat center; }

.flix-checkbox {
  position: relative; }
  .flix-checkbox__label {
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    min-height: 30px;
    padding-left: 42px;
    cursor: pointer; }
    .flix-checkbox__label::before {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 30px;
      height: 30px;
      border: 2px solid #c8c8c8;
      border-radius: 5px;
      content: ''; }
  .flix-checkbox__input {
    position: absolute;
    opacity: 0; }
  .flix-checkbox__input:focus + .flix-checkbox__label::before {
    outline: 1px solid transparent;
    -webkit-box-shadow: 0 0 3px #00b5e2;
            box-shadow: 0 0 3px #00b5e2; }
  .flix-checkbox__input:checked + .flix-checkbox__label::before {
    border-color: #73d700; }
  .flix-checkbox__info {
    color: #444;
    font-size: 14px;
    font-weight: 300;
    line-height: 17px;
    display: block;
    padding-top: 3px; }
  .flix-checkbox--disabled .flix-checkbox__label {
    cursor: not-allowed;
    opacity: .5; }
  .flix-checkbox--error .flix-checkbox__label:before {
    border-color: #ff5700; }
  .flix-checkbox--error .flix-checkbox__info {
    color: #c64300; }
  .flix-checkbox__input:checked + .flix-checkbox__label::after {
    display: block;
    position: absolute;
    top: 3px;
    left: 3px;
    width: 24px;
    height: 24px;
    background: url("data:image/svg+xml,%3Csvg id='Layer_1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 8'%3E%3Ctitle%3Eexport 2%3C/title%3E%3Cpath d='M4.66,7.57a.75.75,0,0,1-.53-.22l-3-3A.75.75,0,0,1,2.23,3.33L4.66,5.76,9.77.65a.75.75,0,0,1,1.06,1.06L5.19,7.35A.75.75,0,0,1,4.66,7.57Z' style='fill:%2373d700'/%3E%3C/svg%3E") no-repeat center;
    content: '';
    -webkit-animation: checkmark-grow 250ms;
            animation: checkmark-grow 250ms; }

@-webkit-keyframes checkmark-grow {
  0% {
    -webkit-transform: scale(0.25);
            transform: scale(0.25); }
  50% {
    -webkit-transform: scale(1);
            transform: scale(1); } }

@keyframes checkmark-grow {
  0% {
    -webkit-transform: scale(0.25);
            transform: scale(0.25); }
  50% {
    -webkit-transform: scale(1);
            transform: scale(1); } }

.flix-connection {
  width: 100%;
  margin-bottom: 24px;
  border-collapse: collapse; }
  .flix-connection__station, .flix-connection__station-info, .flix-connection__station-toggler {
    position: relative;
    margin-left: 28px; }
    .flix-connection__station::after, .flix-connection__station-info::after, .flix-connection__station-toggler::after {
      display: block;
      position: absolute;
      top: -0.6875em;
      bottom: -0.6875em;
      left: -24px;
      width: 1px;
      background: #8b8b8b;
      content: '';
      z-index: 1; }
  .flix-connection__station-info::after {
    top: 0%; }
  .flix-connection__station-info {
    padding: 2px 4px;
    background: #f7f7f7; }
  .flix-connection__station-toggler a {
    font-size: 16px; }
  tr:last-child .flix-connection__station-info::after {
    display: none; }
  .flix-connection__station::before {
    position: absolute;
    top: 0.6875em;
    left: -24px;
    width: 7px;
    height: 1px;
    background: #8b8b8b;
    content: '';
    vertical-align: middle;
    z-index: 2; }
  .flix-connection__title {
    margin-bottom: 6px;
    font-weight: 700;
    text-align: left; }
    .flix-connection__title-arrival {
      font-weight: 300; }
  .flix-connection__day {
    font-size: 10px;
    font-weight: 300;
    line-height: 1;
    vertical-align: text-top; }
  .flix-connection__time .flix-connection__day {
    position: absolute; }
  .flix-connection__time {
    padding-right: 17px; }
    .flix-connection__time--warning {
      color: #ffad00; }
    .flix-connection__time--old {
      color: #8b8b8b;
      text-decoration: line-through; }
    .flix-connection__time--danger {
      color: #ff5700; }
  .flix-connection__ellipsis {
    width: 0;
    min-width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden; }
  .flix-connection tr:first-child .flix-connection__station::before,
  .flix-connection tr:last-child .flix-connection__station::before {
    display: inline-block;
    top: 12px;
    left: -28px;
    width: 9px;
    height: 9px;
    margin-top: -5px;
    border: 1px solid #8b8b8b;
    border-radius: 50%;
    background: #8b8b8b;
    content: ''; }
  .flix-connection tr:first-child .flix-connection__station::before {
    background: #fff; }
  .flix-connection tr:first-child .flix-connection__station::after {
    top: 12px; }
  .flix-connection tr:last-child .flix-connection__station::after {
    bottom: calc(100% - 12px); }
  .flix-connection tr td:last-child {
    width: 100%; }
  .flix-connection td {
    min-width: 1px;
    vertical-align: top; }
  .flix-connection--live .flix-connection__time {
    padding: 0 0 0 12px;
    text-align: right; }
    .flix-connection--live .flix-connection__time .flix-connection__day {
      position: relative; }
  .flix-connection--live .flix-connection__station::after {
    top: -1.1em;
    bottom: -1.1em; }
  .flix-connection--live tr:last-child .flix-connection__station,
  .flix-connection--live tr:first-child .flix-connection__station {
    font-weight: 700; }
  .flix-connection--live tr:last-child td:last-child,
  .flix-connection--live tr:first-child td:last-child {
    font-weight: 700; }
  .flix-connection--live tr td:first-child {
    padding: 0; }
  .flix-connection--live tr td:first-child,
  .flix-connection--live tr td:only-child {
    width: 100%; }
  .flix-connection--live td {
    padding: 6px 0;
    vertical-align: middle; }
  .flix-connection--live .flix-connection__station::before {
    top: 50%; }
  .flix-connection--hide-stops tr {
    display: none; }
  .flix-connection--hide-stops tr:first-child,
  .flix-connection--hide-stops tr:last-child {
    display: table-row; }

.flix-fieldset {
  margin: 0 0 24px;
  padding: 0;
  border: 0; }
  @media (min-width: 1200px) {
    .flix-fieldset {
      margin: 0 0 24px; } }
  .flix-fieldset .flix-fieldset__item {
    margin-top: 12px;
    margin-bottom: 0; }
  .flix-fieldset__info {
    color: #444;
    font-size: 14px;
    font-weight: 300;
    line-height: 17px;
    display: block;
    padding-top: 3px; }
  .flix-fieldset--error .flix-fieldset__info {
    color: #c64300; }

.flix-footer-nav {
  position: relative;
  border-bottom: 1px solid #e1e1e1; }
  @media (min-width: 600px) {
    .flix-footer-nav {
      border-bottom: 0; } }
  .flix-footer-nav--first {
    border-top: 1px solid #e1e1e1; }
    @media (min-width: 600px) {
      .flix-footer-nav--first {
        border-top: 0; } }
  .flix-footer-nav__items {
    display: none;
    padding-right: 12px;
    padding-left: 12px;
    list-style: none; }
    @media (min-width: 600px) {
      .flix-footer-nav__items {
        display: block;
        padding-right: 0;
        padding-left: 0; } }
    .flix-footer-nav__items--open {
      display: block; }
  .flix-footer-nav__item {
    padding-bottom: 12px; }
  .flix-footer-nav__link {
    color: #444;
    font-size: 16px;
    font-weight: 300;
    line-height: 1.375em;
    font-weight: 400;
    text-decoration: none; }
    .flix-footer-nav__link:hover {
      color: #ffad00; }
  .flix-footer-nav__title {
    color: #444;
    font-size: 16px;
    font-weight: 700;
    line-height: 22px;
    position: relative;
    padding: 12px; }
    .flix-footer-nav__title::after {
      display: inline-block;
      width: 24px;
      height: 24px;
      content: '';
      vertical-align: middle;
      background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1000 1000'%3E%3Cpath d='M236.4 365.9A28.2 28.2 0 0 1 276.8 365.9L493.2 582.3A8.2 8.2 0 0 0 506.8 582.3L723.2 365.9A28.6 28.6 0 0 1 763.6 406.4L545.5 622.7A66.4 66.4 0 0 1 500 642.3H500A66.4 66.4 0 0 1 454.5 622.7L236.4 406.4A28.2 28.2 0 0 1 236.4 365.9Z' fill='rgba(51, 51, 51, 0.99)'/%3E%3C/svg%3E") no-repeat center;
      position: absolute;
      top: 50%;
      right: 12px;
      margin-top: -11px; }
    @media (min-width: 600px) {
      .flix-footer-nav__title {
        padding-right: 0;
        padding-left: 0; }
        .flix-footer-nav__title::after {
          display: none; } }
    .flix-footer-nav__title--open::after {
      background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1000 1000'%3E%3Cpath d='M763.6 626.4A28.2 28.2 0 0 1 723.2 626.4L506.8 409.1A8.6 8.6 0 0 0 493.2 409.1L276.8 626.4A28.6 28.6 0 1 1 236.4 585.9L454.5 369.5A66.4 66.4 0 0 1 500 350H500A66.4 66.4 0 0 1 545.5 369.5L761.8 585.9A28.2 28.2 0 0 1 763.6 626.4Z' fill='rgba(51, 51, 51, 0.99)'/%3E%3C/svg%3E") no-repeat center; }

.flix-control {
  display: block;
  position: relative;
  width: 100%;
  margin-bottom: 24px; }
  .flix-control--is-last {
    margin-bottom: 0; }
  .flix-control--small {
    width: 50%; }

.flix-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding-right: 12px;
  padding-left: 12px;
  background: #73d700;
  z-index: 1000;
  -webkit-font-smoothing: subpixel-antialiased; }
  @media (min-width: 1200px) {
    .flix-header {
      text-align: center; } }
  .flix-header--unfixed {
    position: static; }
  .flix-header__inner {
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
    width: calc(100% + 12px);
    margin-right: -6px;
    margin-left: -6px;
    position: relative;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    min-width: 320px;
    max-width: 1188px; }
  .flix-header--fullwidth .flix-header__inner {
    max-width: none; }

.flix-header-nav-toggle {
  display: none;
  position: absolute;
  top: 0;
  width: 60px;
  height: 60px;
  padding: 0 12px;
  border: 0;
  background: none;
  color: #fff;
  font-size: 16px;
  line-height: 60px; }

.flix-header-brand {
  width: 50%;
  padding-right: 6px;
  padding-left: 6px;
  margin-left: 25%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-ordinal-group: 2;
  -webkit-order: 1;
      -ms-flex-order: 1;
          order: 1;
  height: 60px; }
  @media (min-width: 1200px) {
    .flix-header-brand {
      width: 16.66667%;
      padding-right: 6px;
      padding-left: 6px;
      -webkit-box-pack: start;
      -webkit-justify-content: flex-start;
          -ms-flex-pack: start;
              justify-content: flex-start;
      margin-left: 0; } }
  .flix-header-brand__link {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center;
    width: 100%; }
    @media (min-width: 1200px) {
      .flix-header-brand__link {
        -webkit-box-pack: start;
        -webkit-justify-content: start;
            -ms-flex-pack: start;
                justify-content: start; } }
  .flix-header-brand__img {
    width: auto;
    height: 22px;
    border: 0; }
    .flix-header-brand--square .flix-header-brand__img {
      width: 36px;
      height: 36px; }

.flix-header-widgets {
  display: none;
  width: 25%;
  padding-right: 6px;
  padding-left: 6px;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  -webkit-box-ordinal-group: 3;
  -webkit-order: 2;
      -ms-flex-order: 2;
          order: 2; }
  @media (min-width: 600px) {
    .flix-header-widgets {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex; } }
  @media (min-width: 1200px) {
    .flix-header-widgets {
      width: 16.66667%;
      padding-right: 6px;
      padding-left: 6px;
      -webkit-box-ordinal-group: 4;
      -webkit-order: 3;
          -ms-flex-order: 3;
              order: 3; } }
  .flix-header-widgets--sticky {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex; }
  .flix-header-widgets--mobile {
    display: block;
    width: auto; }
    @media (min-width: 600px) {
      .flix-header-widgets--mobile {
        display: none; } }
  .flix-header-widgets--has-container {
    margin: 0 24px;
    padding: 12px 0;
    border-top: 1px solid #e1e1e1; }

.flix-header-nav-wrapper {
  position: fixed;
  top: 60px;
  bottom: 0;
  left: -250px;
  width: 250px;
  -webkit-transition: left 250ms ease;
  transition: left 250ms ease;
  background: #fff;
  -webkit-box-shadow: none;
          box-shadow: none;
  z-index: 1000;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch; }
  @media (min-width: 600px) {
    .flix-header-nav-wrapper {
      position: static;
      top: auto;
      bottom: auto;
      left: auto;
      width: auto;
      padding: 0;
      background: #5cac00;
      -webkit-box-shadow: none;
              box-shadow: none;
      overflow-y: visible; } }
  @media (min-width: 1200px) {
    .flix-header-nav-wrapper {
      background: transparent; } }
  .flix-header-nav-wrapper--visible {
    left: 0; }
    .flix-header-nav-wrapper--visible:before {
      display: block;
      position: fixed;
      top: 60px;
      left: 250px;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.12);
      content: ''; }
    @media (min-width: 600px) {
      .flix-header-nav-wrapper--visible:before {
        display: none; } }

.flix-header-navbar {
  width: 100%;
  padding-right: 6px;
  padding-left: 6px;
  position: static;
  top: auto;
  -webkit-box-ordinal-group: 4;
  -webkit-order: 3;
      -ms-flex-order: 3;
          order: 3;
  background: #5cac00;
  -webkit-box-shadow: none;
          box-shadow: none;
  overflow-y: visible; }
  @media (min-width: 600px) {
    .flix-header-navbar {
      width: calc(100% + 12px);
      margin-right: -6px;
      margin-left: -6px; } }
  @media (min-width: 1200px) {
    .flix-header-navbar {
      width: 66.66667%;
      padding-right: 6px;
      padding-left: 6px;
      -webkit-box-ordinal-group: 3;
      -webkit-order: 2;
          -ms-flex-order: 2;
              order: 2;
      margin-right: 0;
      margin-left: 0;
      background: transparent; } }

.flix-header-nav {
  margin: 0;
  padding: 0; }
  @media (min-width: 600px) {
    .flix-header-nav {
      text-align: center; } }
  @media (min-width: 1200px) {
    .flix-header-nav {
      text-align: left; } }
  .flix-header-nav__item {
    position: relative;
    min-height: 60px;
    padding: 0 24px;
    list-style-type: none; }
    @media (min-width: 600px) {
      .flix-header-nav__item {
        display: inline-block;
        min-height: 0;
        padding: 0 12px; } }
    @media (min-width: 600px) {
      .flix-header-nav__item:first-child {
        padding-left: 0; } }
    @media (min-width: 600px) {
      .flix-header-nav__item:last-child {
        padding-right: 0; } }
  @media (min-width: 600px) {
    .flix-header-nav__item + .flix-header-nav__item {
      border-top: 0; } }
  @media (min-width: 600px) {
    .flix-header-nav__item + .flix-header-nav__item::after {
      display: inline-block;
      position: absolute;
      top: 50%;
      left: 0;
      height: 16px;
      margin-top: -8px;
      border-left: 1px solid #fff;
      content: ''; } }
  .flix-header-nav__link {
    display: block;
    position: relative;
    width: 100%;
    height: 60px;
    border-top: 1px solid #e1e1e1;
    color: #444;
    font-size: 16px;
    font-weight: 400;
    line-height: 60px;
    text-decoration: none;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer; }
    @media (min-width: 600px) {
      .flix-header-nav__link {
        width: auto;
        height: 40px;
        border: 0;
        color: #fff;
        line-height: 40px; } }
    @media (min-width: 1200px) {
      .flix-header-nav__link {
        height: 60px;
        line-height: 60px; } }
  .flix-header-nav__link:hover::before, .flix-header-nav__link--active::before {
    position: absolute;
    left: -24px;
    height: 100%;
    border-left: 4px solid #ffad00;
    content: ''; }
    @media (min-width: 600px) {
      .flix-header-nav__link:hover::before, .flix-header-nav__link--active::before {
        left: auto;
        width: 100%;
        border-bottom: 4px solid #fff;
        border-left: 0; } }
  .flix-header-nav__item--has-subnav .flix-header-nav__link::after {
    display: inline-block;
    width: 24px;
    height: 24px;
    content: '';
    vertical-align: middle;
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1000 1000'%3E%3Cpath d='M236.4 365.9A28.2 28.2 0 0 1 276.8 365.9L493.2 582.3A8.2 8.2 0 0 0 506.8 582.3L723.2 365.9A28.6 28.6 0 0 1 763.6 406.4L545.5 622.7A66.4 66.4 0 0 1 500 642.3H500A66.4 66.4 0 0 1 454.5 622.7L236.4 406.4A28.2 28.2 0 0 1 236.4 365.9Z' fill='rgba(68, 68, 68, 0.99)'/%3E%3C/svg%3E") no-repeat center;
    position: absolute;
    top: 50%;
    right: 0;
    margin-top: -12px; }
    @media (min-width: 600px) {
      .flix-header-nav__item--has-subnav .flix-header-nav__link::after {
        background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1000 1000'%3E%3Cpath d='M236.4 365.9A28.2 28.2 0 0 1 276.8 365.9L493.2 582.3A8.2 8.2 0 0 0 506.8 582.3L723.2 365.9A28.6 28.6 0 0 1 763.6 406.4L545.5 622.7A66.4 66.4 0 0 1 500 642.3H500A66.4 66.4 0 0 1 454.5 622.7L236.4 406.4A28.2 28.2 0 0 1 236.4 365.9Z' fill='rgba(255, 255, 255, 0.99)'/%3E%3C/svg%3E") no-repeat center;
        position: static;
        top: auto;
        right: auto;
        margin-top: 0; } }
  .flix-header-nav__item--has-subnav:hover .flix-header-nav__link::after, .flix-header-nav__item--has-subnav__item--has-subnav-open .flix-header-nav__link::after {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg); }
    @media (min-width: 600px) {
      .flix-header-nav__item--has-subnav:hover .flix-header-nav__link::after, .flix-header-nav__item--has-subnav__item--has-subnav-open .flix-header-nav__link::after {
        -webkit-transform: none;
                transform: none; } }

.flix-header-subnav {
  max-height: 0;
  margin: 0;
  padding: 0;
  -webkit-transition: max-height .25s ease-out;
  transition: max-height .25s ease-out;
  list-style: none;
  text-align: left;
  overflow: hidden; }
  @media (min-width: 600px) {
    .flix-header-subnav {
      display: none;
      position: absolute;
      top: 100%;
      left: 50%;
      width: 250px;
      max-height: none;
      margin-top: -4px;
      margin-left: -135px;
      padding: 12px 0;
      border-radius: 5px;
      background: #fff;
      -webkit-box-shadow: 0 0 12px rgba(0, 0, 0, 0.12);
              box-shadow: 0 0 12px rgba(0, 0, 0, 0.12);
      overflow: visible;
      z-index: 1000; } }
  @media (min-width: 1200px) {
    .flix-header-subnav {
      margin-top: -10px; } }
  @media (min-width: 600px) {
    .flix-header-subnav::before {
      position: absolute;
      top: -5px;
      left: 50%;
      width: 0;
      height: 0;
      border-right: 6px solid transparent;
      border-bottom: 6px solid #fff;
      border-left: 6px solid transparent;
      content: ''; } }
  .flix-header-nav__item:hover .flix-header-subnav {
    max-height: 1000px; }
    @media (min-width: 600px) {
      .flix-header-nav__item:hover .flix-header-subnav {
        display: block;
        max-height: none; } }
  .flix-header-subnav__item {
    margin-bottom: 12px; }
    @media (min-width: 600px) {
      .flix-header-subnav__item {
        margin-bottom: 0; } }
  .flix-header-subnav__link {
    display: block;
    position: relative;
    width: 100%;
    padding: 5px 24px;
    color: #444;
    font-size: 16px;
    font-weight: 400;
    text-decoration: none;
    cursor: pointer; }
    @media (min-width: 600px) {
      .flix-header-subnav__link {
        padding: 5px 24px; } }
  .flix-header-subnav__link:hover::before, .flix-header-subnav__link--active::before {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    border-left: 4px solid #ffad00;
    content: ''; }

.flix-header-nav-toggle {
  display: block; }
  @media (min-width: 600px) {
    .flix-header-nav-toggle {
      display: none; } }

.flix-h1, .flix-text h1 {
  color: #444;
  font-size: 28px;
  font-weight: 700;
  line-height: 34px;
  margin: 0;
  padding: 0 0 24px; }

.flix-h2, .flix-text h2 {
  color: #444;
  font-size: 22px;
  font-weight: 700;
  line-height: 28px;
  margin: 0;
  padding: 12px 0; }

.flix-h3, .flix-text h3 {
  color: #444;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  margin: 0;
  padding: 12px 0; }

.flix-h4, .flix-text h4 {
  color: #444;
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  margin: 0;
  padding: 12px 0; }

.flix-h2--section-header, .flix-h3--section-header, .flix-h4--section-header {
  padding-top: 0; }

.flix-tooltip {
  position: absolute;
  top: 50%;
  left: 100%;
  width: 250px;
  min-height: 44px;
  margin-left: 12px;
  padding: 12px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  border-radius: 5px;
  background: #fff;
  color: #444;
  font-family: "Roboto Condensed", "Arial", sans-serif;
  font-size: 16px;
  text-align: center;
  text-decoration: none;
  -webkit-box-shadow: 0 0 12px rgba(0, 0, 0, 0.12);
          box-shadow: 0 0 12px rgba(0, 0, 0, 0.12);
  z-index: 10;
  display: none; }
  .flix-tooltip::before {
    position: absolute;
    top: 50%;
    left: 0;
    width: 15px;
    height: 15px;
    -webkit-transform: translateY(-50%) translateX(-50%) rotate(45deg);
            transform: translateY(-50%) translateX(-50%) rotate(45deg);
    background: #fff;
    -webkit-box-shadow: -3px 3px 5px rgba(0, 0, 0, 0.1);
            box-shadow: -3px 3px 5px rgba(0, 0, 0, 0.1);
    z-index: 10;
    content: ''; }
  .flix-tooltip--active {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
            justify-content: space-between;
    text-align: left; }
  .flix-tooltip--left {
    right: 100%;
    left: auto;
    margin-right: 12px;
    margin-left: 0; }
    .flix-tooltip--left::before {
      right: 0;
      left: auto;
      -webkit-transform: translateY(-50%) translateX(50%) rotate(45deg);
              transform: translateY(-50%) translateX(50%) rotate(45deg);
      -webkit-box-shadow: 3px -3px 5px rgba(0, 0, 0, 0.1);
              box-shadow: 3px -3px 5px rgba(0, 0, 0, 0.1); }
  .flix-tooltip--top {
    top: auto;
    bottom: 100%;
    left: 50%;
    margin-bottom: 12px;
    margin-left: 0;
    -webkit-transform: translateY(0%) translateX(-50%);
            transform: translateY(0%) translateX(-50%); }
    .flix-tooltip--top::before {
      top: auto;
      bottom: 0;
      left: 50%;
      margin-left: 0;
      -webkit-transform: translateY(50%) translateX(-50%) rotate(-45deg);
              transform: translateY(50%) translateX(-50%) rotate(-45deg); }
  .flix-tooltip--bottom {
    top: 100%;
    left: 50%;
    margin-top: 12px;
    margin-left: 0;
    -webkit-transform: translateY(0%) translateX(-50%);
            transform: translateY(0%) translateX(-50%); }
    .flix-tooltip--bottom::before {
      top: 0;
      left: 50%;
      -webkit-transform: translateY(-50%) translateX(-50%) rotate(45deg);
              transform: translateY(-50%) translateX(-50%) rotate(45deg);
      -webkit-box-shadow: -3px -3px 5px rgba(0, 0, 0, 0.1);
              box-shadow: -3px -3px 5px rgba(0, 0, 0, 0.1); }
  .flix-tooltip__close {
    border: 0;
    background: transparent;
    font-size: 16px;
    cursor: pointer;
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    -webkit-align-self: flex-start;
        -ms-flex-item-align: start;
            align-self: flex-start;
    display: inline-block;
    width: 24px;
    height: 24px;
    content: '';
    vertical-align: middle;
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1000 1000'%3E%3Cpath d='M846.4 799.1L535.9 488.6 846.4 177.7A29.1 29.1 0 0 0 805 136.4L494.1 446.8 181.8 136.4A29.5 29.5 0 0 0 140.5 136.4 27.3 27.3 0 0 0 131.8 156.8 28.2 28.2 0 0 0 140.5 177.7L454.5 488.6 142.3 799.5A29.5 29.5 0 0 0 163.2 849.5 29.1 29.1 0 0 0 181.8 840.9L492.3 530.5 803.2 840.9A29.1 29.1 0 0 0 844.5 840.9 28.6 28.6 0 0 0 846.4 799.1Z' fill='rgba(51, 51, 51, 0.99)'/%3E%3C/svg%3E") no-repeat center;
    background-size: 20px; }
    .flix-tooltip__close .flix-icon {
      display: none; }

.flix-tooltip-target {
  position: relative; }

.flix-element-has-hint {
  position: relative; }
  .flix-element-has-hint::before, .flix-element-has-hint::after {
    display: none; }
  .flix-element-has-hint::before {
    position: absolute;
    top: 50%;
    left: 100%;
    width: 250px;
    min-height: 44px;
    margin-left: 12px;
    padding: 12px;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    border-radius: 5px;
    background: #fff;
    color: #444;
    font-family: "Roboto Condensed", "Arial", sans-serif;
    font-size: 16px;
    text-align: center;
    text-decoration: none;
    -webkit-box-shadow: 0 0 12px rgba(0, 0, 0, 0.12);
            box-shadow: 0 0 12px rgba(0, 0, 0, 0.12);
    z-index: 10;
    width: auto;
    min-width: 150px;
    content: attr(data-hint); }
  .flix-element-has-hint::after {
    position: absolute;
    top: 50%;
    left: 0;
    width: 15px;
    height: 15px;
    -webkit-transform: translateY(-50%) translateX(-50%) rotate(45deg);
            transform: translateY(-50%) translateX(-50%) rotate(45deg);
    background: #fff;
    -webkit-box-shadow: -3px 3px 5px rgba(0, 0, 0, 0.1);
            box-shadow: -3px 3px 5px rgba(0, 0, 0, 0.1);
    z-index: 10;
    left: 100%;
    -webkit-transform: translateY(-50%) translateX(30%) rotate(45deg);
            transform: translateY(-50%) translateX(30%) rotate(45deg);
    content: ''; }
  .flix-element-has-hint--error::before, .flix-element-has-hint--error::after {
    background: #ff5700;
    color: #fff; }
  .flix-element-has-hint--left::before {
    right: 100%;
    left: auto;
    margin-right: 12px;
    margin-left: 0; }
  .flix-element-has-hint--left::after {
    right: 100%;
    left: auto;
    -webkit-transform: translateY(-50%) translateX(-30%) rotate(-135deg);
            transform: translateY(-50%) translateX(-30%) rotate(-135deg); }
  .flix-element-has-hint--top::before {
    top: auto;
    bottom: 100%;
    left: 50%;
    margin-bottom: 12px;
    margin-left: 0;
    -webkit-transform: translateY(0%) translateX(-50%);
            transform: translateY(0%) translateX(-50%); }
  .flix-element-has-hint--top::after {
    top: auto;
    bottom: 100%;
    left: 50%;
    -webkit-transform: translateY(-30%) translateX(-50%) rotate(-45deg);
            transform: translateY(-30%) translateX(-50%) rotate(-45deg); }
  .flix-element-has-hint--bottom::before {
    top: 100%;
    left: 50%;
    margin-top: 12px;
    margin-left: 0;
    -webkit-transform: translateY(0%) translateX(-50%);
            transform: translateY(0%) translateX(-50%); }
  .flix-element-has-hint--bottom::after {
    top: 100%;
    left: 50%;
    -webkit-transform: translateY(30%) translateX(-50%) rotate(135deg);
            transform: translateY(30%) translateX(-50%) rotate(135deg); }

.flix-element-has-hint:hover::before, .flix-element-has-hint:hover::after, .flix-element-has-hint:focus::before, .flix-element-has-hint:focus::after, .flix-element-has-hint--active::before, .flix-element-has-hint--active::after {
  display: block; }

.flix-img-link {
  display: block;
  margin-bottom: 12px;
  border: 1px solid #e1e1e1;
  border-radius: 5px;
  text-decoration: none;
  overflow: hidden; }
  .flix-img-link:hover {
    -webkit-box-shadow: 0 0 12px rgba(0, 0, 0, 0.12);
            box-shadow: 0 0 12px rgba(0, 0, 0, 0.12); }
  .flix-img-link__img-wrapper {
    position: relative;
    min-height: 200px; }
    @media (min-width: 1200px) {
      .flix-img-link__img-wrapper {
        min-height: 160px; } }
  .flix-img-link__img {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  @supports ((-o-object-fit: cover) or (object-fit: cover)) {
    .flix-img-link__img {
      height: 100%;
      -o-object-fit: cover;
         object-fit: cover; } }
  .flix-img-link__content {
    position: relative;
    padding: 12px 36px 12px 24px;
    background: #fff; }
    .flix-img-link__content::after {
      display: inline-block;
      width: 24px;
      height: 24px;
      content: '';
      vertical-align: middle;
      background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1000 1000'%3E%3Cpath d='M365.9 763.6A28.2 28.2 0 0 1 365.9 723.2L582.3 506.8A8.2 8.2 0 0 0 582.3 493.2L365.9 276.8A28.6 28.6 0 0 1 406.4 236.4L622.7 454.5A66.4 66.4 0 0 1 642.3 500H642.3A66.4 66.4 0 0 1 622.7 545.5L406.4 763.6A28.2 28.2 0 0 1 365.9 763.6Z' fill='rgba(139, 139, 139, 0.99)'/%3E%3C/svg%3E") no-repeat center;
      position: absolute;
      top: 50%;
      right: 12px;
      margin-top: -11px; }
  .flix-img-link--sm {
    position: relative;
    min-height: 92px; }
    .flix-img-link--sm .flix-img-link__img-wrapper {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      width: 90px;
      min-height: 90px; }
    .flix-img-link--sm .flix-img-link__content {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -webkit-flex-direction: column;
          -ms-flex-direction: column;
              flex-direction: column;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
          -ms-flex-pack: center;
              justify-content: center;
      width: calc(100% - 90px);
      min-height: 90px;
      margin-left: 90px; }

.flix-infobox {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 12px;
  padding: 12px;
  border-radius: 5px;
  background-color: #fff;
  font-family: "Roboto Condensed", "Arial", sans-serif;
  font-size: 16px;
  font-weight: 300;
  -webkit-box-shadow: 0 0 12px rgba(0, 0, 0, 0.12);
          box-shadow: 0 0 12px rgba(0, 0, 0, 0.12); }
  @media (min-width: 600px) {
    .flix-infobox {
      padding: 24px; } }
  .flix-infobox::before {
    display: inline-block;
    width: 48px;
    height: 48px;
    background-size: 48px;
    content: '';
    vertical-align: middle;
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1000 1000'%3E%3Cpath d='M522.7 454.5V699.5A22.7 22.7 0 1 1 477.3 699.5V454.5A22.7 22.7 0 0 1 522.7 454.5ZM920.5 500A420.5 420.5 0 1 1 500 79.5 420.9 420.9 0 0 1 920.5 500ZM875 500A375 375 0 1 0 500 875 375.5 375.5 0 0 0 875 500ZM500 265.9A37.7 37.7 0 1 0 537.3 303.6 37.7 37.7 0 0 0 500 265.9Z' fill='rgba(0, 181, 226, 0.99)'/%3E%3C/svg%3E") no-repeat center;
    -webkit-align-self: flex-start;
        -ms-flex-item-align: start;
            align-self: flex-start;
    min-width: 48px;
    margin-right: 15px;
    color: #00b5e2; }
  .flix-infobox--success::before {
    display: inline-block;
    width: 48px;
    height: 48px;
    background-size: 48px;
    content: '';
    vertical-align: middle;
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1000 1000'%3E%3Cpath d='M909.1 500A409.1 409.1 0 1 1 500 90.9 409.1 409.1 0 0 1 909.1 500ZM863.6 500A363.6 363.6 0 1 0 500 863.6 363.6 363.6 0 0 0 863.6 500ZM757.3 367.3L460 664.5A23.6 23.6 0 0 1 444.1 670.9 22.7 22.7 0 0 1 428.2 664.5L242.7 479.1A22.3 22.3 0 0 1 242.7 447.7 21.8 21.8 0 0 1 274.5 447.7L444.1 616.8 727.3 335.5A22.3 22.3 0 1 1 759.1 367.3Z' fill='rgba(115, 215, 0, 0.99)'/%3E%3C/svg%3E") no-repeat center; }
  .flix-infobox--warning::before {
    display: inline-block;
    width: 48px;
    height: 48px;
    background-size: 48px;
    content: '';
    vertical-align: middle;
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1000 1000'%3E%3Cpath d='M477.3 614.5V240.9A22.7 22.7 0 0 1 522.7 240.9V614.5A22.7 22.7 0 0 1 477.3 614.5ZM920.5 500A420.5 420.5 0 1 1 500 79.5 420.9 420.9 0 0 1 920.5 500ZM875 500A375 375 0 1 0 500 875 375.5 375.5 0 0 0 875 500ZM500 709.5A37.3 37.3 0 1 0 537.3 746.8 37.3 37.3 0 0 0 500 709.5Z' fill='rgba(255, 173, 0, 0.99)'/%3E%3C/svg%3E") no-repeat center; }
  .flix-infobox--danger::before {
    display: inline-block;
    width: 48px;
    height: 48px;
    background-size: 48px;
    content: '';
    vertical-align: middle;
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1000 1000'%3E%3Cpath d='M477.3 614.5V240.9A22.7 22.7 0 0 1 522.7 240.9V614.5A22.7 22.7 0 0 1 477.3 614.5ZM920.5 500A420.5 420.5 0 1 1 500 79.5 420.9 420.9 0 0 1 920.5 500ZM875 500A375 375 0 1 0 500 875 375.5 375.5 0 0 0 875 500ZM500 709.5A37.3 37.3 0 1 0 537.3 746.8 37.3 37.3 0 0 0 500 709.5Z' fill='rgba(255, 87, 0, 0.99)'/%3E%3C/svg%3E") no-repeat center; }

.flix-input {
  position: relative; }
  .flix-input--error::before, .flix-input--valid::before {
    display: inline-block;
    width: 24px;
    height: 24px;
    content: '';
    vertical-align: middle;
    position: absolute;
    top: 10px;
    right: 12px;
    z-index: 1; }
    @media (min-width: 1200px) {
      .flix-input--error::before, .flix-input--valid::before {
        top: 30px; } }
  .flix-input--error::before {
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1000 1000'%3E%3Cpath d='M500 90.9A409.1 409.1 0 1 0 909.1 500 409.1 409.1 0 0 0 500 90.9ZM471.8 240.9A28.2 28.2 0 0 1 528.2 240.9V614.5A28.2 28.2 0 1 1 471.8 614.5ZM500 803.6A45.5 45.5 0 1 1 545.5 758.2 45.5 45.5 0 0 1 500 803.6Z' fill='rgba(255, 87, 0, 0.99)'/%3E%3C/svg%3E") no-repeat center; }
  .flix-input--valid::before {
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1000 1000'%3E%3Cpath d='M500 90.9A409.1 409.1 0 1 0 909.1 500 409.1 409.1 0 0 0 500 90.9ZM757.3 367.3L460 664.5A23.6 23.6 0 0 1 444.1 670.9 22.7 22.7 0 0 1 428.2 664.5L242.7 479.1A22.3 22.3 0 0 1 242.7 447.7 21.8 21.8 0 0 1 274.5 447.7L444.1 616.8 727.3 335.5A22.3 22.3 0 1 1 759.1 367.3Z' fill='rgba(115, 215, 0, 0.99)'/%3E%3C/svg%3E") no-repeat center; }
  .flix-input__field {
    font-family: "Roboto Condensed", "Arial", sans-serif;
    font-size: 16px;
    font-weight: 300;
    position: relative;
    width: 100%;
    height: 44px;
    padding: 0 12px;
    border: 1px solid #c8c8c8;
    border-radius: 5px;
    background-color: #fff;
    color: #444;
    line-height: 1.375em;
    text-overflow: ellipsis;
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none; }
    @media (min-width: 1200px) {
      .flix-input__field {
        height: 36px; } }
    .flix-input__field::-webkit-input-placeholder {
      color: #757575;
      font-style: italic; }
    .flix-input__field::-moz-placeholder {
      color: #757575;
      font-style: italic; }
    .flix-input__field:-ms-input-placeholder {
      color: #757575;
      font-style: italic; }
    .flix-input__field::-ms-input-placeholder {
      color: #757575;
      font-style: italic; }
    .flix-input__field::placeholder {
      color: #757575;
      font-style: italic; }
    .flix-input--valid .flix-input__field {
      padding-right: 40px; }
    .flix-input--error .flix-input__field {
      padding-right: 40px;
      border: 1px solid #ff5700; }
    .flix-input--active .flix-input__field, .flix-input__field:focus {
      border: 1px solid #757575;
      outline: none; }
    .flix-input__field[disabled],
    .flix-input--disabled .flix-input__field {
      cursor: not-allowed;
      opacity: .5; }
    .flix-input--has-icon .flix-input__field {
      padding-left: 40px; }
    .flix-input--loading .flix-input__field {
      padding-right: 48px; }
  .flix-input .flix-input__icon {
    display: block;
    position: absolute;
    left: 12px;
    margin-top: -22px;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    z-index: 2; }
    @media (min-width: 1200px) {
      .flix-input .flix-input__icon {
        margin-top: -18px; } }
    .flix-input .flix-input__icon::before {
      margin-left: 0; }
  .flix-input__spinner {
    display: block;
    position: absolute;
    right: 12px;
    margin-top: -34px;
    z-index: 2; }
    @media (min-width: 1200px) {
      .flix-input__spinner {
        margin-top: -30px; } }
  .flix-input__info {
    color: #444;
    font-size: 14px;
    font-weight: 300;
    line-height: 17px;
    display: block;
    padding-top: 3px; }
    .flix-input--error .flix-input__info {
      color: #c64300; }

.flix-input .flix-input__spinner {
  display: block; }

.flix-label {
  display: block;
  position: absolute;
  top: -8px;
  left: 7px;
  padding: 1px 5px;
  border-radius: 5px;
  background: #fff;
  color: #444;
  font-family: "Roboto Condensed", "Arial", sans-serif;
  font-size: 14px;
  font-weight: 300;
  line-height: 1em;
  z-index: 1; }
  @media (min-width: 1200px) {
    .flix-label {
      position: static;
      top: auto;
      left: auto;
      padding: 0 0 2px;
      background: transparent;
      color: #757575;
      font-size: 16px;
      line-height: 1.375em; } }

.flix-legend {
  display: block;
  padding-bottom: 2px;
  color: #757575;
  font-family: "Roboto Condensed", "Arial", sans-serif;
  font-size: 16px;
  font-weight: 300; }

.flix-link, .flix-text a {
  background-color: transparent;
  color: #00b5e2;
  font-weight: 400;
  text-decoration: none; }
  .flix-link:hover, .flix-text a:hover, .flix-link:active, .flix-text a:active, .flix-link:focus, .flix-text a:focus {
    color: #00b5e2;
    text-decoration: underline; }

.flix-list, .flix-text ul {
  margin: 0 0 12px;
  padding-left: 12px;
  list-style-type: none; }

.flix-list .flix-list, .flix-text ul ul {
  margin: 0;
  padding-left: 0; }

.flix-list__item, .flix-text ul li {
  position: relative;
  padding-left: 16px;
  line-height: 1.375em; }
  .flix-list__item::before, .flix-text ul li::before {
    display: block;
    position: absolute;
    top: 0.6875em;
    left: 0;
    width: 4px;
    height: 4px;
    margin-top: -2px;
    margin-right: 12px;
    border-radius: 50%;
    background: #444;
    content: ''; }

.flix-list-simple {
  margin: 0;
  padding-left: 0;
  list-style-type: none; }
  .flix-list-simple__item {
    min-height: 40px;
    padding: 8px 0;
    border-bottom: 1px solid #e1e1e1;
    color: #444;
    font-size: 16px; }
    .flix-list-simple__item:last-child {
      border-bottom: 0; }

.flix-list-linked {
  margin: 0;
  padding-left: 0;
  list-style-type: none; }
  .flix-list-linked__item {
    position: relative;
    border-bottom: 1px solid #e1e1e1; }
    .flix-list-linked__item::after {
      display: inline-block;
      width: 24px;
      height: 24px;
      content: '';
      vertical-align: middle;
      background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1000 1000'%3E%3Cpath d='M365.9 763.6A28.2 28.2 0 0 1 365.9 723.2L582.3 506.8A8.2 8.2 0 0 0 582.3 493.2L365.9 276.8A28.6 28.6 0 0 1 406.4 236.4L622.7 454.5A66.4 66.4 0 0 1 642.3 500H642.3A66.4 66.4 0 0 1 622.7 545.5L406.4 763.6A28.2 28.2 0 0 1 365.9 763.6Z' fill='rgba(51, 51, 51, 0.99)'/%3E%3C/svg%3E") no-repeat center;
      position: absolute;
      top: 50%;
      right: 12px;
      margin-top: -11px; }
    .flix-list-linked__item:hover, .flix-list-linked__item:active, .flix-list-linked__item:focus {
      border-radius: 5px;
      -webkit-box-shadow: 0 0 12px rgba(0, 0, 0, 0.12);
              box-shadow: 0 0 12px rgba(0, 0, 0, 0.12); }
    .flix-list-linked__item:last-child {
      border-bottom: 0; }
  .flix-list-linked a, .flix-list-linked__link {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    width: 100%;
    height: 60px;
    padding: 5px 24px 5px 12px;
    color: #444;
    font-size: 16px;
    font-weight: 400;
    text-decoration: none;
    cursor: pointer; }
    .flix-list-linked a:hover, .flix-list-linked a:active, .flix-list-linked a:focus, .flix-list-linked__link:hover, .flix-list-linked__link:active, .flix-list-linked__link:focus {
      text-decoration: none; }

.flix-list-numbered, .flix-text ol {
  margin: 0 0 12px;
  padding-left: 12px;
  list-style-type: none;
  counter-reset: numbered-list-counter; }

.flix-list-numbered__item, .flix-text ol > li {
  display: table;
  position: relative; }
  .flix-list-numbered__item::before, .flix-text ol > li::before {
    display: table-cell;
    padding-right: 6px;
    font-weight: 700;
    content: counters(numbered-list-counter, ".") ".";
    counter-increment: numbered-list-counter; }

.flix-list-numbered .flix-list-numbered, .flix-text ol ol {
  width: 100%;
  margin: 0;
  padding-left: 0; }

.flix-list-numbered .flix-list-numbered .flix-list-numbered__item::before, .flix-text ol ol > li::before {
  font-weight: 300;
  content: counters(numbered-list-counter, "."); }

.flix-nav-horizontal {
  margin: 0;
  margin-bottom: 24px;
  padding: 0; }
  .flix-nav-horizontal--centered {
    text-align: center; }
  .flix-nav-horizontal--right {
    text-align: right; }
  .flix-nav-horizontal--left {
    text-align: left; }
  .flix-nav-horizontal__items {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
        -ms-flex-align: end;
            align-items: flex-end;
    margin: 0;
    padding: 0; }
  .flix-nav-horizontal__item {
    position: relative;
    max-width: 50%;
    text-align: center;
    list-style-type: none; }
  .flix-nav-horizontal__item + .flix-nav-horizontal__item::before {
    display: inline-block;
    position: absolute;
    bottom: 13px;
    left: 0;
    height: 16px;
    border-left: 1px solid #e1e1e1;
    content: ''; }
  .flix-nav-horizontal__link {
    display: inline-block;
    width: 100%;
    padding: 9px 12px;
    color: #444;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.375em;
    text-decoration: none;
    cursor: pointer; }
    .flix-nav-horizontal__link:hover, .flix-nav-horizontal__link:active, .flix-nav-horizontal__link:focus, .flix-nav-horizontal__link--active {
      color: #ffad00;
      text-decoration: none; }
      .flix-nav-horizontal__link:hover::before, .flix-nav-horizontal__link:active::before, .flix-nav-horizontal__link:focus::before, .flix-nav-horizontal__link--active::before {
        display: block;
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        height: 0;
        border-bottom: 4px solid #ffad00;
        content: ''; }

.flix-nav-side, .flix-nav-side-subnav {
  position: relative;
  margin: 0;
  padding: 0; }
  .flix-nav-side--centered {
    text-align: center; }
  .flix-nav-side__item, .flix-nav-side-subnav__item {
    display: block;
    position: relative; }
  .flix-nav-side__link, .flix-nav-side-subnav__link {
    display: inline-block;
    position: relative;
    width: 100%;
    padding: 5px 0;
    color: #444;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.375em;
    text-decoration: none;
    cursor: pointer; }
    .flix-nav-side__link:hover, .flix-nav-side-subnav__link:hover, .flix-nav-side__link:active, .flix-nav-side-subnav__link:active, .flix-nav-side__link:focus, .flix-nav-side-subnav__link:focus {
      color: #ffad00;
      text-decoration: none; }
  .flix-nav-side__link--active {
    color: #ffad00; }
  .flix-nav-side__link--has-subnav:after {
    display: inline-block;
    width: 24px;
    height: 24px;
    content: '';
    vertical-align: middle;
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1000 1000'%3E%3Cpath d='M236.4 365.9A28.2 28.2 0 0 1 276.8 365.9L493.2 582.3A8.2 8.2 0 0 0 506.8 582.3L723.2 365.9A28.6 28.6 0 0 1 763.6 406.4L545.5 622.7A66.4 66.4 0 0 1 500 642.3H500A66.4 66.4 0 0 1 454.5 622.7L236.4 406.4A28.2 28.2 0 0 1 236.4 365.9Z' fill='rgba(68, 68, 68, 0.99)'/%3E%3C/svg%3E") no-repeat center; }
  .flix-nav-side__link--has-subnav:hover::after, .flix-nav-side__link--has-subnav:active::after, .flix-nav-side__link--has-subnav:focus::after, .flix-nav-side__link--has-subnav.flix-nav-side__link--active::after {
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1000 1000'%3E%3Cpath d='M236.4 365.9A28.2 28.2 0 0 1 276.8 365.9L493.2 582.3A8.2 8.2 0 0 0 506.8 582.3L723.2 365.9A28.6 28.6 0 0 1 763.6 406.4L545.5 622.7A66.4 66.4 0 0 1 500 642.3H500A66.4 66.4 0 0 1 454.5 622.7L236.4 406.4A28.2 28.2 0 0 1 236.4 365.9Z' fill='rgba(255, 173, 0, 0.99)'/%3E%3C/svg%3E") no-repeat center; }
  .flix-nav-side__link--has-subnav-open:after {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg); }
  .flix-nav-side__link--has-subnav-open:hover::after, .flix-nav-side__link--has-subnav-open:active::after, .flix-nav-side__link--has-subnav-open:focus::after {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg); }
  .flix-nav-side-subnav {
    padding-left: 12px; }
    .flix-nav-side-subnav__link--active {
      color: #ffad00; }

.flix-notification {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
  margin: 12px 0;
  padding: 12px;
  border-radius: 5px;
  background: #fff;
  font-family: "Roboto Condensed", "Arial", sans-serif;
  font-size: 16px;
  font-weight: 700;
  -webkit-box-shadow: 0 0 12px rgba(0, 0, 0, 0.12);
          box-shadow: 0 0 12px rgba(0, 0, 0, 0.12); }
  .flix-notification::before {
    display: inline-block;
    width: 24px;
    height: 24px;
    content: '';
    vertical-align: middle;
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1000 1000'%3E%3Cpath d='M500 90.9A409.1 409.1 0 1 0 909.1 500 409.1 409.1 0 0 0 500 90.9ZM528.2 735.5A28.2 28.2 0 1 1 471.8 735.5V490.5A28.2 28.2 0 1 1 528.2 490.5ZM500 348.6A45.5 45.5 0 1 1 545.5 303.2 45.5 45.5 0 0 1 500 348.6Z' fill='rgba(0, 181, 226, 0.99)'/%3E%3C/svg%3E") no-repeat center;
    -webkit-align-self: flex-start;
        -ms-flex-item-align: start;
            align-self: flex-start;
    min-width: 24px;
    margin-right: 12px; }
  .flix-notification--error::before {
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1000 1000'%3E%3Cpath d='M500 90.9A409.1 409.1 0 1 0 909.1 500 409.1 409.1 0 0 0 500 90.9ZM471.8 240.9A28.2 28.2 0 0 1 528.2 240.9V614.5A28.2 28.2 0 1 1 471.8 614.5ZM500 803.6A45.5 45.5 0 1 1 545.5 758.2 45.5 45.5 0 0 1 500 803.6Z' fill='rgba(255, 87, 0, 0.99)'/%3E%3C/svg%3E") no-repeat center; }
  .flix-notification--warning::before {
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1000 1000'%3E%3Cpath d='M500 90.9A409.1 409.1 0 1 0 909.1 500 409.1 409.1 0 0 0 500 90.9ZM471.8 240.9A28.2 28.2 0 0 1 528.2 240.9V614.5A28.2 28.2 0 1 1 471.8 614.5ZM500 803.6A45.5 45.5 0 1 1 545.5 758.2 45.5 45.5 0 0 1 500 803.6Z' fill='rgba(255, 173, 0, 0.99)'/%3E%3C/svg%3E") no-repeat center; }
  .flix-notification--success::before {
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1000 1000'%3E%3Cpath d='M500 90.9A409.1 409.1 0 1 0 909.1 500 409.1 409.1 0 0 0 500 90.9ZM757.3 367.3L460 664.5A23.6 23.6 0 0 1 444.1 670.9 22.7 22.7 0 0 1 428.2 664.5L242.7 479.1A22.3 22.3 0 0 1 242.7 447.7 21.8 21.8 0 0 1 274.5 447.7L444.1 616.8 727.3 335.5A22.3 22.3 0 1 1 759.1 367.3Z' fill='rgba(115, 215, 0, 0.99)'/%3E%3C/svg%3E") no-repeat center; }
  .flix-notification__content {
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
        -ms-flex-positive: 1;
            flex-grow: 1; }
  .flix-notification__close {
    border: 0;
    background: transparent;
    font-size: 16px;
    cursor: pointer;
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    -webkit-align-self: flex-start;
        -ms-flex-item-align: start;
            align-self: flex-start;
    margin-left: 12px;
    display: inline-block;
    width: 24px;
    height: 24px;
    content: '';
    vertical-align: middle;
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1000 1000'%3E%3Cpath d='M846.4 799.1L535.9 488.6 846.4 177.7A29.1 29.1 0 0 0 805 136.4L494.1 446.8 181.8 136.4A29.5 29.5 0 0 0 140.5 136.4 27.3 27.3 0 0 0 131.8 156.8 28.2 28.2 0 0 0 140.5 177.7L454.5 488.6 142.3 799.5A29.5 29.5 0 0 0 163.2 849.5 29.1 29.1 0 0 0 181.8 840.9L492.3 530.5 803.2 840.9A29.1 29.1 0 0 0 844.5 840.9 28.6 28.6 0 0 0 846.4 799.1Z' fill='rgba(51, 51, 51, 0.99)'/%3E%3C/svg%3E") no-repeat center;
    background-size: 20px; }
    .flix-notification__close .flix-icon {
      display: none; }

.flix-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.15);
  z-index: 1100; }

.flix-pager {
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
  max-width: 100%;
  padding-top: 1px; }
  .flix-pager__item {
    display: block;
    min-width: 44px;
    height: 44px;
    margin-top: -1px;
    margin-left: -1px;
    border: 1px solid #c8c8c8;
    color: #444;
    font-size: 16px;
    font-weight: 400;
    line-height: 42px;
    text-align: center;
    text-decoration: none; }
    .flix-pager__item:focus, .flix-pager__item:active {
      text-decoration: none; }
    @media (min-width: 1200px) {
      .flix-pager__item {
        min-width: 36px;
        height: 36px;
        line-height: 36px; } }
    .flix-pager__item:first-child {
      border-radius: 5px 0 0 5px; }
    .flix-pager__item:last-child {
      border-right: 1px solid #c8c8c8;
      border-radius: 0 5px 5px 0; }
    .flix-pager__item--active {
      background-color: #73d700;
      color: #fff; }
    .flix-pager__item--disabled {
      cursor: not-allowed;
      opacity: .5; }
    .flix-pager__item--prev::after {
      display: inline-block;
      width: 24px;
      height: 24px;
      content: '';
      vertical-align: middle;
      background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1000 1000'%3E%3Cpath d='M636.4 236.4A28.2 28.2 0 0 1 636.4 276.8L417.7 493.2A8.2 8.2 0 0 0 417.7 506.8L636.4 723.2A28.6 28.6 0 0 1 595.9 763.6L377.3 545.5A66.4 66.4 0 0 1 357.7 500H357.7A66.4 66.4 0 0 1 377.3 454.5L593.6 236.4A28.2 28.2 0 0 1 636.4 236.4Z' fill='rgba(68, 68, 68, 0.99)'/%3E%3C/svg%3E") no-repeat center; }
    .flix-pager__item--next::after {
      display: inline-block;
      width: 24px;
      height: 24px;
      content: '';
      vertical-align: middle;
      background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1000 1000'%3E%3Cpath d='M365.9 763.6A28.2 28.2 0 0 1 365.9 723.2L582.3 506.8A8.2 8.2 0 0 0 582.3 493.2L365.9 276.8A28.6 28.6 0 0 1 406.4 236.4L622.7 454.5A66.4 66.4 0 0 1 642.3 500H642.3A66.4 66.4 0 0 1 622.7 545.5L406.4 763.6A28.2 28.2 0 0 1 365.9 763.6Z' fill='rgba(68, 68, 68, 0.99)'/%3E%3C/svg%3E") no-repeat center; }

.flix-panel {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  -webkit-transform: translateY(100%);
          transform: translateY(100%);
  -webkit-transition: opacity 125ms, -webkit-transform 62.5ms 62.5ms;
  transition: opacity 125ms, -webkit-transform 62.5ms 62.5ms;
  transition: transform 62.5ms 62.5ms, opacity 125ms;
  transition: transform 62.5ms 62.5ms, opacity 125ms, -webkit-transform 62.5ms 62.5ms;
  opacity: 0;
  z-index: 1209; }
  .flix-panel--active {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    -webkit-transition: opacity 62.5ms 62.5ms, -webkit-transform 125ms;
    transition: opacity 62.5ms 62.5ms, -webkit-transform 125ms;
    transition: transform 125ms, opacity 62.5ms 62.5ms;
    transition: transform 125ms, opacity 62.5ms 62.5ms, -webkit-transform 125ms;
    opacity: 1; }
  .flix-panel__body {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    -webkit-transform: translate(0, 100%);
            transform: translate(0, 100%);
    -webkit-transition: -webkit-transform 250ms ease;
    transition: -webkit-transform 250ms ease;
    transition: transform 250ms ease;
    transition: transform 250ms ease, -webkit-transform 250ms ease;
    background: #fff;
    -webkit-box-shadow: 0 0 12px rgba(0, 0, 0, 0.12);
            box-shadow: 0 0 12px rgba(0, 0, 0, 0.12);
    overflow: hidden;
    z-index: 1209; }
    @media (min-width: 600px) {
      .flix-panel__body {
        width: 380px;
        -webkit-transform: translate(100%, 0);
                transform: translate(100%, 0); } }
    .flix-panel--active .flix-panel__body {
      -webkit-transform: translate(0, 0);
              transform: translate(0, 0);
      -webkit-transition: -webkit-transform 250ms 250ms ease;
      transition: -webkit-transform 250ms 250ms ease;
      transition: transform 250ms 250ms ease;
      transition: transform 250ms 250ms ease, -webkit-transform 250ms 250ms ease; }
  .flix-panel__header {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    position: absolute;
    top: 0;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
            justify-content: space-between;
    width: 100%;
    height: 60px;
    padding-right: 24px;
    padding-left: 24px;
    background: #73d700; }
  .flix-panel__title {
    padding: 0 12px;
    color: #fff;
    font-size: 18px;
    font-weight: 400;
    line-height: 1.1;
    text-align: center; }
  .flix-panel__back, .flix-panel__close {
    margin: 0 -2px;
    padding: 0;
    border: 0;
    background: none;
    color: #fff;
    font-size: 16px;
    line-height: 60px;
    cursor: pointer;
    overflow: visible; }
  .flix-panel__close {
    display: inline-block;
    width: 24px;
    height: 24px;
    content: '';
    vertical-align: middle;
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1000 1000'%3E%3Cpath d='M846.4 799.1L535.9 488.6 846.4 177.7A29.1 29.1 0 0 0 805 136.4L494.1 446.8 181.8 136.4A29.5 29.5 0 0 0 140.5 136.4 27.3 27.3 0 0 0 131.8 156.8 28.2 28.2 0 0 0 140.5 177.7L454.5 488.6 142.3 799.5A29.5 29.5 0 0 0 163.2 849.5 29.1 29.1 0 0 0 181.8 840.9L492.3 530.5 803.2 840.9A29.1 29.1 0 0 0 844.5 840.9 28.6 28.6 0 0 0 846.4 799.1Z' fill='rgba(255, 255, 255, 0.99)'/%3E%3C/svg%3E") no-repeat center;
    background-size: 20px; }
    .flix-panel__close .flix-icon {
      display: none; }
  .flix-panel__content {
    max-height: 100%;
    padding: 72px 12px;
    background: #fff;
    overflow-y: auto; }
    @media (min-width: 600px) {
      .flix-panel__content {
        padding: 84px 24px; } }
  .flix-panel__footer {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    position: absolute;
    bottom: 0;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center;
    width: 100%;
    height: 60px;
    padding: 0 12px;
    background: #fff;
    text-align: center;
    -webkit-box-shadow: 0 0 12px rgba(0, 0, 0, 0.12);
            box-shadow: 0 0 12px rgba(0, 0, 0, 0.12); }
    @media (min-width: 600px) {
      .flix-panel__footer {
        padding: 0 24px; } }
  .flix-panel__footer-section {
    margin: 0 6px; }
    @media (min-width: 0) {
      .flix-panel__footer-section {
        margin: 0 6px; } }
  .flix-panel__overlay {
    position: absolute; }

.flix-popup {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1100; }
  .flix-popup--active {
    display: block; }
  .flix-popup__body {
    position: fixed;
    top: 50%;
    left: 50%;
    width: 330px;
    max-width: 100%;
    max-height: 100%;
    padding: 36px;
    -webkit-transform: translateY(-50%) translateX(-50%);
            transform: translateY(-50%) translateX(-50%);
    border-radius: 5px;
    background: #fff;
    font-family: "Roboto Condensed", "Arial", sans-serif;
    font-size: 16px;
    font-weight: 300;
    line-height: 1.375em;
    text-align: center;
    z-index: 1101;
    overflow-y: auto; }
    @media (min-width: 600px) {
      .flix-popup__body {
        width: 600px;
        padding: 36px; } }
  .flix-popup__icon {
    padding-bottom: 12px; }
  .flix-popup__title {
    margin: 0;
    padding-bottom: 12px;
    color: #444;
    font-size: 28px;
    font-weight: 700;
    line-height: 34px; }
  .flix-popup__content {
    padding-bottom: 12px; }
  .flix-popup__actions {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
    -webkit-flex-direction: row-reverse;
        -ms-flex-direction: row-reverse;
            flex-direction: row-reverse;
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center; }
    @media (min-width: 600px) {
      .flix-popup__actions .flix-btn + .flix-btn {
        margin-right: 12px;
        margin-left: 0; } }

.flix-progress-tracker {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
      -ms-flex-pack: center;
          justify-content: center;
  height: 36px;
  padding-right: 18px;
  overflow: hidden;
  list-style-type: none; }
  .flix-progress-tracker__item {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    position: relative;
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center;
    min-width: 60px;
    height: 36px;
    padding-right: 6px;
    padding-left: 24px;
    border: 1px solid #73d700;
    border-right: 0;
    background: #fff;
    color: #73d700;
    font-weight: 400;
    line-height: 34px;
    text-decoration: none; }
    @media (min-width: 600px) {
      .flix-progress-tracker__item {
        -webkit-box-flex: 0;
        -webkit-flex-grow: 0;
            -ms-flex-positive: 0;
                flex-grow: 0;
        min-width: 145px; } }
    .flix-progress-tracker__item:first-child {
      border-radius: 5px 0 0 5px; }
    .flix-progress-tracker__item::before, .flix-progress-tracker__item::after {
      position: absolute;
      top: 50%;
      left: 100%;
      width: 0;
      height: 0;
      border: solid transparent;
      content: '';
      z-index: 1; }
    .flix-progress-tracker__item::after {
      margin-top: -17px;
      border-width: 17px;
      border-left-color: #fff; }
    .flix-progress-tracker__item::before {
      margin-top: -18px;
      border-width: 18px;
      border-left-color: #73d700; }
    .flix-progress-tracker__item--active {
      background: #73d700;
      color: #fff; }
      .flix-progress-tracker__item--active::after {
        border-left-color: #73d700; }
      .flix-progress-tracker__item--active::before {
        border-left-color: #fff; }
  .flix-progress-tracker__number {
    margin-right: .25em; }
    @media (min-width: 600px) {
      .flix-progress-tracker__number::after {
        content: '.'; } }
  .flix-progress-tracker__text {
    display: none;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden; }
    @media (min-width: 600px) {
      .flix-progress-tracker__text {
        display: block; } }

.flix-quantity__label {
  display: block;
  margin-bottom: 12px;
  color: #444; }
  @media (min-width: 1200px) {
    .flix-quantity__label {
      margin-bottom: 2px; } }

.flix-quantity--inline {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
      -ms-flex-pack: justify;
          justify-content: space-between; }

.flix-quantity--inline .flix-quantity__label {
  -webkit-align-self: center;
      -ms-flex-item-align: center;
          align-self: center;
  margin-bottom: 0;
  font-size: 16px;
  font-weight: 700;
  line-height: 22px; }
  .flix-quantity--inline .flix-quantity__label small {
    display: block;
    font-size: 14px;
    font-weight: 300;
    line-height: 17px; }

.flix-quantity__picker {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
      -ms-flex-align: stretch;
          align-items: stretch; }
  .flix-quantity__picker--active .flix-quantity__input {
    border: 1px solid #757575; }
  .flix-quantity__picker--disabled .flix-quantity__input,
  .flix-quantity__picker--disabled .flix-quantity__button {
    opacity: .5; }
  .flix-quantity__picker--error .flix-quantity__input {
    border: 1px solid #ff5700; }

.flix-quantity__input {
  -webkit-align-self: center;
      -ms-flex-item-align: center;
          align-self: center;
  font-family: "Roboto Condensed", "Arial", sans-serif;
  font-size: 16px;
  font-weight: 300;
  position: relative;
  width: 100%;
  height: 44px;
  padding: 0 12px;
  border: 1px solid #c8c8c8;
  border-radius: 5px;
  background-color: #fff;
  color: #444;
  line-height: 1.375em;
  text-overflow: ellipsis;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  width: 60px;
  text-align: center;
  -moz-appearance: textfield; }
  @media (min-width: 1200px) {
    .flix-quantity__input {
      height: 36px; } }
  .flix-quantity__input::-webkit-input-placeholder {
    color: #757575;
    font-style: italic; }
  .flix-quantity__input::-moz-placeholder {
    color: #757575;
    font-style: italic; }
  .flix-quantity__input:-ms-input-placeholder {
    color: #757575;
    font-style: italic; }
  .flix-quantity__input::-ms-input-placeholder {
    color: #757575;
    font-style: italic; }
  .flix-quantity__input::placeholder {
    color: #757575;
    font-style: italic; }
  .flix-quantity__input::-webkit-inner-spin-button, .flix-quantity__input::-webkit-outer-spin-button {
    margin: 0;
    -webkit-appearance: none; }
  .flix-quantity__input:focus {
    border: 1px solid #757575; }

.flix-quantity__button {
  width: 35px;
  padding: 0;
  border: 0;
  background: transparent;
  color: #8b8b8b;
  font-size: 16px;
  cursor: pointer; }
  .flix-quantity__button:disabled, .flix-quantity__button--disabled {
    cursor: not-allowed;
    opacity: .5; }
  .flix-quantity__button:after {
    display: inline-block;
    width: 24px;
    height: 24px;
    content: '';
    vertical-align: middle; }
  .flix-quantity__button--minus {
    padding-right: 12px; }
    .flix-quantity__button--minus:after {
      background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1000 1000'%3E%3Cpath d='M923.2 470.5H76.8A29.1 29.1 0 0 0 55.9 520.5 30.9 30.9 0 0 0 76.8 529.1H923.2A29.5 29.5 0 0 0 954.5 500 30 30 0 0 0 923.2 470.5Z' fill='rgba(139, 139, 139, 0.99)'/%3E%3C/svg%3E") no-repeat center;
      background-size: 20px; }
  .flix-quantity__button--plus {
    padding-left: 12px; }
    .flix-quantity__button--plus:after {
      background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1000 1000'%3E%3Cpath d='M529.5 923.2V529.5H923.2A29.5 29.5 0 0 0 954.5 500 30 30 0 0 0 925 470.5H529.5V76.8A30 30 0 0 0 500 45.5 29.1 29.1 0 0 0 479.1 54.1 30.9 30.9 0 0 0 470.5 75V468.6H76.8A29.1 29.1 0 0 0 55.9 518.6 30.9 30.9 0 0 0 76.8 527.3H470.5V920.9A29.5 29.5 0 1 0 529.1 920.9Z' fill='rgba(139, 139, 139, 0.99)'/%3E%3C/svg%3E") no-repeat center;
      background-size: 20px; }

.flix-radio {
  position: relative; }
  .flix-radio__label {
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    min-height: 30px;
    padding-left: 42px;
    cursor: pointer; }
    .flix-radio__label::before {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 30px;
      height: 30px;
      border: 2px solid #c8c8c8;
      border-radius: 100%;
      content: ''; }
  .flix-radio__input {
    position: absolute;
    opacity: 0; }
  .flix-radio__input:focus + .flix-radio__label::before {
    outline: 1px solid transparent;
    -webkit-box-shadow: 0 0 3px #00b5e2;
            box-shadow: 0 0 3px #00b5e2; }
  .flix-radio__input:checked + .flix-radio__label::before {
    border-color: #73d700; }
  .flix-radio__info {
    color: #444;
    font-size: 14px;
    font-weight: 300;
    line-height: 17px;
    display: block;
    padding-top: 3px; }
  .flix-radio--disabled .flix-radio__label {
    cursor: not-allowed;
    opacity: .5; }
  .flix-radio--error .flix-radio__label:before {
    border-color: #ff5700; }
  .flix-radio--error .flix-radio__info {
    color: #c64300; }
  .flix-radio__input:checked + .flix-radio__label::after {
    display: block;
    position: absolute;
    top: 7px;
    left: 7px;
    width: 16px;
    height: 16px;
    border-radius: 100%;
    background: #73d700;
    content: '';
    -webkit-animation: radio-grow 250ms;
            animation: radio-grow 250ms; }

@-webkit-keyframes radio-grow {
  0% {
    -webkit-transform: scale(0.25);
            transform: scale(0.25); }
  50% {
    -webkit-transform: scale(1);
            transform: scale(1); } }

@keyframes radio-grow {
  0% {
    -webkit-transform: scale(0.25);
            transform: scale(0.25); }
  50% {
    -webkit-transform: scale(1);
            transform: scale(1); } }

.flix-range {
  position: relative;
  font-size: 0; }
  .flix-range__field {
    position: relative;
    height: 30px;
    overflow: visible; }
    .flix-range__field::before {
      display: block;
      position: absolute;
      top: 50%;
      width: 100%;
      height: 2px;
      margin-top: -1px;
      border-radius: 5px;
      background: #ffad00;
      content: ''; }
  .flix-range [type='range'] {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 30px;
    margin: 0;
    padding: 0;
    outline: none;
    background: transparent;
    overflow: visible;
    z-index: 100;
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none; }
    .flix-range [type='range']::-webkit-slider-runnable-track {
      border: 0;
      background: transparent;
      border: 0px solid #ffad00; }
    .flix-range [type='range']::-webkit-slider-thumb {
      width: 30px;
      height: 30px;
      border: 2px solid #e1e1e1;
      border-radius: 100%;
      background: #fff;
      cursor: pointer;
      position: relative;
      outline: 0;
      z-index: 1;
      -webkit-appearance: none;
      pointer-events: all; }
    .flix-range [type='range']::-moz-range-track {
      border: 0;
      background: transparent; }
    .flix-range [type='range']::-moz-range-thumb {
      width: 30px;
      height: 30px;
      border: 2px solid #e1e1e1;
      border-radius: 100%;
      background: #fff;
      cursor: pointer;
      pointer-events: all; }
    .flix-range [type='range']::-moz-focus-outer {
      border: 0; }
    .flix-range [type='range']::-ms-track {
      border: 0;
      background: transparent;
      color: transparent; }
    .flix-range [type='range']::-ms-fill-lower {
      border: 0;
      background: transparent; }
    .flix-range [type='range']::-ms-fill-upper {
      border: 0;
      background: transparent; }
    .flix-range [type='range']::-ms-thumb {
      width: 30px;
      height: 30px;
      border: 2px solid #e1e1e1;
      border-radius: 100%;
      background: #fff;
      cursor: pointer;
      margin-top: 0; }
  .flix-range__value {
    display: inline-block;
    width: 50%;
    margin-bottom: 4px;
    font-size: 14px;
    word-break: break-all; }
    .flix-range__value--max {
      text-align: right; }
  .flix-range__track-range {
    position: absolute;
    bottom: 14px;
    height: 2px;
    margin-top: -1px;
    background: #ffad00;
    z-index: 0; }
  .flix-range__label {
    display: block;
    margin-bottom: 6px;
    font-size: 16px;
    word-break: break-all; }
  .flix-range__label--hidden {
    display: none; }
  .flix-range [type='range']:focus {
    outline: 0; }
  .flix-range--disabled .flix-range__field::before {
    background: #e1e1e1;
    opacity: .5; }
  .flix-range--disabled .flix-range__track-range {
    background: #e1e1e1;
    opacity: 0; }
  .flix-range--disabled input::-moz-range-thumb,
  .flix-range input:disabled::-moz-range-thumb {
    cursor: not-allowed; }
  .flix-range--disabled input::-ms-thumb,
  .flix-range input:disabled::-ms-thumb {
    cursor: not-allowed; }
  .flix-range--disabled input::-webkit-slider-thumb,
  .flix-range input:disabled::-webkit-slider-thumb {
    cursor: not-allowed; }
  .flix-range--double {
    pointer-events: none; }
  .flix-range--double .flix-range__field::before {
    background: #e1e1e1; }

.flix-select {
  position: relative; }
  .flix-select--error::before, .flix-select--valid::before {
    display: inline-block;
    width: 24px;
    height: 24px;
    content: '';
    vertical-align: middle;
    position: absolute;
    top: 10px;
    right: 49px;
    z-index: 1; }
    @media (min-width: 1200px) {
      .flix-select--error::before, .flix-select--valid::before {
        top: 30px; } }
  .flix-select--error::before {
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1000 1000'%3E%3Cpath d='M500 90.9A409.1 409.1 0 1 0 909.1 500 409.1 409.1 0 0 0 500 90.9ZM471.8 240.9A28.2 28.2 0 0 1 528.2 240.9V614.5A28.2 28.2 0 1 1 471.8 614.5ZM500 803.6A45.5 45.5 0 1 1 545.5 758.2 45.5 45.5 0 0 1 500 803.6Z' fill='rgba(255, 87, 0, 0.99)'/%3E%3C/svg%3E") no-repeat center; }
  .flix-select--valid::before {
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1000 1000'%3E%3Cpath d='M500 90.9A409.1 409.1 0 1 0 909.1 500 409.1 409.1 0 0 0 500 90.9ZM757.3 367.3L460 664.5A23.6 23.6 0 0 1 444.1 670.9 22.7 22.7 0 0 1 428.2 664.5L242.7 479.1A22.3 22.3 0 0 1 242.7 447.7 21.8 21.8 0 0 1 274.5 447.7L444.1 616.8 727.3 335.5A22.3 22.3 0 1 1 759.1 367.3Z' fill='rgba(115, 215, 0, 0.99)'/%3E%3C/svg%3E") no-repeat center; }
  .flix-select::after {
    display: inline-block;
    width: 24px;
    height: 24px;
    content: '';
    vertical-align: middle;
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1000 1000'%3E%3Cpath d='M236.4 365.9A28.2 28.2 0 0 1 276.8 365.9L493.2 582.3A8.2 8.2 0 0 0 506.8 582.3L723.2 365.9A28.6 28.6 0 0 1 763.6 406.4L545.5 622.7A66.4 66.4 0 0 1 500 642.3H500A66.4 66.4 0 0 1 454.5 622.7L236.4 406.4A28.2 28.2 0 0 1 236.4 365.9Z' fill='rgba(139, 139, 139, 0.99)'/%3E%3C/svg%3E") no-repeat center;
    position: absolute;
    top: 10px;
    right: 12px;
    pointer-events: none; }
    @media (min-width: 1200px) {
      .flix-select::after {
        top: 30px; } }
  .flix-select__field {
    font-family: "Roboto Condensed", "Arial", sans-serif;
    font-size: 16px;
    font-weight: 300;
    position: relative;
    width: 100%;
    height: 44px;
    padding: 0 12px;
    border: 1px solid #c8c8c8;
    border-radius: 5px;
    background-color: #fff;
    color: #444;
    line-height: 1.375em;
    text-overflow: ellipsis;
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
    padding-right: 48px;
    background: transparent; }
    @media (min-width: 1200px) {
      .flix-select__field {
        height: 36px; } }
    .flix-select__field::-webkit-input-placeholder {
      color: #757575;
      font-style: italic; }
    .flix-select__field::-moz-placeholder {
      color: #757575;
      font-style: italic; }
    .flix-select__field:-ms-input-placeholder {
      color: #757575;
      font-style: italic; }
    .flix-select__field::-ms-input-placeholder {
      color: #757575;
      font-style: italic; }
    .flix-select__field::placeholder {
      color: #757575;
      font-style: italic; }
    .flix-select__field:hover, .flix-select__field:active, .flix-select__field:focus {
      outline: 0; }
    .flix-select--valid .flix-select__field {
      padding-right: 80px; }
    .flix-select--error .flix-select__field {
      padding-right: 80px;
      border: 1px solid #ff5700; }
    .flix-select__field:focus,
    .flix-select--active .flix-select__field {
      border: 1px solid #757575;
      outline: none; }
    .flix-select__field[disabled],
    .flix-select--disabled .flix-select__field {
      cursor: not-allowed;
      opacity: .5; }
    .flix-select__field::-ms-expand {
      display: none; }
  .flix-select__info {
    color: #444;
    font-size: 14px;
    font-weight: 300;
    line-height: 17px;
    display: block;
    padding-top: 3px; }
    .flix-select--error .flix-select__info {
      color: #c64300; }

.flix-select-group {
  position: relative; }
  .flix-select-group__items {
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    position: relative;
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
    padding-top: 1px;
    border-left: 1px solid #c8c8c8;
    border-radius: 5px;
    overflow: hidden;
    list-style-type: none; }
  .flix-select-group__item {
    min-width: 44px;
    height: 44px;
    margin-top: -1px;
    border: 1px solid #c8c8c8;
    border-left: 0;
    line-height: 44px;
    text-align: center;
    overflow: hidden; }
    @media (min-width: 1200px) {
      .flix-select-group__item {
        min-width: 36px;
        height: 36px;
        line-height: 36px; } }
    .flix-select-group__item:last-child {
      border-radius: 0 5px 5px 0; }
    .flix-select-group__item input {
      position: absolute;
      cursor: pointer;
      opacity: 0; }
    .flix-select-group__item label {
      display: block;
      padding: 0 12px;
      background: #fff;
      cursor: pointer; }
    .flix-select-group__item input:checked + label {
      background: #73d700;
      color: #fff; }

.flix-skeleton {
  display: block;
  width: 100%;
  height: 15px;
  margin-bottom: 7px;
  border-radius: 5px;
  background: #e1e1e1;
  content: ''; }
  .flix-skeleton--w-xs {
    width: 25%; }
  .flix-skeleton--w-sm {
    width: 33%; }
  .flix-skeleton--w-md {
    width: 50%; }
  .flix-skeleton--w-lg {
    width: 75%; }
  .flix-skeleton--h-xs {
    height: 1px;
    margin-bottom: 12px; }
  .flix-skeleton--h-md {
    height: 26px;
    margin-bottom: 12px; }
  .flix-skeleton--h-lg {
    height: 44px;
    margin-bottom: 12px; }
    @media (min-width: 1200px) {
      .flix-skeleton--h-lg {
        height: 36px; } }
  .flix-skeleton--flush-bottom {
    margin-bottom: 0; }

.flix-spinner {
  display: inline-block;
  width: 90px;
  height: 90px;
  border: 4px solid #a6ec55;
  border-radius: 50%;
  border-left-color: #73d700;
  -webkit-animation: spinner-rotate 1s infinite linear;
          animation: spinner-rotate 1s infinite linear; }
  .flix-spinner--sm, .flix-spinner--md, .flix-spinner--lg, .flix-spinner--xl, .flix-spinner--xxl {
    border-left-color: #5cac00; }
  .flix-spinner--sm {
    width: 24px;
    height: 24px; }
  .flix-spinner--md {
    width: 36px;
    height: 36px; }
  .flix-spinner--lg {
    width: 48px;
    height: 48px; }
  .flix-spinner--xl {
    width: 72px;
    height: 72px; }
  .flix-spinner--xxl {
    width: 96px;
    height: 96px; }

@-webkit-keyframes spinner-rotate {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes spinner-rotate {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

.flix-switch {
  display: block;
  position: relative;
  line-height: 30px; }
  .flix-switch__input {
    position: absolute;
    opacity: 0; }
  .flix-switch__label {
    display: block;
    position: relative;
    padding-right: 57px;
    line-height: 1.375em;
    cursor: pointer; }
    .flix-switch__label::before {
      position: absolute;
      top: 50%;
      right: 0;
      width: 45px;
      height: 5px;
      -webkit-transform: translateY(-50%);
              transform: translateY(-50%);
      -webkit-transition: -webkit-transform 0.4s;
      transition: -webkit-transform 0.4s;
      transition: transform 0.4s;
      transition: transform 0.4s, -webkit-transform 0.4s;
      border-radius: 5px;
      background-color: #c8c8c8;
      content: ''; }
    .flix-switch__label::after {
      position: absolute;
      top: 50%;
      left: calc(100% - 45px);
      width: 30px;
      height: 30px;
      -webkit-transform: translateY(-50%);
              transform: translateY(-50%);
      -webkit-transition: -webkit-transform 0.4s;
      transition: -webkit-transform 0.4s;
      transition: transform 0.4s;
      transition: transform 0.4s, -webkit-transform 0.4s;
      border: 2px solid #e1e1e1;
      border-radius: 100%;
      background-color: #fff;
      content: ''; }
    .flix-switch__input:focus + .flix-switch__label::after {
      outline: 1px solid transparent;
      -webkit-box-shadow: 0 0 3px #00b5e2;
              box-shadow: 0 0 3px #00b5e2; }
    .flix-switch__input:checked + .flix-switch__label::after {
      -webkit-transform: translateY(-50%) translateX(15px);
              transform: translateY(-50%) translateX(15px);
      border-color: #ffad00; }
    .flix-switch__input:checked + .flix-switch__label::before {
      background-color: #ffad00; }
  .flix-switch--stacked .flix-switch__label {
    padding-bottom: 36px;
    padding-left: 0; }
    .flix-switch--stacked .flix-switch__label::before, .flix-switch--stacked .flix-switch__label::after {
      top: calc(50% + 12px);
      bottom: 0;
      left: 0; }
    @media (min-width: 1200px) {
      .flix-switch--stacked .flix-switch__label {
        color: #757575; } }
  .flix-switch__input:disabled + .flix-switch__label::before {
    background: #e1e1e1;
    opacity: 0.5; }
  .flix-switch__input:disabled + .flix-switch__label::after {
    border-color: rgba(225, 225, 225, 0.5); }
  .flix-switch__input:disabled + .flix-switch__label::before,
  .flix-switch__input:disabled + .flix-switch__label::after {
    cursor: not-allowed; }
  @media (min-width: 600px) {
    .flix-switch {
      display: inline-block; }
      .flix-switch__label {
        padding-right: 0;
        padding-left: 57px; }
        .flix-switch__label::before {
          right: auto;
          left: 0; }
        .flix-switch__label::after {
          left: 0; } }

.flix-table__wrap {
  display: inline-block;
  margin-bottom: 12px;
  padding: 12px 0;
  border: 1px solid #e1e1e1;
  border-radius: 5px; }

.flix-table {
  border-collapse: separate;
  border-spacing: 24px 0; }
  .flix-table__header th,
  .flix-table__header td {
    color: #444;
    font-size: 16px;
    font-weight: 700;
    line-height: 22px; }
  .flix-table tr:last-child td {
    border-bottom: 0; }

.flix-table__header th,
.flix-table__header td, .flix-table td {
  padding: 12px 0;
  border-bottom: 1px solid #e1e1e1;
  text-align: left; }

.flix-tag {
  display: inline-block;
  max-width: 100%;
  height: 30px;
  padding: 0 12px;
  border: 1px solid #73d700;
  border-radius: 15px;
  outline: 0;
  background: #fff;
  color: #73d700;
  font-family: "Roboto Condensed", "Arial", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  text-decoration: none;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  vertical-align: middle; }
  .flix-tag--success {
    border: 0;
    background: #73d700;
    color: #fff;
    line-height: 30px; }
  .flix-tag--warning {
    border: 0;
    background: #ffad00;
    color: #fff;
    line-height: 30px; }
  .flix-tag--danger {
    border: 0;
    background: #ff5700;
    color: #fff;
    line-height: 30px; }
  .flix-tag:disabled, .flix-tag--disabled {
    border: 0;
    background: #c8c8c8;
    color: #fff;
    line-height: 30px;
    cursor: not-allowed; }
  button.flix-tag {
    cursor: pointer; }

.flix-tag__icon:first-child::before {
  margin-left: 0; }

.flix-text,
.flix-text p {
  color: #444;
  font-size: 16px;
  font-weight: 300;
  line-height: 1.375em;
  margin: 0 0 12px; }

.flix-fineprint {
  color: #444;
  font-size: 14px;
  font-weight: 300;
  line-height: 17px;
  margin: 0 0 12px; }

.flix-textarea {
  position: relative; }
  .flix-textarea--error::before, .flix-textarea--valid::before {
    display: inline-block;
    width: 24px;
    height: 24px;
    content: '';
    vertical-align: middle;
    position: absolute;
    top: 10px;
    right: 12px;
    z-index: 1; }
    @media (min-width: 1200px) {
      .flix-textarea--error::before, .flix-textarea--valid::before {
        top: 30px; } }
  .flix-textarea--error::before {
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1000 1000'%3E%3Cpath d='M500 90.9A409.1 409.1 0 1 0 909.1 500 409.1 409.1 0 0 0 500 90.9ZM471.8 240.9A28.2 28.2 0 0 1 528.2 240.9V614.5A28.2 28.2 0 1 1 471.8 614.5ZM500 803.6A45.5 45.5 0 1 1 545.5 758.2 45.5 45.5 0 0 1 500 803.6Z' fill='rgba(255, 87, 0, 0.99)'/%3E%3C/svg%3E") no-repeat center; }
  .flix-textarea--valid::before {
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1000 1000'%3E%3Cpath d='M500 90.9A409.1 409.1 0 1 0 909.1 500 409.1 409.1 0 0 0 500 90.9ZM757.3 367.3L460 664.5A23.6 23.6 0 0 1 444.1 670.9 22.7 22.7 0 0 1 428.2 664.5L242.7 479.1A22.3 22.3 0 0 1 242.7 447.7 21.8 21.8 0 0 1 274.5 447.7L444.1 616.8 727.3 335.5A22.3 22.3 0 1 1 759.1 367.3Z' fill='rgba(115, 215, 0, 0.99)'/%3E%3C/svg%3E") no-repeat center; }
  .flix-textarea__field {
    font-family: "Roboto Condensed", "Arial", sans-serif;
    font-size: 16px;
    font-weight: 300;
    position: relative;
    width: 100%;
    min-height: 112px;
    padding: 10px 12px;
    border: 1px solid #c8c8c8;
    border-radius: 5px;
    background-color: #fff;
    color: #444;
    line-height: 1.375em;
    overflow: auto;
    vertical-align: top;
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none; }
    @media (min-width: 1200px) {
      .flix-textarea__field {
        min-height: 120px;
        padding: 5px 12px; } }
    .flix-textarea__field::-webkit-input-placeholder {
      color: #757575;
      font-style: italic; }
    .flix-textarea__field::-moz-placeholder {
      color: #757575;
      font-style: italic; }
    .flix-textarea__field:-ms-input-placeholder {
      color: #757575;
      font-style: italic; }
    .flix-textarea__field::-ms-input-placeholder {
      color: #757575;
      font-style: italic; }
    .flix-textarea__field::placeholder {
      color: #757575;
      font-style: italic; }
    .flix-textarea--valid .flix-textarea__field {
      padding-right: 40px; }
    .flix-textarea--error .flix-textarea__field {
      padding-right: 40px;
      border: 1px solid #ff5700; }
    .flix-textarea__field:focus,
    .flix-textarea--active .flix-textarea__field {
      border: 1px solid #757575;
      outline: none;
      color: #444; }
    .flix-textarea__field[disabled],
    .flix-textarea--disabled .flix-textarea__field {
      cursor: not-allowed;
      opacity: .5; }

.flix-grid-container {
  padding-right: 6px;
  padding-left: 6px; }

.flix-grid {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
  width: calc(100% + 12px);
  margin-right: -6px;
  margin-left: -6px; }
  .flix-grid--align-bottom {
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
        -ms-flex-align: end;
            align-items: flex-end; }
  .flix-grid--align-top {
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
        -ms-flex-align: start;
            align-items: flex-start; }
  .flix-grid--align-center {
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center; }
  .flix-grid--justify-center {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center; }
  .flix-grid--justify-left {
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
        -ms-flex-pack: start;
            justify-content: flex-start; }
  .flix-grid--justify-right {
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
        -ms-flex-pack: end;
            justify-content: flex-end; }

.flix-col-1 {
  width: 8.33333%;
  padding-right: 6px;
  padding-left: 6px; }

.flix-push-1 {
  margin-left: 8.33333%; }

.flix-col-2 {
  width: 16.66667%;
  padding-right: 6px;
  padding-left: 6px; }

.flix-push-2 {
  margin-left: 16.66667%; }

.flix-col-3 {
  width: 25%;
  padding-right: 6px;
  padding-left: 6px; }

.flix-push-3 {
  margin-left: 25%; }

.flix-col-4 {
  width: 33.33333%;
  padding-right: 6px;
  padding-left: 6px; }

.flix-push-4 {
  margin-left: 33.33333%; }

.flix-col-5 {
  width: 41.66667%;
  padding-right: 6px;
  padding-left: 6px; }

.flix-push-5 {
  margin-left: 41.66667%; }

.flix-col-6 {
  width: 50%;
  padding-right: 6px;
  padding-left: 6px; }

.flix-push-6 {
  margin-left: 50%; }

.flix-col-7 {
  width: 58.33333%;
  padding-right: 6px;
  padding-left: 6px; }

.flix-push-7 {
  margin-left: 58.33333%; }

.flix-col-8 {
  width: 66.66667%;
  padding-right: 6px;
  padding-left: 6px; }

.flix-push-8 {
  margin-left: 66.66667%; }

.flix-col-9 {
  width: 75%;
  padding-right: 6px;
  padding-left: 6px; }

.flix-push-9 {
  margin-left: 75%; }

.flix-col-10 {
  width: 83.33333%;
  padding-right: 6px;
  padding-left: 6px; }

.flix-push-10 {
  margin-left: 83.33333%; }

.flix-col-11 {
  width: 91.66667%;
  padding-right: 6px;
  padding-left: 6px; }

.flix-push-11 {
  margin-left: 91.66667%; }

.flix-col-12 {
  width: 100%;
  padding-right: 6px;
  padding-left: 6px; }

.flix-push-12 {
  margin-left: 100%; }

@media (min-width: 600px) {
  .flix-col-1-sm {
    width: 8.33333%;
    padding-right: 6px;
    padding-left: 6px; }
  .flix-push-1-sm {
    margin-left: 8.33333%; }
  .flix-col-2-sm {
    width: 16.66667%;
    padding-right: 6px;
    padding-left: 6px; }
  .flix-push-2-sm {
    margin-left: 16.66667%; }
  .flix-col-3-sm {
    width: 25%;
    padding-right: 6px;
    padding-left: 6px; }
  .flix-push-3-sm {
    margin-left: 25%; }
  .flix-col-4-sm {
    width: 33.33333%;
    padding-right: 6px;
    padding-left: 6px; }
  .flix-push-4-sm {
    margin-left: 33.33333%; }
  .flix-col-5-sm {
    width: 41.66667%;
    padding-right: 6px;
    padding-left: 6px; }
  .flix-push-5-sm {
    margin-left: 41.66667%; }
  .flix-col-6-sm {
    width: 50%;
    padding-right: 6px;
    padding-left: 6px; }
  .flix-push-6-sm {
    margin-left: 50%; }
  .flix-col-7-sm {
    width: 58.33333%;
    padding-right: 6px;
    padding-left: 6px; }
  .flix-push-7-sm {
    margin-left: 58.33333%; }
  .flix-col-8-sm {
    width: 66.66667%;
    padding-right: 6px;
    padding-left: 6px; }
  .flix-push-8-sm {
    margin-left: 66.66667%; }
  .flix-col-9-sm {
    width: 75%;
    padding-right: 6px;
    padding-left: 6px; }
  .flix-push-9-sm {
    margin-left: 75%; }
  .flix-col-10-sm {
    width: 83.33333%;
    padding-right: 6px;
    padding-left: 6px; }
  .flix-push-10-sm {
    margin-left: 83.33333%; }
  .flix-col-11-sm {
    width: 91.66667%;
    padding-right: 6px;
    padding-left: 6px; }
  .flix-push-11-sm {
    margin-left: 91.66667%; }
  .flix-col-12-sm {
    width: 100%;
    padding-right: 6px;
    padding-left: 6px; }
  .flix-push-12-sm {
    margin-left: 100%; } }

@media (min-width: 768px) {
  .flix-col-1-md {
    width: 8.33333%;
    padding-right: 6px;
    padding-left: 6px; }
  .flix-push-1-md {
    margin-left: 8.33333%; }
  .flix-col-2-md {
    width: 16.66667%;
    padding-right: 6px;
    padding-left: 6px; }
  .flix-push-2-md {
    margin-left: 16.66667%; }
  .flix-col-3-md {
    width: 25%;
    padding-right: 6px;
    padding-left: 6px; }
  .flix-push-3-md {
    margin-left: 25%; }
  .flix-col-4-md {
    width: 33.33333%;
    padding-right: 6px;
    padding-left: 6px; }
  .flix-push-4-md {
    margin-left: 33.33333%; }
  .flix-col-5-md {
    width: 41.66667%;
    padding-right: 6px;
    padding-left: 6px; }
  .flix-push-5-md {
    margin-left: 41.66667%; }
  .flix-col-6-md {
    width: 50%;
    padding-right: 6px;
    padding-left: 6px; }
  .flix-push-6-md {
    margin-left: 50%; }
  .flix-col-7-md {
    width: 58.33333%;
    padding-right: 6px;
    padding-left: 6px; }
  .flix-push-7-md {
    margin-left: 58.33333%; }
  .flix-col-8-md {
    width: 66.66667%;
    padding-right: 6px;
    padding-left: 6px; }
  .flix-push-8-md {
    margin-left: 66.66667%; }
  .flix-col-9-md {
    width: 75%;
    padding-right: 6px;
    padding-left: 6px; }
  .flix-push-9-md {
    margin-left: 75%; }
  .flix-col-10-md {
    width: 83.33333%;
    padding-right: 6px;
    padding-left: 6px; }
  .flix-push-10-md {
    margin-left: 83.33333%; }
  .flix-col-11-md {
    width: 91.66667%;
    padding-right: 6px;
    padding-left: 6px; }
  .flix-push-11-md {
    margin-left: 91.66667%; }
  .flix-col-12-md {
    width: 100%;
    padding-right: 6px;
    padding-left: 6px; }
  .flix-push-12-md {
    margin-left: 100%; } }

@media (min-width: 1024px) {
  .flix-col-1-lg {
    width: 8.33333%;
    padding-right: 6px;
    padding-left: 6px; }
  .flix-push-1-lg {
    margin-left: 8.33333%; }
  .flix-col-2-lg {
    width: 16.66667%;
    padding-right: 6px;
    padding-left: 6px; }
  .flix-push-2-lg {
    margin-left: 16.66667%; }
  .flix-col-3-lg {
    width: 25%;
    padding-right: 6px;
    padding-left: 6px; }
  .flix-push-3-lg {
    margin-left: 25%; }
  .flix-col-4-lg {
    width: 33.33333%;
    padding-right: 6px;
    padding-left: 6px; }
  .flix-push-4-lg {
    margin-left: 33.33333%; }
  .flix-col-5-lg {
    width: 41.66667%;
    padding-right: 6px;
    padding-left: 6px; }
  .flix-push-5-lg {
    margin-left: 41.66667%; }
  .flix-col-6-lg {
    width: 50%;
    padding-right: 6px;
    padding-left: 6px; }
  .flix-push-6-lg {
    margin-left: 50%; }
  .flix-col-7-lg {
    width: 58.33333%;
    padding-right: 6px;
    padding-left: 6px; }
  .flix-push-7-lg {
    margin-left: 58.33333%; }
  .flix-col-8-lg {
    width: 66.66667%;
    padding-right: 6px;
    padding-left: 6px; }
  .flix-push-8-lg {
    margin-left: 66.66667%; }
  .flix-col-9-lg {
    width: 75%;
    padding-right: 6px;
    padding-left: 6px; }
  .flix-push-9-lg {
    margin-left: 75%; }
  .flix-col-10-lg {
    width: 83.33333%;
    padding-right: 6px;
    padding-left: 6px; }
  .flix-push-10-lg {
    margin-left: 83.33333%; }
  .flix-col-11-lg {
    width: 91.66667%;
    padding-right: 6px;
    padding-left: 6px; }
  .flix-push-11-lg {
    margin-left: 91.66667%; }
  .flix-col-12-lg {
    width: 100%;
    padding-right: 6px;
    padding-left: 6px; }
  .flix-push-12-lg {
    margin-left: 100%; } }

@media (min-width: 1200px) {
  .flix-col-1-xl {
    width: 8.33333%;
    padding-right: 6px;
    padding-left: 6px; }
  .flix-push-1-xl {
    margin-left: 8.33333%; }
  .flix-col-2-xl {
    width: 16.66667%;
    padding-right: 6px;
    padding-left: 6px; }
  .flix-push-2-xl {
    margin-left: 16.66667%; }
  .flix-col-3-xl {
    width: 25%;
    padding-right: 6px;
    padding-left: 6px; }
  .flix-push-3-xl {
    margin-left: 25%; }
  .flix-col-4-xl {
    width: 33.33333%;
    padding-right: 6px;
    padding-left: 6px; }
  .flix-push-4-xl {
    margin-left: 33.33333%; }
  .flix-col-5-xl {
    width: 41.66667%;
    padding-right: 6px;
    padding-left: 6px; }
  .flix-push-5-xl {
    margin-left: 41.66667%; }
  .flix-col-6-xl {
    width: 50%;
    padding-right: 6px;
    padding-left: 6px; }
  .flix-push-6-xl {
    margin-left: 50%; }
  .flix-col-7-xl {
    width: 58.33333%;
    padding-right: 6px;
    padding-left: 6px; }
  .flix-push-7-xl {
    margin-left: 58.33333%; }
  .flix-col-8-xl {
    width: 66.66667%;
    padding-right: 6px;
    padding-left: 6px; }
  .flix-push-8-xl {
    margin-left: 66.66667%; }
  .flix-col-9-xl {
    width: 75%;
    padding-right: 6px;
    padding-left: 6px; }
  .flix-push-9-xl {
    margin-left: 75%; }
  .flix-col-10-xl {
    width: 83.33333%;
    padding-right: 6px;
    padding-left: 6px; }
  .flix-push-10-xl {
    margin-left: 83.33333%; }
  .flix-col-11-xl {
    width: 91.66667%;
    padding-right: 6px;
    padding-left: 6px; }
  .flix-push-11-xl {
    margin-left: 91.66667%; }
  .flix-col-12-xl {
    width: 100%;
    padding-right: 6px;
    padding-left: 6px; }
  .flix-push-12-xl {
    margin-left: 100%; } }

.flix-col {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
      -ms-flex: 1;
          flex: 1;
  margin: 0 6px; }

.flix-main-wrapper {
  position: relative;
  min-height: 100%;
  margin-top: 0;
  padding-top: 36px;
  padding-bottom: 0;
  background: #fff; }
  @media (min-width: 1024px) {
    .flix-main-wrapper {
      padding-top: 36px; } }

.flix-site-body {
  min-width: 320px;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 12px; }

.flix-page {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
  width: calc(100% + 12px);
  margin-right: -6px;
  margin-left: -6px; }
  .flix-page__content {
    width: 100%;
    padding-right: 6px;
    padding-left: 6px; }
    @media (min-width: 768px) {
      .flix-page__content {
        width: 83.33333%;
        padding-right: 6px;
        padding-left: 6px; } }
  .flix-page__sidebar {
    width: 100%;
    padding-right: 6px;
    padding-left: 6px; }
    @media (min-width: 768px) {
      .flix-page__sidebar {
        width: 16.66667%;
        padding-right: 6px;
        padding-left: 6px; } }

.flix-has-text-centered {
  text-align: center; }

.flix-has-text-left {
  text-align: left; }

.flix-has-text-right {
  text-align: right; }

@media (min-width: 0) and (max-width: 599px) {
  .flix-hidden-xs,
  .hidden-xs {
    display: none; } }

@media (min-width: 600px) and (max-width: 767px) {
  .flix-hidden-sm,
  .hidden-sm {
    display: none; } }

@media (min-width: 768px) and (max-width: 1023px) {
  .flix-hidden-md,
  .hidden-md {
    display: none; } }

@media (min-width: 1024px) and (max-width: 1199px) {
  .flix-hidden-lg,
  .hidden-lg {
    display: none; } }

@media (min-width: 1200px) {
  .flix-hidden-xl,
  .hidden-xl {
    display: none; } }

.flix-space-xs-top {
  margin-top: 12px; }

.flix-space-xs-bottom {
  margin-bottom: 12px; }

.flix-space-xs-left {
  margin-left: 12px; }

.flix-space-xs-right {
  margin-right: 12px; }

.flix-space-sm-top {
  margin-top: 24px; }

.flix-space-sm-bottom {
  margin-bottom: 24px; }

.flix-space-sm-left {
  margin-left: 24px; }

.flix-space-sm-right {
  margin-right: 24px; }

.flix-space-md-top {
  margin-top: 36px; }

.flix-space-md-bottom {
  margin-bottom: 36px; }

.flix-space-md-left {
  margin-left: 36px; }

.flix-space-md-right {
  margin-right: 36px; }

.flix-space-lg-top {
  margin-top: 48px; }

.flix-space-lg-bottom {
  margin-bottom: 48px; }

.flix-space-lg-left {
  margin-left: 48px; }

.flix-space-lg-right {
  margin-right: 48px; }

.flix-space-flush-top {
  margin-top: 0; }

.flix-space-flush-bottom {
  margin-bottom: 0; }

.flix-space-flush-left {
  margin-left: 0; }

.flix-space-flush-right {
  margin-right: 0; }

/*# sourceMappingURL=map/honeycomb.css.map */
