
// Flixicon mixins
// ------------------------------------

@import '../themes/theme-default';
@import '../config/all';

// ------------------------------------
// style variables
// ------------------------------------
$icon-base-font-size: 1.2em;
$icon-size-small: 25px;
$icon-size-large: 50px;
$icon-font-family: 'flixicons';

// use ONLY for including the icons into HTML containers, when you want to get rid of additional icon HTML
// basically duplicates general font icons styles
@mixin flix-icon-abstract {
  // sass-lint:disable no-vendor-prefixes
  font-family: $icon-font-family;
  font-style: normal;
  font-variant: normal;
  font-weight: $font-weight-normal;
  text-align: center;
  text-decoration: inherit;
  text-transform: none;
  speak: none;
  vertical-align: middle;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


// draws normal icon, size depends on the parent container font-size

@mixin flix-icon {
  display: inline-block;
  // to stand out more we want normal icons to be slightly bigger than the text itself
  font-size: $icon-base-font-size;
}


// draws 25px sized small icon

@mixin flix-icon-small {
  font-size: $icon-size-small;
  line-height: $icon-size-small;
}


// draws 50px sized large icon

@mixin flix-icon-large {
  font-size: $icon-size-large;
  line-height: $icon-size-large;
}

// prepares base for 24px sized small SVG background icon

@mixin flix-svg-icon-small {
  display: inline-block;
  width: get-spacing(sm);
  height: get-spacing(sm);
  content: '';
  vertical-align: middle;
}

// prepares base for 48px sized small SVG background icon

@mixin flix-svg-icon-large {
  display: inline-block;
  width: get-spacing(lg);
  height: get-spacing(lg);
  background-size: get-spacing(lg);
  content: '';
  vertical-align: middle;
}
