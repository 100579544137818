// ----------------------------------------------
// Overlay
// ----------------------------------------------
// .[prefix-]overlay

@import '../themes/theme-default';
@import '../config/all';
@import '../utils/all';

// ------------------------------------
// style variables
// ------------------------------------
$overlay-color: rgba(0, 0, 0, .15);

#{$config-class-prefix} {
  // General overlay element, can be used on it's own for sliding bars or whatever

  &overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: $overlay-color;
    // overlay z-index is just bellow the popup
    z-index: z('popup'); // this way it can be used with both popup and side panels
  }
}
